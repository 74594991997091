import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Stack,
  useTheme,
  Alert,
} from '@mui/material';
import { ChatInputNew } from '../../Chat/SimpleChatInput/ChatInputNew.js';
import { ChatMessage } from '../../Chat/ChatMessage.js';
import {
  getModelInfoR,
  getTokenizerR,
  getTokenWindowR,
  isCUICapableR,
  parseMessage,
  cleanMessage
} from '../../Utils/GenAI_helper.js';
import {
  logout,
  getToken,
  getUser,
  getName,
  isBotLoggedIn,
  getBotSettingItem,
} from '../../AuthService.js';
import { useUserSettings } from '../../Contexts/UserPreferenceContext.js';
import { validateFile } from '../../Utils/file_functions.js';
import * as default_data from '../../config.js';
import PersonaList from '../../Personas/PersonaList.js';

import { v4 as uuidv4 } from 'uuid';
import * as log from 'loglevel';
import { escapeHTML, strip, unEscapeHTML, urlify } from '../../Utils/text_functions.js';
import FilePopup from '../../Popups/FilePopup.js';
import { FileManagementDialog } from '../../Popups/FileManagement/FileManagementDialog.js';
import AddDatasetPopup from '../../Popups/AddDatasetPopup.js';
import { SelectModel } from '../../Chat/Components/SelectModel.js';
import { getAllowedModels } from '../../Utils/model_helper.js';
import { Navbar } from '../../Chat/Navbar.js';

const ModelComparison = () => {
  const theme = useTheme();
  const [loaded, setLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState('Getting things ready...');
  const [currentTokens, setCurrentTokens] = useState(0);
  const [file, setCurrentFile] = useState(null);
  const [datasets, setDatasets] = useState([]);
  const [paid, setPaid] = useState(false);
  const [triggerFilesPopup, setTriggerFilesPopup] = useState(false);
  const [triggerDatasetPopup, setTriggerDatasetPopup] = useState(0);
  const [triggerPersonaList, setTriggerPersonaList] = useState(0);
  const [triggerFilePopup, setTriggerFilePopup] = useState(0);
  const [currentMode, setCurrentMode] = useState('advanced');
  const [current_dataset, setCurrentDataset] = useState([{value: 'all', label: 'All'}]);
  var random_element = document.createElement('div');

  const [chatLog1, setChatLog1] = useState([
    { user: 'gpt', message: 'Welcome, how can I help you?' },
  ]);
  const [chatLog2, setChatLog2] = useState([
    { user: 'gpt', message: 'Welcome, how can I help you?' },
  ]);
  const [chatInput, setChatInput] = useState('');
  const [isLoading1, setIsLoading1] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);

  const [secrets, setSecrets] = useState([]);

  const getFirstAvailableModel = (index) => {
    var allowed_models = getAllowedModels();
    return allowed_models[index];
  }

  const [currentModel1, setCurrentModel1] = useState(getFirstAvailableModel(0));
  const [currentModel2, setCurrentModel2] = useState(getFirstAvailableModel(1));

  const [temperature, setTemperature] = useState(0.0);

  const [prevCommands, setPrevCommands] = useState([]);
  const [commandCount, setCommandCount] = useState(0);

  const { state } = useUserSettings();

  const chatLogRef1 = useRef(null);
  const chatLogRef2 = useRef(null);

  const [personas, setPersonas] = useState([]);
  const [currentPersona, setCurrentPersona] = useState(1);

  const [CUIAuth, setCUIAuth] = useState(false);
  const [cur_user, setUser] = useState(null);

  const [live, setLive] = useState(0);

  // Refs for the end of each chat log
  const messagesEndRef1 = useRef(null);
  const messagesEndRef2 = useRef(null);

  const isLastMeMessage1 = chatLog1.map((message, index) => message.user === "me" ? index : -1).reduce((a, b) => Math.max(a, b), -1);
  const isLastSageMessage1 = chatLog1.map((message, index) => message.user === "gpt" ? index : -1).reduce((a, b) => Math.max(a, b), -1);

  const isLastMeMessage2 = chatLog2.map((message, index) => message.user === "me" ? index : -1).reduce((a, b) => Math.max(a, b), -1);
  const isLastSageMessage2 = chatLog2.map((message, index) => message.user === "gpt" ? index : -1).reduce((a, b) => Math.max(a, b), -1);

  // Load datasets, personas, and secrets
  useEffect(() => {
    if (loaded) return;

    setLoaded(true);
    loadPersonas();
    loadDatasets();
    loadSecrets();

    const user = getUser();
    setUser(user);
    setPaid(user.paid);
    setCurrentPersona(state.defaultPersonality);
    setCUIAuth(false)
    if(user.cac_auth && (user.cac_auth === 'true' || user.cac_auth === true)) {
      setCUIAuth(true)
    } 
    else {  
      setCUIAuth(false)
    }
  }, [loaded, state.defaultPersonality]);

  useEffect(() => {
    scrollToLatestMessage(messagesEndRef1);
  }, [chatLog1]);
  
  useEffect(() => {
    scrollToLatestMessage(messagesEndRef2);
  }, [chatLog2]);

  const scrollToLatestMessage = (messagesEndRef) => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  // Function to handle model selection
  const handleModelSelect = (event, modelNumber) => {
    const selectedModel = event.target.value;
    if (modelNumber === 1) {
      setCurrentModel1(selectedModel);
    } else if (modelNumber === 2) {
      setCurrentModel2(selectedModel);
    }
  };

  // Function to load datasets
  function loadDatasets() {
    var token = getToken()
    if(token === false) {
        logout()
        return;
    }
  
    fetch(default_data.default_user_service_url_no_cac + '/get-datasets-with-permissions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-tokens': `${token}`
      },        
      body: JSON.stringify({
      })
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('An error occurred while getting data.');
      })
      .then(data => {
        if(data != null && data.status == '200') {            
          var res = data.response;

          var names = res.map(item => item.dataset);

          names.sort((a, b) => {
            const nameA = a.toUpperCase();
            const nameB = b.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          });
          setDatasets(names);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }  

  // Function to load personas
  const loadPersonas = () => {
    var token = getToken()
      if(token === false) {
          logout()        
          return;
      }
    
      fetch(default_data.default_chat_service_url + '/get-personas', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-access-tokens': `${token}`
        },        
        body: JSON.stringify({
        })
      })
        .then(response => {
          if (response.ok) {
            return response.json();
          }
          throw new Error('An error occurred while getting data.');
        })
        .then(data => {
          var res = data.response;
          if(data != null && data.status == '200') {            
            res.sort((a, b) => {
              const nameA = a['name'].toUpperCase();
              const nameB = b['name'].toUpperCase();
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              return 0;
            });
            setPersonas(res)
          }
          else if(data != null && data.status == '400') {
            if(data.response.indexOf('Token is invalid') > -1) {
              logout()
            }
          }            
        })
        .catch(error => {
          console.log(error);
        });
  };

  // Function to load secrets
  const loadSecrets = () => {
    const token = getToken();
    if (!token) {
      logout();
      return;
    }

    fetch(`${default_data.default_chat_service_url}/get-secrets`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-tokens': token,
      },
      body: JSON.stringify({}),
    })
      .then((response) => {
        if (response.ok) return response.json();
        throw new Error('An error occurred while getting data.');
      })
      .then((data) => {
        if (data.status === '200' && data.response) {
          setSecrets(data.response.sort());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function getDatasetValues() {
    var values = current_dataset.map(option => option.value);
    if (values.includes('all')) {
      values = ['all']
    }
    else if (values.includes('none')) {
      values = ['none']
    }
    return values
  }
  
  // Function to handle the submission of the chat input
  const handleSubmit = async (e, chatInputNew) => {
    e.preventDefault();
    await submitPrompt(chatInputNew);
  };

  // Function to submit the prompt to both models
  const submitPrompt = async (inputText) => {
    try {
      const token = getToken();
      if (!token) {
        logout();
        return;
      }

      const escapedInput = escapeHTML(inputText);
      if (escapedInput === '') return;

      if (escapedInput.toLowerCase() === '/clear' || escapedInput.toLowerCase() === '/new') {
        setChatLog1([]);
        setChatLog2([]);
        setChatInput('');
        return;
      }

      setPrevCommands([...prevCommands, inputText]);
      setCommandCount(commandCount + 1);

      // Generate unique IDs for messages
      const userMessageId = uuidv4();
      const assistantMessageId1 = uuidv4();
      const assistantMessageId2 = uuidv4();

      // Construct updated chat logs with the user's message
      const newUserMessage = { user: 'me', id: userMessageId, message: escapedInput };
      const loadingMessage1 = {
        user: 'gpt',
        id: assistantMessageId1,
        message: get_loading_message(),
      };
      const loadingMessage2 = {
        user: 'gpt',
        id: assistantMessageId2,
        message: get_loading_message(),
      };

      // Update chat logs
      const updatedChatLog1 = [...chatLog1, newUserMessage, loadingMessage1];
      const updatedChatLog2 = [...chatLog2, newUserMessage, loadingMessage2];

      setChatLog1(updatedChatLog1);
      setChatLog2(updatedChatLog2);
      setChatInput('');

      async function getModelInfo(currentModel) {
        return await getModelInfoR(currentModel, currentTokens, null)
      }

      // Prepare message histories without the loading messages
      const modelInfo1 = await getModelInfo(currentModel1);
      const modelInfo2 = await getModelInfo(currentModel2);

      const history1 = updatedChatLog1
        .filter((msg) => msg.id !== assistantMessageId1)
        .slice(-modelInfo1.history_count);
      const history2 = updatedChatLog2
        .filter((msg) => msg.id !== assistantMessageId2)
        .slice(-modelInfo2.history_count);

      const messages1 = history1.map((new_item) => {
        return cleanMessage(new_item);
      });      

      const messages2 = history2.map((new_item) => {
        return cleanMessage(new_item);
      });

      // Start both streams
      setIsLoading1(true);
      setIsLoading2(true);

      await Promise.all([
        streamResults(
          messages1,
          modelInfo1.model,
          token,
          setChatLog1,
          assistantMessageId1,
          setIsLoading1
        ),
        streamResults(
          messages2,
          modelInfo2.model,
          token,
          setChatLog2,
          assistantMessageId2,
          setIsLoading2
        ),
      ]);
    } catch (error) {
      log.error(`/submitPrompt: ${error.message}`);
      log.error(`/submitPrompt: ${error.stack}`);
    }
  };

  // Function to stream results from the API
  const streamResults = async (
    messages,
    model,
    token,
    setChatLog,
    assistantMessageId,
    setIsLoadingFlag
  ) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('message', JSON.stringify(messages));
      formData.append('model', model);
      formData.append('temperature', temperature);
      formData.append('persona', currentPersona);
      formData.append('dataset', getDatasetValues());
      formData.append('live', live);
      formData.append('streaming', true);

      const response = await fetch(`${default_data.default_chat_service_url}/query`, {
        method: 'POST',
        headers: {
          'x-access-tokens': token,
        },
        body: formData,
        timeout: 1200000,
      });

      if (!response.ok) {
        log.error(`/query_stream: Response Status: ${response.status}`);
        log.error(`/query_stream: Response Headers: ${JSON.stringify(response.headers)}`);
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      await handleResponseStream(response, setChatLog, assistantMessageId);
    } catch (err) {
      log.error(`/query_stream: Error: ${err.message}`);
      setChatLog((prevChatLog) => [
        ...prevChatLog,
        { user: 'gpt', message: `Error: please try again.`, added_obj: null },
      ]);
    } finally {
      setIsLoadingFlag(false);
    }
  };

  // Function to handle the streaming response
  const handleResponseStream = async (response, setChatLog, assistantMessageId) => {
    const reader = response.body.getReader();
    const decoder = new TextDecoder('utf-8');
    let bufferLeft = '';

    while (true) {
      const { value, done } = await reader.read();
      if (done) {
        if (bufferLeft !== '') {
          await handleData(bufferLeft, setChatLog, assistantMessageId);
          bufferLeft = '';
        }
        break;
      }
      const chunk = decoder.decode(value);
      bufferLeft += chunk;

      const messages = bufferLeft.split('@|@sep@|@');
      for (let i = 0; i < messages.length - 1; i++) {
        await handleData(messages[i], setChatLog, assistantMessageId);
      }
      bufferLeft = messages[messages.length - 1];
    }
  };

  // Function to handle individual data chunks
  const handleData = async (dataChunk, setChatLog, assistantMessageId) => {
    try {
      const data = JSON.parse(dataChunk);
      if (!data) return;

      const parsedMessage = await buildMessage(data);
      const newContent = parsedMessage.message;

      setChatLog((prevChatLog) => {
        const updatedChatLog = [...prevChatLog];
        const messageIndex = updatedChatLog.findIndex(
          (msg) => msg.id === assistantMessageId
        );

        if (messageIndex > -1) {
          // if the message already exists, update it
          updatedChatLog[messageIndex] = {
            ...updatedChatLog[messageIndex],
            message: newContent,
            message_orig: data.message_orig,
            analysis_failed: false,
            type: data.type,
            images: parsedMessage.images,
            references: parsedMessage.references,
            references_descriptions: parsedMessage.references_descriptions,
          };
        } else {
          // If message doesn't exist, add it
          updatedChatLog.push({
            id: assistantMessageId,
            user: 'gpt',
            message: newContent,
            message_orig: data.message_orig,
            added_obj: JSON.stringify(data.added_obj),
            references: parsedMessage.references,
            references_descriptions: parsedMessage.references_descriptions,
            analysis_failed: false,
            type: data.type,
            images: parsedMessage.images,
          });
        }

        return updatedChatLog;
      });
    } catch (error) {
      log.error(`/handleData: 7: ${error.message}`);
      log.error(`/handleData: 7: ${error.stack}`);
    }
  };

  // Function to parse the message content
  const buildMessage = async (data) => {
    if(data.message !== '' && data.message != null) {    
      if (Array.isArray(data.message)) {
        data.message = data.message.join('\n');
      }
      data.message = data.message.replace(' Source: ', "\n\nSource: ");
      data.message = data.message.replace(' Sources: ', "\n\nSources: ");
      data.message = data.message.replace(' Reference: ', "\n\nReference: ");
      data.message = data.message.replace(' References: ', "\n\nReferences: ");
      data.message = data.message.replace(' Reference 1: ', "\n\nReference 1: ");
      data.message = data.message.replace('.Reference: ', ".\n\nReference: ");
      data.message = data.message.replace('.References: ', ".\n\nReferences: ");
      data.message = data.message.replace('.Reference 1: ', ".\n\nReference 1: ");
      data.message = data.message.replace('Reference: 1.', "Reference:\n1.");
      data.message = data.message.replace('References: 1.', "References:\n1.");

      data.message_orig = data.message;

      var ret_parse = await parseMessage(random_element, data.message);
      data.message = ret_parse['message'];
      data.images = ret_parse['images'];

      data.added_obj = JSON.stringify(data.added_obj);

      if(data.type === 'yield') {
        data.message += "\n\n" + get_loading_message();
      }

      var chat_log_update_obj = {message: data.message, file: data.file, embedding_down: data.embedding_down, vectors_down: data.vectors_down, added_obj: data.added_obj, analysis_failed: false, type: data.type, images: data.images}
      if(data.type !== 'yield') {
        chat_log_update_obj['message_orig'] = data.message_orig
      }
      if(data.type !== 'teach' && data.type !== 'yield') {
        if(data.images) {

        }
        else {
          const [references, references_descriptions] = addReferences(data)
          if(references != null) {
            data.references = references
            data.references_descriptions = references_descriptions
          }
        }
      }
      return data;
    }
  };
  
  function addReferences(data, max_size=250) {

    if(data.references == null || data.references == '') {
      return [null, null];
    }
  
    var references = data.references 
    var references_array = []
    var references_descriptions = [] // New array to store descriptions
  
    // List of delimiters
    var delimiters = [
      ' - Video Description:',
      ' - Summary:',
      ' - Data:',
      ' - Article:',
      ' - Article Description:',
      ' - Content:',
      ' - Sage:'
    ];
    
    let refArray = references.split("\n");
    refArray.forEach((element) => {
      element = strip(element);
      var index = -1;
      var description = ''; // Variable to store description
      if(element != '') {
        // Iterate over delimiters
        for (let delimiter of delimiters) {
          if (element.indexOf(delimiter) !== -1) {
            index = element.indexOf(delimiter);
            description = element.substring(index + delimiter.length); // Store description
            element = element.substring(0, index);
            break; // Break the loop as soon as a delimiter is found
          }
        }
        if(index === -1) {
          if(element.length > max_size) {
            description = element;
            element = element.substring(0, max_size) + '...';
          }
          else {
            description = ''; // If no delimiter is found, description is empty
          }
        }
        references_array.push(urlify(escapeHTML(element)))
        references_descriptions.push(escapeHTML(description)); // Push description to array        
      }
    });
    
    var i = 1;
    var res_references = []
    var res_references_descriptions = []
    while(i <= 20) {
      if(data.message.indexOf('[' + i.toString() + ']') !== -1) {
        references_array.forEach((element) => {
          if(element.indexOf('[' + i.toString() + ']') !== -1) {
            res_references.push(element)
            res_references_descriptions.push(references_descriptions[references_array.indexOf(element)])
          }
        });
      }
      i++;
    }

    return [res_references, res_references_descriptions]
  }

  // Function to handle temperature selection
  const handleTemperatureChange = (event) => {
    setTemperature(event.target.value);
  };

  // Function to handle persona selection
  const handlePersonaChange = (event) => {
    if (Number.isInteger(event)) {
      setCurrentPersona(event);
    }
    else {
      setCurrentPersona(event.target.value);
    }
  };

  // Function to handle live selection
  const handleLiveChange = (event) => {
    setLive(parseInt(event.target.value, 10));
  };

  function get_loading_message() {
    return "<img class=\"spinner\" src=\"spinner.png\" alt=\"Loading...\"> I'm thinking, please wait...";
  }

  function handleAudio(blob, chatInput, setChatInput) {
    // Send the Blob to the API
    if (validateFile(blob)) {
      var token = getToken();
      if(token === false) {
        return;
      }

      setIsLoading(true);
      setLoadingText('Please wait, loading...');

      const formData = new FormData();
      formData.append('file', blob, 'audio.mp4'); // Add the Blob to the form data
      formData.append('strategy', 'auto');

      try {
        fetch(default_data.default_chat_service_url_files + '/file', {
          method: 'POST',
          headers: {
            'x-access-tokens': `${token}`,
            'Connection': 'keep-alive' // Add this line
          },        
          body: formData,
          timeout: 1200000 // 20 minutes in milliseconds
        })
        .then(response => {
          if (response.ok) {
            return response.json();
          }
          throw new Error('An error occurred while getting data.');
        })
        .then(data => {
          var ret = 'Internal error, please contact customer support with more information about your file'
  
          if(data['status'] == '200') {
            if(typeof data['ret'] === 'object') {
              if('text' in data['ret']) {
                ret = data['ret'].text;
              }
              else {
                ret = 'Internal error, please contact customer support with more information about your file [2]'
              }
            }
            else {
              ret = data['ret']
            }
          }
          else if(data['response'] === 'No file too large') {
            ret = 'File too large, please contact customer support with more information about your file'
          }           
          else if(data['response'] === 'Virus detected in file') {
            ret = 'Virus detected in file, please contact customer support with more information about your file'
          }           
          else if(data['response'] === 'File type not allowed') {
            ret = 'File type not allowed, please contact customer support with more information about your file'
          }
          if(chatInput.length > 0) {
            setChatInput(chatInput + '\n' + ret)
          }
          else {
            setChatInput(ret)
          }
          setIsLoading(false);  
      })
      .catch(error => {
        setIsLoading(false);
        console.log(error);
      });  
      }
        catch (error) {
        setIsLoading(false);
        console.log(error)
      }
    }
  }

  function handleFileChange(event) {
    if (!validateFile(event.target.files[0])) {
      alert('Invalid file type. Please upload a valid file type.');
      return;
    }
    setCurrentFile(event.target.files[0]);
    event.target.value = null;
  }

  const handleDataset = (options) => {
    setCurrentDataset(options);
  };

  function showSimpleIngest() {
    setTriggerFilePopup(1);
  }

  function showAddDataset() {
    setTriggerDatasetPopup(1);
  }

  function clickHandlerFollowUp(e, setChatInput) {
    var value = e.target.textContent;
    if(value != '') {
      submitPrompt(value, setChatInput)
      e.target.disabled = true;
    }
  }

  function clickHandlerCopy(e, chatLog) {
    let el = e.target;
    while (el && el !== e.currentTarget && el.tagName !== "BUTTON") {
        el = el.parentNode;
    }
    var uuid = el.getAttribute('tag');
    if(uuid != '' && uuid != null) {
      var found = false;
      var content = '';
      chatLog.forEach((element) => {
        if(element['id'] == uuid) {
          content = unEscapeHTML(element['message_orig'])
          found = true;
        }
      });
      if(found) {
        el.classList.add('clicked');
        setTimeout(() => {
          el.classList.remove('clicked');
        }, 2000);
        navigator.clipboard.writeText(content);
      }          
      else {
        console.log('not found')
      }
    }
  }

  function clickHandlerDelete(item, chatLog, setChatLog) {
    if(item != '' && item != null) {
      // if item is string, search by ['id']
      if(typeof item === 'string') {
        // Filter the chatLog array to exclude the item with the specified uuid
        const updatedChatLog = chatLog.filter(element => element['id'] !== item);
        // Update the chatLog and save the updated list
        setChatLog(updatedChatLog);
      }
      else {
        const updatedChatLog = chatLog.filter(element => element !== item);
        setChatLog(updatedChatLog);
      }
    }
  }

  function isCUICapable() {
    return isCUICapableR(currentModel1, live) && isCUICapableR(currentModel2, live);
  }

  const handleDeleteEvent = (item, chatLog, setChatLog) => {
    clickHandlerDelete(item, chatLog, setChatLog)
  }

  function clickHandlerDown(id, chatLog) {
    if(id != '' && id != null) {
      var found = false;
      chatLog.forEach((element) => {
        if(element['id'] == id) {
          found = true;
        }
      });
      if(found) {
        voteDown(id)
      }          
      else {
        console.log('not found')
      }
    }
  }

  function voteDown(uuid) {
    var token = getToken()
    if(token === false) {
      return;
    }
  
    fetch(default_data.default_chat_service_url + '/vote-down', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-tokens': `${token}`
      },        
      body: JSON.stringify({
        uuid: uuid
      })
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('An error occurred while getting data.');
      })
      .then(data => {
        if(data != null && data.status != 200) {            
          console.log(data)
        }
      })
      .catch(error => {
        console.log(error);
      });
  }  

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.appBackground,
        height: '100vh',
        width: '100vw',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {
        isBotLoggedIn() && getBotSettingItem('hide_banner') === true ?
        '' :
        (CUIAuth === true) ?
        <div className='header-message'>
          { default_data.default_banner_override != null && default_data.default_banner_override != '' ?
              default_data.default_banner_override : 'CUI'
          }
        </div>
        :
        <div className='header-message'>
          { default_data.default_banner_override != null && default_data.default_banner_override != '' ?
              default_data.default_banner_override : 'UNCLASSIFIED'
          }
        </div>
      }
      {
        ((paid != true && default_data.default_customer_tenant !== true) || (cur_user && cur_user.plan_type && cur_user.plan_type === 'trial')) ?
        <div className="header_subscribe">
          <a href="/payment">Subscribe now for more features & tokens!</a>
        </div>
        : ''
      }
      <Stack direction="row" height={'calc(100dvh - 16px)'}>
        <Navbar />
        <Stack direction="column" spacing={2} sx={{ flex: 1, overflow: 'hidden', backgroundColor: theme.palette.chatBackground }}>
          <Stack
            direction="column"
            spacing={2}
            sx={{ flex: 1, overflow: 'hidden', backgroundColor: theme.palette.chatBackground }}
          >
            <Alert severity="info" sx={{ width: '100%', textAlign: 'center', marginBottom: 2 }}>
              <strong>Model Comparison</strong> - Compare the responses of two different models side by
              side. Conversations are not saved.
            </Alert>
            <Stack
              direction="row"
              spacing={2}
              sx={{ flex: 1, overflow: 'hidden', backgroundColor: theme.palette.chatBackground }}
            >
              {/* Left Chat Column */}
              <Box
                className="chat-thread"
                sx={{
                  flex: 1,
                  borderRight: '1px solid #ccc',
                  display: 'flex',
                  flexDirection: 'column',
                  maxWidth: '50%',
                }}
              >
                <SelectModel
                  value={currentModel1}
                  onChange={(e) => handleModelSelect(e, 1)}
                  cur_user={getName()}
                  paid={paid}
                  className="select-menu"
                  sx={{
                    marginLeft: 2,
                    backgroundColor: theme.palette.chatBackground,
                    maxWidth: '200px',
                  }}
                />
                <Box
                  sx={{
                    flex: 1,
                    overflowY: 'auto',
                    padding: 1,
                  }}
                  ref={chatLogRef1}
                >
                  {chatLog1.map((message, index) => (
                    <div
                      key={`chat1-${index}-${message.id}`}
                      ref={index === chatLog1.length - 1 ? messagesEndRef1 : null}
                    >
                      <ChatMessage
                        message={message}
                        clickHandlerFollowUp={clickHandlerFollowUp}
                        clickHandlerCopy={(event) => clickHandlerCopy(event, chatLog1)}
                        clickHandlerDelete={( id ) => { handleDeleteEvent(message, chatLog1, setChatLog1) }}
                        clickHandlerDown={( id ) => { clickHandlerDown(id, chatLog1) }}
                        setChatInput={setChatInput}
                        isLastMeMessage={index === isLastMeMessage1}
                        isLastSageMessage={index === isLastSageMessage1}
                      />
                    </div>
                  ))}
                </Box>
              </Box>

              {/* Right Chat Column */}
              <Box
                className="chat-thread"
                sx={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  maxWidth: '50%',
                }}
              >
                <SelectModel
                  value={currentModel2}
                  onChange={(e) => handleModelSelect(e, 2)}
                  cur_user={getName()}
                  paid={paid}
                  className="select-menu"
                  sx={{
                    marginLeft: 2,
                    backgroundColor: theme.palette.chatBackground,
                    maxWidth: '200px',
                  }}
                />
                <Box
                  sx={{
                    flex: 1,
                    overflowY: 'auto',
                    padding: 1,
                  }}
                  ref={chatLogRef2}
                >
                  {chatLog2.map((message, index) => (
                    <div
                    key={`chat2-${index}-${message.id}`}
                      ref={index === chatLog2.length - 1 ? messagesEndRef2 : null}
                    >
                      <ChatMessage 
                        message={message}
                        clickHandlerFollowUp={clickHandlerFollowUp}
                        clickHandlerCopy={(event) => clickHandlerCopy(event, chatLog2)}
                        clickHandlerDelete={( id ) => { handleDeleteEvent(message, chatLog2, setChatLog2) }}
                        clickHandlerDown={( id ) => { clickHandlerDown(id, chatLog2) }}
                        setChatInput={setChatInput}
                        isLastMeMessage={index === isLastMeMessage2}
                        isLastSageMessage={index === isLastSageMessage2}
                      />
                    </div>
                  ))}
                </Box>
              </Box>
            </Stack>
          </Stack>
          <Box sx={{ width: '100%', backgroundColor: theme.palette.chatBackground }}>
            <ChatInputNew
              handleSubmit={handleSubmit}
              prevCommand={prevCommands}
              setChatInputMode={setChatInput}
              chatInputPass={chatInput}
              showAddDataset={showAddDataset}
              personas={personas}
              current_persona={currentPersona}
              setCurrentPersona={setCurrentPersona}
              isCUICapable={() => isCUICapable()}
              getTokenWindow={() => Math.min(getTokenWindowR(currentModel1), getTokenWindowR(currentModel2))}
              tokens_count={currentTokens}
              getTokenizer={async (string) => setCurrentTokens(await getTokenizerR(string))}
              setFile={setCurrentFile}
              datasets={datasets}
              current_dataset={current_dataset}
              handleDataset={handleDataset}
              showSimpleIngest={() => setTriggerFilePopup(1)}
              showCopyData={() => setTriggerFilesPopup(true)}
              handleLive={handleLiveChange}
              live={live}
              paid={paid}
              showPersonaList={() => setTriggerPersonaList(1)}
              handlePersona={handlePersonaChange}
              handleSelect={handleTemperatureChange}
              temperature={temperature}
              cur_user={getName()}
              handleFileChange={handleFileChange}
              handleAudio={handleAudio}
              setChatLogAndSaveWithScroll={() => {}}
              secrets={secrets}
              file={file}
              setChatInputPass={setChatInput}
              setSettingDrawerOpen={() => {}}
              hideModelSelect={true}
              hidePluginSelect={true}
              currentMode={currentMode}
            />
          </Box>
          { triggerPersonaList === 1 && (
            <PersonaList
              personas={personas}
              loadPersonas={loadPersonas}
              setTriggerPersonaList={setTriggerPersonaList}
              triggerPersonaList={triggerPersonaList}
              handlePersonaClick={handlePersonaChange}
            />
          )}
          {triggerFilePopup === 1 && (
            <FilePopup 
              paid={paid}
              showAddDataset={showAddDataset}
              datasets={datasets}
              setTriggerFilePopup={setTriggerFilePopup}
              triggerFilePopup={triggerFilePopup}
            />
          )}
          {triggerFilesPopup && (
            <FileManagementDialog
              datasets={datasets}
              setDatasets={setDatasets}
              loadDatasets={loadDatasets}
              setTriggerFilesPopup={setTriggerFilesPopup}
              triggerFilesPopup={triggerFilesPopup}
              triggerIngestPopup={showSimpleIngest}
              ingestPopup={triggerFilePopup}
            />
          )}
          { triggerDatasetPopup === 1 && (
            <AddDatasetPopup
              loadDatasets={loadDatasets}
              setTriggerDatasetPopup={setTriggerDatasetPopup}
              triggerDatasetPopup={triggerDatasetPopup}
            />
          )}
          {isLoading && (
            <div
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                background: 'rgba(0, 0, 0, 0.9)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 9999,
              }}
            >
              <span style={{ color: '#fff', fontSize: '24px' }}>{loadingText}</span>
            </div>
          )}
        </Stack>
      </Stack>
    </Box>
  );
};

export default ModelComparison;
