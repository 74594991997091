import { useMemo, useState, useEffect } from 'react';
import { MenuItem, Select, Skeleton, useTheme } from '@mui/material';
import { getAllowedModels } from '../../Utils/model_helper.js';

export const SelectModel = ({ value, cur_user, onChange, paid, sx }) => {
  const theme = useTheme();
  const [optionsLoaded, setOptionsLoaded] = useState(false);
  const [allowedModels, setAllowedModels] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAllowedModels = async () => {
      try {
        const models = await getAllowedModels();
        setAllowedModels(models);
        setOptionsLoaded(true);
      } catch (err) {
        setError(err);
        console.error('Failed to fetch allowed models:', err);
      }
    };

    fetchAllowedModels();
  }, []);

  const filteredOptions = [
    { key: 'auto', value: 'GPT Auto*', paid: true, superadmin_only: false },
    { key: 'gpt', value: 'GPT3.5*', paid: false, superadmin_only: false },
    { key: 'gpt35-16k', value: 'GPT3.5-16K*', paid: true, superadmin_only: false },
    { key: 'gpt4', value: 'GPT4*', paid: false, superadmin_only: false },
    { key: 'gpt-4o', value: 'GPT-4o*', paid: true, superadmin_only: false },
    { key: 'gpt-4o-mini', value: 'GPT-4o-mini*', paid: true, superadmin_only: false },
    { key: 'gpt-o1-mini', value: 'GPT-o1-mini*', paid: true, superadmin_only: false },
    { key: 'gpt-o1', value: 'GPT-o1*', paid: true, superadmin_only: false },
    { key: 'gpt4-32k', value: 'GPT4-32K*', paid: true, superadmin_only: false },
    { key: 'gpt4-vision', value: 'GPT4-V*', paid: true, superadmin_only: false },
    { key: 'gpt-o3-mini', value: 'GPT-o3-mini', paid: true, superadmin_only: false },    
    { key: 'gpt-4.5-preview', value: 'GPT-4.5-preview', paid: true, superadmin_only: false },
    { key: 'gpt-gov', value: 'GPT3.5 Gov*', paid: false, superadmin_only: false },
    { key: 'gpt4-gov', value: 'GPT4 Gov*', paid: false, superadmin_only: false },
    { key: 'gpt-4o-gov', value: 'GPT4o Gov*', paid: true, superadmin_only: false },
    { key: 'gpt-4o-mini-gov', value: 'GPT4o-mini Gov*', paid: true, superadmin_only: false },
    { key: 'dall-e-2', value: 'DALL-E (2)', paid: true, superadmin_only: false },
    { key: 'dall-e-3', value: 'DALL-E (3)*', paid: true, superadmin_only: false },
    { key: 'google-imagen-3', value: 'Google Imagen 3', paid: true, superadmin_only: false },
    { key: 'mpt-7b-chat', value: 'MPT-7B-Chat*', paid: true, superadmin_only: true },
    { key: 'dolly', value: 'Dolly*', paid: true, superadmin_only: true },
    { key: 'flan-t5', value: 'Flan T5-XL*', paid: true, superadmin_only: true },
    //{ key: 'llma2', value: 'LLMA-2', paid: true, superadmin_only: false },
    { key: 'llma3', value: 'LLAMA-3', paid: true, superadmin_only: false },
    { key: 'claude2', value: 'Claude 2', paid: true, superadmin_only: false },
    { key: 'claude-3-opus', value: 'Claude 3 O.', paid: true, superadmin_only: false },
    { key: 'claude-3-sonnet', value: 'Claude 3 S.', paid: true, superadmin_only: false },
    { key: 'claude-35-sonnet', value: 'Claude 3.5 S.', paid: true, superadmin_only: false },
    { key: 'claude-37-sonnet', value: 'Claude 3.7 S.', paid: true, superadmin_only: false },
    { key: 'falcon', value: 'Falcon*', paid: true, superadmin_only: true },
    { key: 'aws-bedrock-titan', value: 'Titan*', paid: true, superadmin_only: true },
    { key: 'aws-bedrock-claude-35-sonnet-gov', value: 'Claude 3.5 S Gov*', paid: true, superadmin_only: false },
    { key: 'google-bison', value: 'Google Bison', paid: true, superadmin_only: false },
    { key: 'google-gemini-pro', value: 'Google Gemini Pro', paid: true, superadmin_only: false },
    { key: 'google-gemini-20-flash', value: 'Google Gemini 2.0', paid: true, superadmin_only: false },
    { key: 'mistral-large', value: 'Mistral Large', paid: true, superadmin_only: false },
    { key: 'cohere', value: 'Cohere', paid: false, superadmin_only: false },
    { key: 'groq-70b', value: 'Groq-70B', paid: true, superadmin_only: false },
    { key: 'groq-llama33', value: 'Groq LLAMA 3.3', paid: true, superadmin_only: false },
    { key: 'groq-deepseek', value: 'DeepSeek R1 70B', paid: true, superadmin_only: false },
    { key: 'xai-grok', value: 'X.AI Grok', paid: true, superadmin_only: false },
  ].filter((option) => allowedModels.includes(option.key));

  const options = useMemo(() => {
    return filteredOptions.map((option) => {
      if ((option.paid === true && paid !== true) || (option.superadmin_only === true && cur_user.type !== 'superadmin')) {
        return null;
      }
      return (
        <MenuItem key={option.key} value={option.key} sx={{ fontSize: 13 }}>
          {option.value}
        </MenuItem>
      );
    }).filter((option) => option !== null);
  }, [filteredOptions, paid, cur_user]);

  if (error) {
    return <div>Error loading options: {error.message}</div>;
  }

  if (!optionsLoaded) {
    return <Skeleton variant="rounded" width={100} height={30} />;
  }

  return (
    <Select
      value={value}
      onChange={onChange}
      defaultValue={'gpt'}
      className="select-menu"
      sx={{
        backgroundColor: theme.palette.dropdowns.backgroundColor,
        ...sx,
      }}
      MenuProps={{
        style: {
          maxHeight: 500,
        },
      }}
    >
      {options}
    </Select>
  );
};