export const plans = [
  {
    cost: 'Enterprise Plan - $90/mo or $990/yr<br />Buy tokens in bulk, spread on users!<br />No cost per user!',
    inferenceTokens: '2,000,000*',
    trainingTokens: '2,000,000*',
    trainingDetails: 'Only $0.8 per 1M tokens',
    features: 'All',
  },
  {
    cost: '$15/mo/user',
    inferenceTokens: '200,000',
    trainingTokens: '200,000',
    trainingDetails: 'Only $0.8 per 1M tokens',
    features: 'All',
  }, 
  {
    cost: '$30/mo/user',
    inferenceTokens: '500,000',
    trainingTokens: '500,000',
    trainingDetails: 'Only $0.8 per 1M tokens',
    features: 'All',
  }, 
  {
    cost: '$50/mo/user',
    inferenceTokens: '1,000,000',
    trainingTokens: '1,000,000',
    trainingDetails: 'Only $0.8 per 1M tokens',
    features: 'All',
  }, 
  {
    cost: '$90/mo/user',
    inferenceTokens: '2,000,000',
    trainingTokens: '2,000,000',
    trainingDetails: 'Only $0.8 per 1M tokens',
    features: 'All',
  },
  {
    cost: 'Bring your own LLMs<br />(starts at $15/mo/user with large volume discounts)',
    inferenceTokens: 'Unlimited',
    trainingTokens: 'Unlimited',
    trainingDetails: '',
    features: 'All',
  }
];