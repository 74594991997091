import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { FiChevronDown } from 'react-icons/fi';
import { sanitizeHtml } from '../Utils/text_functions';
import { Typography } from '@mui/material';

export const AccordionFileComponent = ({ content }) => {
  // Remove leading and trailing newline characters
  const trimmedContent = content.replace(/^\n+|\n+$/g, '');
  const isValidContent = typeof trimmedContent === 'string' && trimmedContent.trim() !== '';

  return (
    isValidContent ? 
      <Accordion sx={{ border: '1px solid #ccc', borderRadius: '5px', marginBottom: '5px' }}>
        <AccordionSummary expandIcon={<FiChevronDown />}>
          <Typography>File Content Ingested</Typography>
        </AccordionSummary>
        <AccordionDetails dangerouslySetInnerHTML={{ __html: sanitizeHtml(trimmedContent.replace(/\n/g, "<br />"), ['br']) }} />
      </Accordion>
    : null
  );
}