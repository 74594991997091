import { 
  Paper, 
  useTheme, 
  Link, 
  Typography, 
  Box, 
  Grid, 
  Card, 
  CardContent, 
  CardMedia, 
  Chip, 
  List, 
  ListItem, 
  ListItemIcon, 
  ListItemText,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';
import { 
  MdNewReleases, 
  MdImage, 
  MdCloud, 
  MdSearch, 
  MdMic, 
  MdLabel, 
  MdExtension, 
  MdCode, 
  MdDescription,
  MdHelp,
  MdPlayArrow,
  MdExpandMore,
  MdDataObject,
  MdBarChart,
  MdVolumeUp,
  MdCompareArrows,
  MdFilterAlt,
  MdShare,
  MdAutoAwesome,
  MdTableChart,
  MdCalculate,
  MdAutoGraph
} from 'react-icons/md';

export const Capabilities = () => {
  const theme = useTheme();
  
  // Core Products
  const coreProducts = [
    { 
      icon: <MdCode />, 
      title: "Code Canvas", 
      description: "Edit and work on code directly in Ask Sage with a dedicated side window",
      isPaid: true,
      isNew: true
    },
    { 
      icon: <MdCompareArrows />, 
      title: "Model Comparison", 
      description: "Compare prompts across multiple models on a single screen with RAG, live search, and file attachments",
      isPaid: true,
      isNew: true
    },
    { 
      icon: <MdDescription />, 
      title: "Workbook", 
      description: "Collaborative workspace with shared sources, memos, and chat history",
      isPaid: true,
      isNew: true
    }
  ];
  
  // Model Support
  const modelSupport = [
    { 
      icon: <MdCloud />, 
      title: "Azure Gov OpenAI Support", 
      description: "GPT 3.5, GPT 4, 4o, 4o-mini, and more on FedRAMP High fabric (CUI compliant)",
      isPaid: false,
      isNew: false
    },
    { 
      icon: <MdCloud />, 
      title: "GPT-4.5 Preview & GPT-o3-mini", 
      description: "Latest OpenAI models available on both commercial and Azure Gov platforms",
      isPaid: true,
      isNew: true
    },
    { 
      icon: <MdCloud />, 
      title: "Claude 3.5 Sonnet & Claude 3.7 Sonnet", 
      description: "Anthropic Claude models available on AWS Gov for CUI compliance",
      isPaid: true,
      isNew: true
    },
    { 
      icon: <MdCloud />, 
      title: "Groq LLAMA 3.3", 
      description: "High-performance, cost-effective model for non-CUI use cases",
      isPaid: true,
      isNew: true
    },
    { 
      icon: <MdCloud />, 
      title: "Google Gemini 2.0 Flash & X.AI Grok", 
      description: "Additional model options for diverse use cases (non-CUI)",
      isPaid: true,
      isNew: true
    },
    { 
      icon: <MdCloud />, 
      title: "DeepSeek R1 LLAMA 70B", 
      description: "Available for research purposes only (not CUI compliant)",
      isPaid: true,
      isNew: true
    }
  ];
  
  // Multi-modal Features
  const multimodalFeatures = [
    { 
      icon: <MdImage />, 
      title: "Multi-modal Support", 
      description: "Text, audio, and image generation & analysis capabilities",
      isPaid: true,
      isNew: false
    },
    { 
      icon: <MdImage />, 
      title: "Google Imagen 3", 
      description: "Advanced image generation capabilities (not CUI authorized)",
      isPaid: true,
      isNew: true
    },
    { 
      icon: <MdVolumeUp />, 
      title: "Text-to-Speech", 
      description: "Azure OpenAI TTS and TTS HS with various voice options",
      isPaid: true,
      isNew: true
    },
    { 
      icon: <MdMic />, 
      title: "Speech-to-Text", 
      description: "Ingest audio files (mp3, mp4, mpeg, mpga, m4a, wav, webm) up to 500MB",
      isPaid: false,
      isNew: false
    }
  ];
  
  // Data & Analysis Features
  const dataFeatures = [
    { 
      icon: <MdDataObject />, 
      title: "CSV/XLSX Download", 
      description: "Download generated CSV/XLSX files directly to your device",
      isPaid: false,
      isNew: true
    },
    { 
      icon: <MdTableChart />, 
      title: "Excel/CSV File Analysis", 
      description: "Attach Excel or CSV files to prompts and ask questions about your data",
      isPaid: true,
      isNew: true
    },
    { 
      icon: <MdCalculate />, 
      title: "Advanced Data Analysis", 
      description: "Use pandas, numpy, and sklearn for sophisticated data analysis of CSV/XLSX files",
      isPaid: true,
      isNew: true
    },
    { 
      icon: <MdAutoGraph />, 
      title: "Advanced Plotting", 
      description: "Create visualizations from your CSV/XLSX data with advanced plotting capabilities",
      isPaid: true,
      isNew: true
    },
    { 
      icon: <MdBarChart />, 
      title: "Chart & Diagram Generation", 
      description: "Generate charts and diagrams using MermaidJS, including flowcharts, sequence diagrams, pie charts, bar charts, and more",
      isPaid: false,
      isNew: true
    },
    { 
      icon: <MdFilterAlt />, 
      title: "Filter & Ingest CSV", 
      description: "Filter CSV columns to selectively ingest data into datasets of your choice",
      isPaid: true,
      isNew: true
    }
  ];
  
  // Collaboration & Integration Features
  const collaborationFeatures = [
    { 
      icon: <MdShare />, 
      title: "Workbook Sharing", 
      description: "Share workbooks with colleagues for true collaborative experiences",
      isPaid: true,
      isNew: true
    },
    { 
      icon: <MdShare />, 
      title: "Dataset Permissions", 
      description: "Share datasets with Read-only, Edit, or Admin permissions to colleagues or organization-wide",
      isPaid: true,
      isNew: true
    },
    { 
      icon: <MdCode />, 
      title: "IDE Integration", 
      description: "Access Ask Sage models directly within VS Code and JetBrains through Continue.dev integration",
      isPaid: true,
      isNew: true
    },
    { 
      icon: <MdLabel />, 
      title: "Data Labeling", 
      description: "Auto-label content with customizable prompts and label lists, with API for automation",
      isPaid: true,
      isNew: false
    }
  ];
  
  // Search & UI Features
  const uiFeatures = [
    { 
      icon: <MdSearch />, 
      title: "Live+ Search", 
      description: "Enhanced real-time search that loads the first 2 results content for more accurate answers",
      isPaid: true,
      isNew: false
    },
    { 
      icon: <MdDescription />, 
      title: "Markdown/Code Reader", 
      description: "Download CSV/XLSX, copy code, display SVGs, markdown tables, GitHub markdown, and more",
      isPaid: false,
      isNew: true
    },
    { 
      icon: <MdAutoAwesome />, 
      title: "Auto-name Chat", 
      description: "Automatically names new chats based on the topic of your first prompt",
      isPaid: false,
      isNew: true
    },
    { 
      icon: <MdDescription />, 
      title: "Prompt Templates", 
      description: "Create and share public or private prompt templates with other Ask Sage users",
      isPaid: false,
      isNew: false
    },
    { 
      icon: <MdHelp />, 
      title: "Help Command", 
      description: "Use /help to get a list of available commands",
      isPaid: false,
      isNew: false
    }
  ];
  
  // Enterprise Features
  const enterpriseFeatures = [
    { 
      icon: <MdCloud />, 
      title: "Model Access Control", 
      description: "Limit which models your users can see (available for enterprise SaaS customers)",
      isPaid: true,
      isNew: true
    },
    { 
      icon: <MdShare />, 
      title: "Organization-wide Dataset Sharing", 
      description: "Share datasets across your entire organization without per-user sharing",
      isPaid: true,
      isNew: true
    },
    { 
      icon: <MdExtension />, 
      title: "File Plugins & Agents", 
      description: "Enable automated tasks with customizable plugins and agents",
      isPaid: true,
      isNew: false
    }
  ];
  
  return (
    <Paper sx={{ padding: 3, mt: 2, borderRadius: 1 }}>
      <Typography variant="h5" component="h3" className="modal_header" sx={{ mb: 3, textAlign: 'center', fontWeight: 'medium' }}>
        Ask Sage Capabilities
      </Typography>
      
      <Box className="scroller">
        <Card sx={{ mb: 4, overflow: 'hidden', borderRadius: 1, boxShadow: 2 }}>
          <CardMedia
            component="img"
            image="/schema.png"
            alt="Ask Sage schema"
            sx={{ width: '100%', height: 'auto', objectFit: 'cover' }}
          />
        </Card>
        
        <Typography variant="h6" sx={{ mb: 2, fontWeight: 'medium', display: 'flex', alignItems: 'center' }}>
          <MdNewReleases style={{ marginRight: '8px', color: theme.palette.primary.main }} />
          Core Products
        </Typography>
        
        <Grid container spacing={2} sx={{ mb: 4 }}>
          {coreProducts.map((feature, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Card 
                sx={{ 
                  height: '100%', 
                  borderRadius: 1, 
                  boxShadow: 1,
                  position: 'relative',
                  overflow: 'visible'
                }}
              >
                {feature.isNew && (
                  <Chip 
                    label="NEW" 
                    color="primary" 
                    size="small" 
                    sx={{ 
                      position: 'absolute', 
                      top: -10, 
                      right: 16,
                      fontWeight: 'bold'
                    }} 
                  />
                )}
                <CardContent sx={{ pt: 2 }}>
                  <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 1 }}>
                    <Box 
                      sx={{ 
                        mr: 2, 
                        color: theme.palette.primary.main,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: '1.5rem'
                      }}
                    >
                      {feature.icon}
                    </Box>
                    <Box sx={{ flex: 1 }}>
                      <Typography variant="subtitle1" component="div" fontWeight="medium">
                        {feature.title}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {feature.description}
                      </Typography>
                      {feature.isPaid && (
                        <Chip 
                          label="Paid Feature" 
                          size="small" 
                          variant="outlined"
                          sx={{ mt: 1, fontSize: '0.7rem' }} 
                        />
                      )}
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        
        <Divider sx={{ my: 3 }} />
        
        {/* Feature Categories as Accordions */}
        <Accordion defaultExpanded sx={{ mb: 2 }}>
          <AccordionSummary expandIcon={<MdExpandMore />}>
            <Typography variant="h6" sx={{ fontWeight: 'medium', display: 'flex', alignItems: 'center' }}>
              <MdCloud style={{ marginRight: '8px', color: theme.palette.primary.main }} />
              Model Support
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              {modelSupport.map((feature, index) => (
                <Grid item xs={12} md={6} key={index}>
                  <Card sx={{ height: '100%', borderRadius: 1, boxShadow: 1 }}>
                    <CardContent>
                      <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                        <Box sx={{ mr: 2, color: theme.palette.primary.main, fontSize: '1.5rem' }}>
                          {feature.icon}
                        </Box>
                        <Box sx={{ flex: 1 }}>
                          <Typography variant="subtitle1" fontWeight="medium">
                            {feature.title}
                            {feature.isNew && (
                              <Chip 
                                label="NEW" 
                                color="primary" 
                                size="small" 
                                sx={{ ml: 1, fontSize: '0.7rem' }} 
                              />
                            )}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            {feature.description}
                          </Typography>
                          {feature.isPaid && (
                            <Chip 
                              label="Paid Feature" 
                              size="small" 
                              variant="outlined"
                              sx={{ mt: 1, fontSize: '0.7rem' }} 
                            />
                          )}
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
        
        <Accordion defaultExpanded sx={{ mb: 2 }}>
          <AccordionSummary expandIcon={<MdExpandMore />}>
            <Typography variant="h6" sx={{ fontWeight: 'medium', display: 'flex', alignItems: 'center' }}>
              <MdImage style={{ marginRight: '8px', color: theme.palette.primary.main }} />
              Multi-modal Features
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              {multimodalFeatures.map((feature, index) => (
                <Grid item xs={12} md={6} key={index}>
                  <Card sx={{ height: '100%', borderRadius: 1, boxShadow: 1 }}>
                    <CardContent>
                      <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                        <Box sx={{ mr: 2, color: theme.palette.primary.main, fontSize: '1.5rem' }}>
                          {feature.icon}
                        </Box>
                        <Box sx={{ flex: 1 }}>
                          <Typography variant="subtitle1" fontWeight="medium">
                            {feature.title}
                            {feature.isNew && (
                              <Chip 
                                label="NEW" 
                                color="primary" 
                                size="small" 
                                sx={{ ml: 1, fontSize: '0.7rem' }} 
                              />
                            )}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            {feature.description}
                          </Typography>
                          {feature.isPaid && (
                            <Chip 
                              label="Paid Feature" 
                              size="small" 
                              variant="outlined"
                              sx={{ mt: 1, fontSize: '0.7rem' }} 
                            />
                          )}
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
        
        <Accordion defaultExpanded sx={{ mb: 2 }}>
          <AccordionSummary expandIcon={<MdExpandMore />}>
            <Typography variant="h6" sx={{ fontWeight: 'medium', display: 'flex', alignItems: 'center' }}>
              <MdDataObject style={{ marginRight: '8px', color: theme.palette.primary.main }} />
              Data & Analysis Features
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              {dataFeatures.map((feature, index) => (
                <Grid item xs={12} md={6} key={index}>
                  <Card sx={{ height: '100%', borderRadius: 1, boxShadow: 1 }}>
                    <CardContent>
                      <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                        <Box sx={{ mr: 2, color: theme.palette.primary.main, fontSize: '1.5rem' }}>
                          {feature.icon}
                        </Box>
                        <Box sx={{ flex: 1 }}>
                          <Typography variant="subtitle1" fontWeight="medium">
                            {feature.title}
                            {feature.isNew && (
                              <Chip 
                                label="NEW" 
                                color="primary" 
                                size="small" 
                                sx={{ ml: 1, fontSize: '0.7rem' }} 
                              />
                            )}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            {feature.description}
                          </Typography>
                          {feature.isPaid && (
                            <Chip 
                              label="Paid Feature" 
                              size="small" 
                              variant="outlined"
                              sx={{ mt: 1, fontSize: '0.7rem' }} 
                            />
                          )}
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
        
        <Accordion defaultExpanded sx={{ mb: 2 }}>
          <AccordionSummary expandIcon={<MdExpandMore />}>
            <Typography variant="h6" sx={{ fontWeight: 'medium', display: 'flex', alignItems: 'center' }}>
              <MdShare style={{ marginRight: '8px', color: theme.palette.primary.main }} />
              Collaboration & Integration
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              {collaborationFeatures.map((feature, index) => (
                <Grid item xs={12} md={6} key={index}>
                  <Card sx={{ height: '100%', borderRadius: 1, boxShadow: 1 }}>
                    <CardContent>
                      <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                        <Box sx={{ mr: 2, color: theme.palette.primary.main, fontSize: '1.5rem' }}>
                          {feature.icon}
                        </Box>
                        <Box sx={{ flex: 1 }}>
                          <Typography variant="subtitle1" fontWeight="medium">
                            {feature.title}
                            {feature.isNew && (
                              <Chip 
                                label="NEW" 
                                color="primary" 
                                size="small" 
                                sx={{ ml: 1, fontSize: '0.7rem' }} 
                              />
                            )}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            {feature.description}
                          </Typography>
                          {feature.isPaid && (
                            <Chip 
                              label="Paid Feature" 
                              size="small" 
                              variant="outlined"
                              sx={{ mt: 1, fontSize: '0.7rem' }} 
                            />
                          )}
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
        
        <Accordion defaultExpanded sx={{ mb: 2 }}>
          <AccordionSummary expandIcon={<MdExpandMore />}>
            <Typography variant="h6" sx={{ fontWeight: 'medium', display: 'flex', alignItems: 'center' }}>
              <MdSearch style={{ marginRight: '8px', color: theme.palette.primary.main }} />
              Search & UI Features
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              {uiFeatures.map((feature, index) => (
                <Grid item xs={12} md={6} key={index}>
                  <Card sx={{ height: '100%', borderRadius: 1, boxShadow: 1 }}>
                    <CardContent>
                      <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                        <Box sx={{ mr: 2, color: theme.palette.primary.main, fontSize: '1.5rem' }}>
                          {feature.icon}
                        </Box>
                        <Box sx={{ flex: 1 }}>
                          <Typography variant="subtitle1" fontWeight="medium">
                            {feature.title}
                            {feature.isNew && (
                              <Chip 
                                label="NEW" 
                                color="primary" 
                                size="small" 
                                sx={{ ml: 1, fontSize: '0.7rem' }} 
                              />
                            )}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            {feature.description}
                          </Typography>
                          {feature.isPaid && (
                            <Chip 
                              label="Paid Feature" 
                              size="small" 
                              variant="outlined"
                              sx={{ mt: 1, fontSize: '0.7rem' }} 
                            />
                          )}
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
        
        <Accordion defaultExpanded sx={{ mb: 2 }}>
          <AccordionSummary expandIcon={<MdExpandMore />}>
            <Typography variant="h6" sx={{ fontWeight: 'medium', display: 'flex', alignItems: 'center' }}>
              <MdExtension style={{ marginRight: '8px', color: theme.palette.primary.main }} />
              Enterprise Features
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              {enterpriseFeatures.map((feature, index) => (
                <Grid item xs={12} md={6} key={index}>
                  <Card sx={{ height: '100%', borderRadius: 1, boxShadow: 1 }}>
                    <CardContent>
                      <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                        <Box sx={{ mr: 2, color: theme.palette.primary.main, fontSize: '1.5rem' }}>
                          {feature.icon}
                        </Box>
                        <Box sx={{ flex: 1 }}>
                          <Typography variant="subtitle1" fontWeight="medium">
                            {feature.title}
                            {feature.isNew && (
                              <Chip 
                                label="NEW" 
                                color="primary" 
                                size="small" 
                                sx={{ ml: 1, fontSize: '0.7rem' }} 
                              />
                            )}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            {feature.description}
                          </Typography>
                          {feature.isPaid && (
                            <Chip 
                              label="Paid Feature" 
                              size="small" 
                              variant="outlined"
                              sx={{ mt: 1, fontSize: '0.7rem' }} 
                            />
                          )}
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Paper>
  );
};