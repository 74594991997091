import React, { useEffect, useState } from 'react';
import { Stack, TextField, Button, Typography, Box, useTheme, CircularProgress, Alert } from '@mui/material';
import * as default_data from '../../config.js';
import GooglePopup from '../../Popups/GooglePopup.js';
import { useGoogleOAuth } from '../../Contexts/GoogleContext.js';
import { getToken, getUser, isAdminLoggedIn, logout, setWithExpiry, updateUser } from "../../AuthService";
import { Link, useSearchParams } from "react-router-dom";
import { SettingsCard } from './SettingsCard.js';
import { LoginCard } from './LoginCard.js';
import { MdPeople } from 'react-icons/md';
import { FaHistory, FaMobileAlt } from 'react-icons/fa';
import { IoHardwareChip, IoKeySharp } from 'react-icons/io5';
import { WebAuthnTab } from './WebAuthnTab.js'; // Import the new WebAuthnTab component

export const AccountTab = ({
  loading, 
  onClose,
  setShowAPIKeysList,
}) => {
  const [searchParams] = useSearchParams();
  const [user, setUser] = useState({});
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [company, setCompany] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [custom_intro_prompt, setCustomIntroPrompt] = useState('');
  const [datasets, setDatasets] = useState('');
  const [cac_found, setCACFound] = useState(false);
  const [paid, setPaid] = useState(false);
  const [hasStripeID, sethasStripeID] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [CACErrorMessage, setCACErrorMessage] = useState(undefined);
  const [CACSuccessMessage, setCACSuccessMessage] = useState(undefined);
  const [showAll, setShowAll] = useState(false);

  const datasetList = Array.isArray(datasets)
    ? datasets
    : datasets?.split(',') || [];

  const visibleDatasets = showAll ? datasetList : datasetList.slice(0, 10);
  const mfaNotEnabled = !loading && (user.mfa_secret === '' || user.mfa_secret == null || user.mfa_secret.substring(0, 2) === 'T-');

  const isGoogleOAuthEnabled = useGoogleOAuth();
  const theme = useTheme();

  useEffect(() => {
    if (!default_data.default_user_service_url || !default_data.default_user_service_url_no_cac) {
      setErrorMessage('Service URLs are not configured. Please contact support.');
      return;
    }

    if (loaded) return;

    setLoaded(true);

    const user = getUser();
    if (!user) {
      setErrorMessage('User not logged in. Redirecting to login.');
      window.location = '/login';
      return;
    }

    setFirstName(user.first_name || '');
    setLastName(user.last_name || '');
    setCompany(user.company || '');
    setPhone(user.phone || '');
    setEmail(user.email || '');
    setCustomIntroPrompt(user.custom_intro_prompt || '');

    updateInfo();

    const action = searchParams.get("action");
    if (action === 'checkout') {
      window.location = '/payment';
    } else if (action === 'cancel_checkout') {
      setErrorMessage('You have cancelled your payment. Please try again.');
    } else if (action === 'success_checkout') {
      setSuccessMessage('Congratulations! Your subscription is now active!');
      updateInfo();
    } else if (action === 'success_update_checkout') {
      setSuccessMessage('Thank you, your subscription has been updated!');
      updateInfo();
    } else if (action === 'force_password') {
      setErrorMessage('Your password has expired. Please update it before continuing.');
    }

    const handleWindowUnload = () => setLoaded(false);
    window.addEventListener('beforeunload', handleWindowUnload);

    return () => window.removeEventListener('beforeunload', handleWindowUnload);
  }, [loaded, searchParams]);

  function updateInfo() {
    const token = getToken();
    if (!token) {
      setErrorMessage('Authentication token missing. Please log in again.');
      return;
    }

    fetch(default_data.default_user_service_url_no_cac + '/validate_token_with_full_user', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-tokens': token,
      },
      body: JSON.stringify({}),
    })
      .then(response => {
        if (response.ok) return response.json();
        throw new Error('Failed to fetch user information.');
      })
      .then(data => {
        if (data.status !== 200) throw new Error(data.response || 'Unknown error');

        const datasetArray = data.response.datasets || [];
        const formattedDatasets = datasetArray.map(item => {
          if (item === 'DoD') return 'DoD Bios';
          if (item === 'Air Force') return 'Air Force Bios';
          return item;
        }).join(', ') + ', GPT';

        setDatasets(formattedDatasets.replace(/user_custom_/g, ''));
        setPaid(data.response.paid);
        sethasStripeID(!!data.response.stripe_customer_id);
        setCACFound(data.response.cac_found);
        setWithExpiry('user', JSON.stringify(data.response));
        setUser(data.response);
      })
      .catch(error => {
        console.error('Error updating info:', error);
        setErrorMessage(error.message || 'An unexpected error occurred.');
      });
  }

  function registerCAC() {
      var token = getToken();
      if(user == false || user == null) {
        window.location = '/login';
        return;
      }
  
      fetch(default_data.default_user_service_url + '/register-cac', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-access-tokens': `${token}`
        },        
        body: JSON.stringify({
        })
      })
        .then(response => {
          if (response.ok) {
            return response.json();
          }
          throw new Error('An error occurred while sending chats.');
        })
        .then(data => {
          if(data.status != 200) {
            setCACErrorMessage('CAC/PIV not found - if you are getting this error, it is very likely that your local IT proxy is blocking CAC/PIV passthrough in which case you would need to open a local IT ticket with your IT support to whitelist *.asksage.ai for CAC/PIV passthrough. If this does not help, please reach out to us at ' + default_data.default_support_email)
            setCACSuccessMessage('')
          }
          else {          
            setCACErrorMessage('')
            setCACSuccessMessage('CAC/PIV successfully added.')
            updateInfo()
          }
        })
        .catch(error => {
  
        });    
    }

  const handleSubmit = event => {
    event.preventDefault();
    setIsLoading(true);
    const token = getToken();
    if (!token) {
      setErrorMessage('Authentication token missing. Please log in again.');
      setIsLoading(false);
      return;
    }

    fetch(default_data.default_user_service_url_no_cac + '/update', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-tokens': token,
      },
      body: JSON.stringify({
        first_name: firstName,
        last_name: lastName,
        company: company,
        phone: phone,
        password: password,
        custom_intro_prompt: custom_intro_prompt,
      }),
    })
      .then(response => {
        if (response.ok) return response.json();
        throw new Error('Failed to update account.');
      })
      .then(data => {
        if (data.status !== 200) throw new Error(data.response || 'Unknown error');
        setSuccessMessage('Your account has been updated!');
        setErrorMessage('');
        updateUser(firstName, lastName, company, phone, custom_intro_prompt);

        if (password) {
          setTimeout(() => logout(), 2000);
        } else {
          window.location.href = '/dashboard';
        }
      })
      .catch(error => {
        console.error('Error submitting form:', error);
        setErrorMessage(error.message || 'An unexpected error occurred.');
      })
      .finally(() => setIsLoading(false));
  };

  const inputProps = {
    style: { fontSize: '11pt' } // Adjust the font size here
  };

  return (
    <Stack direction={'column'} spacing={2}>
      <SettingsCard title={'Account Information'} cardColor={theme.palette.card.backgroundColorLight}>
        <form onSubmit={handleSubmit}>
          {errorMessage && <Typography color="error">{errorMessage}</Typography>}
          {successMessage && <Typography color="success">{successMessage}</Typography>}
          <TextField
            label="First Name"
            value={firstName}
            onChange={event => setFirstName(event.target.value)}
            fullWidth
            margin="dense"
            size='small'
            slotProps={{input: inputProps}}
          />
          <TextField
            label="Last Name"
            value={lastName}
            onChange={event => setLastName(event.target.value)}
            fullWidth
            margin="dense"
            size='small'
            slotProps={{input: inputProps}}
          />
          <TextField
            label="Company"
            value={company}
            onChange={event => setCompany(event.target.value)}
            fullWidth
            margin="dense"
            size='small'
            slotProps={{input: inputProps}}
          />
          <TextField
            label="Email"
            value={email}
            readOnly
            fullWidth
            margin="dense"
            size='small'
            slotProps={{input: inputProps}}
            disabled
          />
          <TextField
            label="Phone"
            value={phone}
            onChange={event => setPhone(event.target.value)}
            fullWidth
            margin="dense"
            size='small'
            slotProps={{input: inputProps}}
          />
          {paid && (
            <TextField
              label="Describe yourself (maximum 800 characters)"
              value={custom_intro_prompt}
              onChange={event => setCustomIntroPrompt(event.target.value)}
              multiline
              rows={4}
              fullWidth
              margin="dense"
              size='small'
              slotProps={{input: inputProps}}
              />
          )}
          <TextField
            label="Password (leave empty if unchanged)"
            type='password'
            value={password}
            onChange={event => setPassword(event.target.value)}
            fullWidth
            size='small'
            slotProps={{input: inputProps}}
          />
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={isLoading}
            fullWidth
            sx={{ mt: 2 }}
            size='small'
          >
            {isLoading ? <CircularProgress size={24} /> : 'Submit'}
          </Button>
        </form>
      </SettingsCard>
      <SettingsCard cardColor={theme.palette.card.backgroundColorLight}>
        <Stack direction={'column'} spacing={1}>
          <Button
            color="primary"
            component={Link}
            to={'/mfa-setup'}
            target="_blank"
            variant="contained"
            disabled={!mfaNotEnabled}
            fullWidth
            startIcon={<FaMobileAlt />}
            sx={{ 
              textTransform: 'none', 
              '&.Mui-disabled': {
                color: 'black',
              }
              }}
          >
            { 
              mfaNotEnabled ? 
              "Register Microsoft Authenticator" : 
              "Authenticator is already setup."
            }
          </Button>
          {CACErrorMessage && (
            <Alert severity="error" onClose={()=>{setCACErrorMessage(undefined)}} size={"small"}>
              {CACErrorMessage}
            </Alert>
          )}
          {CACSuccessMessage && (
            <Alert severity="success" onClose={()=>{setCACSuccessMessage(undefined)}} size={"small"}>
              {CACSuccessMessage}
            </Alert>
          )}
          { !default_data.default_hide_cac && (
            <Button
              color="primary"
              variant="contained"
              fullWidth
              disabled={loading || user.cac_found}
              startIcon={<IoHardwareChip />}
              onClick={registerCAC}
              sx={{ 
                textTransform: 'none', 
                '&.Mui-disabled': {
                  color: 'black',
                }
                }}
            >
              {loading || user.cac_found ? 
                "CAC registered successfully." : 
                <Box>
                  Register CAC/PIV to your account
                  <br />
                  (CAC must be in the reader to work)
                </Box>
              }
            </Button>
          )}
          <WebAuthnTab />
          {isAdminLoggedIn() === true && (
            <Button
              color="primary"
              variant="contained"
              fullWidth
              startIcon={<MdPeople />}
              component={Link}
              to={'/admin/get-users'}
              target="_blank"
              sx={{ textTransform: 'none' }}
            >
              Manage your Organization
            </Button>
          )}
          <Button
            color="primary"
            variant="contained"
            fullWidth
            startIcon={<IoKeySharp />}
            onClick={() => {
              onClose();
              setShowAPIKeysList(1);
            }}
          >
            Manage your API keys
          </Button>
          <Button 
          startIcon={<FaHistory />}
          sx={{ textTransform: 'none' }} variant="contained" color="primary" target="_blank" href="/prompt-logs" fullWidth>
            See your Prompt Logs
          </Button>
          {paid && default_data.default_has_google_login_enabled && isGoogleOAuthEnabled && (
            <Box>
              <GooglePopup />
            </Box>
          )}
        </Stack>
      </SettingsCard>
      <SettingsCard title={'Recent Logins'} cardColor={theme.palette.card.backgroundColorLight}>
        <LoginCard />
      </SettingsCard>
      <SettingsCard
      title="Available Datasets"
      cardColor={theme.palette.card.backgroundColorLight}
    >
      <Typography>You have access to the following datasets:</Typography>
      <ul style={{
        margin: 0,
      }}>
        {visibleDatasets.map((dataset, index) => (
          <li key={index}>
            <Typography className="settings-drawer-text">
              {dataset.trim()}
            </Typography>
          </li>
        ))}
      </ul>
      {datasetList.length > 10 && (
        <Button
          variant="text"
          size="small"
          onClick={() => setShowAll(!showAll)}
        >
          {showAll ? 'Show Less' : 'Show All'}
        </Button>
      )}
      {default_data.default_hide_cac !== true && (
        <Alert severity='warning' >
          Warning: CUI datasets will not be accessible without CAC/PIV
          authentication. Additionally, do not train CUI data into a dataset
          that does not have "CUI" in its name.
        </Alert>
      )}
    </SettingsCard>
    </Stack>
  );
};