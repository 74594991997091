import React, { useState, useEffect } from 'react';
import * as default_data from '../../config.js';
import { logout, getUser, getToken } from '../../AuthService.js';
import AddWorkbook from './AddWorkbook.js';
import { unEscapeHTML } from '../../Utils/text_functions.js';
import { FiPlus, FiSearch, FiTrash2 } from 'react-icons/fi';
import { Typography, Dialog, Stack, DialogTitle, TextField, Card, CardContent, useTheme, Button as MUIButton, InputAdornment, Box, Grid2, IconButton, MenuItem, Menu } from '@mui/material';
import { NoResults } from '../../Components/NoResults.js';
import { Button } from '../../Components/Button.js';
import { BsBookHalf } from 'react-icons/bs';
import { CustomDialog } from '../../Components/CustomDialog/CustomDialog.js';
import { CardTitle } from 'reactstrap';
import { MdMoreVert } from 'react-icons/md';

const WorkbookList = ({ handleWorkbookClick, triggerWorkbookList, setTriggerWorkbookList }) => {
  const [workbooks, setWorkbooks] = useState(null);
  const [user_id, setUserId] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [triggerAddWorkbook, setTriggerAddWorkbook] = useState(0);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [workbookToDelete, setWorkbookToDelete] = useState(null);
  var loaded_static = false;

  const [modalIsOpen, setIsOpen] = React.useState(false);

  const theme = useTheme();

  function addWorkbook() {
    setTriggerAddWorkbook(1);
  }

  function closeModal() { }

  function handleSearchChange(event) {
    setSearchText(event.target.value);
  }

  function confirmDelete() {
    if (workbookToDelete == null) {
      return;
    }

    var token = getToken();
    if (token === false) {
      return;
    }

    var user = getUser();

    if (user === false || user == null) {
      window.location = '/login';
    }
    setUserId(user.id);

    fetch(default_data.default_user_service_url_no_cac + '/workbook', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'x-access-tokens': `${token}`,
      },
      body: JSON.stringify({
        workbook_id: workbookToDelete,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('An error occurred while getting data.');
      })
      .then((data) => {
        if (data.status != 200) {
          return;
        }
        loadWorkbooks();
        setDeleteModalIsOpen(false);
        setWorkbookToDelete(null);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function loadWorkbooks() {
    var token = getToken();
    if (token === false) {
      return;
    }

    var user = getUser();

    if (user === false || user == null) {
      window.location = '/login';
    }
    setUserId(user.id);

    fetch(default_data.default_user_service_url_no_cac + '/get-workbooks', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-tokens': `${token}`,
      },
      body: JSON.stringify({}),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('An error occurred while getting data.');
      })
      .then((data) => {
        if (data.status != 200) {
          if(data.response && data.response.indexOf('Token is invalid') !== -1) {
            logout()
          }
          return;
        }
        data = data.response;
        setWorkbooks(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    if (triggerWorkbookList) {
      if (triggerWorkbookList === 1) {
        setIsOpen(true);
      }
    }
  }, [triggerWorkbookList]);

  useEffect(() => {
    if (loaded || loaded_static) {
      return;
    }

    setLoaded(true);
    loaded_static = true;

    loadWorkbooks();
  }, []);

  const filterWorkbooks = () => {
    if (!workbooks) return [];
    return workbooks.filter((workbook) =>
      workbook.title.toLowerCase().includes(searchText.toLowerCase())
    );
  };

  const DisplayData = () => {
    const filteredWorkbooks = filterWorkbooks();
    if (filteredWorkbooks.length === 0) {
      return (
        <Box
          sx={{
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            flexDirection: 'column', 
            width:'100%',
            // grow to fill the space and flexGrow is not working
            height: '100%',
            
          }}
        >
          <BsBookHalf style={{fontSize: '80px'}} />
          <Typography style={{fontWeight: 700}}>
            No workbooks created yet!
          </Typography>
          <Typography style={{fontWeight: 700, maxWidth: '350px', textAlign: 'center'}}>
            Create a workbook to keep your related sources and memos in one place.
          </Typography>
          <Button
            onClick={addWorkbook}
            variant="outlined"
            startIcon={<FiPlus/>}
            sx={{marginTop: 2}}
          >
            New Workbook
          </Button>
        </Box>
      );
    }
    return (
      <Grid2 container spacing={{ xs: 2, md: 3 }} columns={{ xs: 2, mobile: 8, lg: 12 }}>
        {filteredWorkbooks.map((info) => {
          return ( 
            <Grid2 key={info.id} size={{ xs: 2, sm: 4, md: 4 }}>
              <WorkbookVignette key={info.id} info={info} workbookClick={workbookClick} />
            </Grid2>
          )
        })}
      </Grid2>
    );

  };

  const WorkbookVignette = ({ info, workbookClick }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    return (
      <Card
        key={info.id}
        tag={info.id}
        onClick={workbookClick}
        sx={{
          width: '100%',
          height: '100%',
          padding: { xs: '0.5rem', sm: '1rem' },
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          }
        }}
      >
        <CardTitle>
          <Box 
            sx={{
              padding: '0.5rem', 
              justifyContent: 'space-between', 
              display: 'flex', 
              flexDirection: 'row',
              width: '100%'
            }}
          >
            {/* Wrap the title to new lines below*/}
            <Typography variant='h6' tag={info.id} sx={{ overflowWrap: 'break-word', wordWrap: 'break-word', hyphens: 'auto', width: '90%' }}>
              {unEscapeHTML(info.title)}
            </Typography>
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? 'long-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup="true"
              onClick={handleClick}
              size='md'
              sx={{
                alignSelf: 'flex-start',
                padding: 0,
                margin: 0,
                '&:hover': {
                  backgroundColor: 'transparent',
                }
              }}
            >
              <MdMoreVert />
            </IconButton>
          </Box>
        </CardTitle>
        <CardContent tag={info.id} sx={{ padding: '0.5rem' }}>
          <Typography color={theme.palette.text.secondary} tag={info.id} variant='body2'>
            {info.overall_summary !== null && info.overall_summary !== '' ? unEscapeHTML(info.overall_summary.substring(0, 400)) + '...' : ''}
          </Typography>
        </CardContent>
        <Menu
          id="long-menu"
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem 
            onClick={(e)=>{
              e.stopPropagation();
              setWorkbookToDelete(info.id);
              setDeleteModalIsOpen(true);
              handleClose();
            }}
          >
            Delete
          </MenuItem>
        </Menu>
      </Card>
    );
  };

  function workbookClick(e) {
    var tag = e.target.getAttribute('tag');
    if (tag == null) {
      return;
    }
    tag = tag.toString();
    var workbook = null;
    workbooks.forEach((element) => {
      if (element['id'] == tag) {
        workbook = element;
      }
    });

    if (workbook != null && workbook != '') {
      handleWorkbookClick(workbook);
      closeModal();
    }
  }

  return (
    <Stack
      direction={'column'}
      spacing={2}
      sx={{        
        padding: 2,
        backgroundColor: theme.palette.appBackground,
        width: '100%',
        height: '100%',
      }}
    >
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={1}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: 2,
        }}
      >
        <Typography variant="h5">
          Workbooks
        </Typography>
      </Stack>
      <Stack 
        direction={{
          xs: 'column',
          sm: 'row',
        }} 
        spacing={1}
        marginBottom={{ xs: 2, sm: 0 }}
        justifyContent="space-between"
      >
        
        <TextField
          type="text"
          placeholder="Search workbooks"
          value={searchText}
          onChange={handleSearchChange}
          size="small"
          slotProps={{
            input: {
              startAdornment: (
                <InputAdornment position="start">
                  <FiSearch />
                </InputAdornment>
              ),
            },
          }}
          sx={{ width: '100%', maxWidth: '500px' }}
        />
        <Button 
          sx={{ whiteSpace: 'nowrap', minWidth: 'max-content'}}
          onClick={addWorkbook}
          variant="outlined" 
          startIcon={<FiPlus/>}
        >
          New Workbook
        </Button>
      </Stack>

      <div className="workbook-container" style={{ overflowY: 'auto', height: '100%' }}>
        {workbooks === null ? (
          'Loading...'
        ) : (
          <DisplayData />
        )}
      </div>

      <AddWorkbook
        loadWorkbooks={loadWorkbooks}
        setTriggerAddWorkbook={setTriggerAddWorkbook}
        triggerAddWorkbook={triggerAddWorkbook}
      />
      <CustomDialog
        open={deleteModalIsOpen}
        onClose={() => setDeleteModalIsOpen(false)}
        title={'Delete Workbook'}
        allowEscape={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        actions={
          <>
            <MUIButton 
              sx={{fontWeight: 'bold'}} 
              color={theme.palette.text.primary}
              variant='text'
              onClick={() => setDeleteModalIsOpen(false)}
            >
              Cancel
            </MUIButton>
            <MUIButton sx={{fontWeight: 'bold'}} color={'error'} variant='contained' onClick={confirmDelete}>Delete</MUIButton>            
          </>
        }
      >
        <Typography>Are you sure you want to delete this workbook?</Typography>
      </CustomDialog>
    </Stack>
  );
};
export default WorkbookList;