import React, {useState} from 'react'
import {  FiCheckCircle, FiTrash } from 'react-icons/fi'
import { Checkbox,  IconButton, TableCell, TableRow, useTheme } from '@mui/material'
import { DeleteFilesDialog } from './DeleteFilesDialog'

export const FileRow = ({ row, datasetPermission, groupDatasets, index, openFiles, setOpenFiles, selectedFiles, setSelectedFiles, refetch, setShowFullText }) => {
  const [deleteDialog, setDeleteDialog] = useState(false);
  const theme = useTheme();
  const isOdd = index % 2 === 0;

  function processDataset(inputString) {
    // Initialize a counter for underscores
  
    inputString = inputString.replace('user_custom_', '');
  
    let underscoreCount = 0;
  
    // Iterate through the input string
    for (let i = 0; i < inputString.length; i++) {
      // If an underscore is found, increment the counter
      if (inputString[i] === '_') {
        underscoreCount++;
  
        // If the counter reaches 2, return the substring up to the 2nd underscore
        if (underscoreCount === 2) {
          return inputString.substring(inputString.indexOf("_") + 1).replace('_content', '');
        }
      }
    }
    // If there are less than or equal to 2 underscores, return the original string
  
    if (/^\d+$/.test(inputString.replace('_content', ''))) {
      return "User " + inputString.replace('_content', '');
    }   
    return inputString;
  }

  return (
    <>
      <TableRow
        key={row.filename}
        sx={{
          backgroundColor: isOdd ? theme.palette.table.body.backgroundColor : theme.palette.table.body.backgroundColorLight,
        }}
      >
        <TableCell width={50} sx={{paddingRight:1, paddingY: 1}}>
          <Checkbox
            checked={selectedFiles.includes(row)}
            onChange={(event) => {
              if (event.target.checked) {
                setSelectedFiles([...selectedFiles, row]);
              } else {
                setSelectedFiles(selectedFiles.filter((file) => file !== row));
              }
            }}
          />
        </TableCell>
        <TableCell align="left" sx={{paddingY: 1}}>{row.filename ? row.filename : "No Filename Found"}</TableCell>
        <TableCell align="left" sx={{paddingY: 1}}>{row.CUI ? <FiCheckCircle size={18}/> : undefined}</TableCell>
        {!groupDatasets && (
          <TableCell align="left" sx={{paddingY: 1}}>{row.source_string ? processDataset(row.source_string) : "No Dataset Found"}</TableCell>
        )}
        <TableCell align="center" sx={{paddingY: 1}}>
          { datasetPermission === 'owner' && (
          <IconButton aria-label='delete' size='small' onClick={()=>{setDeleteDialog(true)}}>
            <FiTrash/>
          </IconButton>
          )}
        </TableCell>
      </TableRow>
      {deleteDialog && (
        <DeleteFilesDialog
          open={deleteDialog}
          setOpen={setDeleteDialog}
          row={row}
          refetch={refetch}
        />)}
    </>
  )
}