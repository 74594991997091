import { 
  Button, 
  Link, 
  useTheme, 
  Paper, 
  Typography, 
  Box, 
  Alert, 
  AlertTitle, 
  List, 
  ListItem, 
  ListItemIcon, 
  ListItemText, 
  Divider, 
  Card, 
  CardContent,
  Stack
} from '@mui/material';
import { 
  MdSecurity, 
  MdWarning, 
  MdInfo, 
  MdGavel, 
  MdError, 
  MdQuestionAnswer, 
  MdSupport, 
  MdCreditCard,
  MdOutlineVerified
} from 'react-icons/md';
import * as default_data from '../../../config';

export const Notices = ({ paid, cur_user }) => {
  const theme = useTheme();
  
  // Organize notices by type for better structure
  const securityNotices = [];
  const infoNotices = [];
  const supportLinks = [
    {
      icon: <MdQuestionAnswer />,
      text: "Questions? Join our community on Discord:",
      link: "https://discord.gg/upxuAdNvjY",
      linkText: "https://discord.gg/upxuAdNvjY"
    },
    {
      icon: <MdSupport />,
      text: "Having an issue? Email us at",
      link: `mailto:${default_data.default_support_email}`,
      linkText: default_data.default_support_email
    },
    {
      icon: <MdCreditCard />,
      text: "Reached your token limits? Email us at",
      link: "mailto:sales@asksage.ai",
      linkText: "sales@asksage.ai"
    }
  ];
  
  // Add conditional notices based on config
  if (!default_data.default_hide_cac) {
    securityNotices.push({
      icon: <MdOutlineVerified />,
      severity: "success",
      title: "Authorization Status",
      text: "Ask Sage is authorized for CUI: we have received a DISA IL5 PA, various DoD ATOs, FedRAMP High authorization and various civilian agencies authorizations for CUI/IL5."
    });
    
    securityNotices.push({
      icon: <MdWarning />,
      severity: "warning",
      title: "Government Users Only",
      text: "WARNING: always store CUI data inside of custom datasets with \"-CUI\" in its the name."
    });
    
    securityNotices.push({
      icon: <MdGavel />,
      severity: "info",
      title: "System Monitoring Notice",
      text: "NOTICE: This system and its usage are subject to monitoring, recording, and auditing. Unauthorized use of this system is strictly prohibited and may result in criminal and civil penalties. By using this system, you indicate your consent to monitoring and recording."
    });
    
    securityNotices.push({
      icon: <MdError />,
      severity: "error",
      title: "CAC Access Issue",
      text: "GOVERNMENT USERS ONLY: if you get the error \"CAC not found\", your local proxy is blocking your CAC. This is NOT something we can fix on our side, you need to reach out to your local IT support to ask *.asksage.ai to be whitelisted for CAC passthrough."
    });
  }
  
  infoNotices.push({
    icon: <MdInfo />,
    severity: "info",
    title: "Model Support",
    text: "INFORMATION: Ask Sage can support any large language models as requested, please reach out to us."
  });
  
  return (
    <Paper sx={{ padding: 3, mt: 2, borderRadius: 1 }}>
      <Typography variant="h5" component="h3" className="modal_header" sx={{ mb: 3, textAlign: 'center', fontWeight: 'medium' }}>
        Important Notices
      </Typography>
      
      <Box className="modal_list">
        {securityNotices.length > 0 && (
          <>
            <Typography variant="h6" sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
              <MdSecurity style={{ marginRight: '8px', color: theme.palette.primary.main }} />
              Security & Compliance Notices
            </Typography>
            
            <Stack spacing={2} sx={{ mb: 4 }}>
              {securityNotices.map((notice, index) => (
                <Alert 
                  key={index} 
                  severity={notice.severity}
                  icon={notice.icon}
                  variant="outlined"
                  sx={{ 
                    '& .MuiAlert-icon': { 
                      alignItems: 'center',
                      fontSize: '1.25rem'
                    }
                  }}
                >
                  <AlertTitle sx={{ fontWeight: 'bold' }}>{notice.title}</AlertTitle>
                  {notice.text}
                </Alert>
              ))}
            </Stack>
          </>
        )}
        
        {infoNotices.length > 0 && (
          <>
            <Typography variant="h6" sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
              <MdInfo style={{ marginRight: '8px', color: theme.palette.primary.main }} />
              General Information
            </Typography>
            
            <Stack spacing={2} sx={{ mb: 4 }}>
              {infoNotices.map((notice, index) => (
                <Alert 
                  key={index} 
                  severity={notice.severity}
                  icon={notice.icon}
                  variant="outlined"
                  sx={{ 
                    '& .MuiAlert-icon': { 
                      alignItems: 'center',
                      fontSize: '1.25rem'
                    }
                  }}
                >
                  <AlertTitle sx={{ fontWeight: 'bold' }}>{notice.title}</AlertTitle>
                  {notice.text}
                </Alert>
              ))}
            </Stack>
          </>
        )}
        
        {(paid != true && default_data.default_customer_tenant !== true) || (cur_user && cur_user.plan_type && cur_user.plan_type === 'trial') ? (
          <Box sx={{ textAlign: 'center', my: 4, py: 2, px: 3, bgcolor: 'background.default', borderRadius: 2, boxShadow: 1 }}>
            <Typography variant="h6" gutterBottom>
              Upgrade to unlock all features
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
              Get access to more models, plugins, and higher token limits
            </Typography>
            <Button 
              variant='contained' 
              href="/payment" 
              size="large"
              sx={{ px: 4, py: 1 }}
            >
              Subscribe now for more features & tokens!
            </Button>
          </Box>
        ) : null}
        
        <Divider sx={{ my: 3 }} />
        
        <Typography variant="h6" sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
          <MdSupport style={{ marginRight: '8px', color: theme.palette.primary.main }} />
          Support & Resources
        </Typography>
        
        <Card sx={{ borderRadius: 1, boxShadow: 1, mb: 2 }}>
          <CardContent>
            <List disablePadding>
              {supportLinks.map((item, index) => (
                <ListItem 
                  key={index} 
                  disablePadding 
                  sx={{ 
                    py: 1,
                    borderBottom: index < supportLinks.length - 1 ? '1px solid' : 'none',
                    borderColor: 'divider'
                  }}
                >
                  <ListItemIcon sx={{ color: theme.palette.primary.main, minWidth: 40 }}>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Box component="span" sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                        <Typography variant="body1" component="span" sx={{ mr: 0.5 }}>
                          {item.text}
                        </Typography>
                        <Link 
                          href={item.link} 
                          target="_blank" 
                          rel="noopener"
                          sx={{ 
                            textTransform: 'none', 
                            color: theme.palette.textColorSecondary || theme.palette.primary.main,
                            fontWeight: 'medium'
                          }}
                        >
                          {item.linkText}
                        </Link>
                      </Box>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </CardContent>
        </Card>
      </Box>
    </Paper>
  );
};