import React from 'react';
import { Box, Typography, Tooltip, IconButton } from '@mui/material';
import { FaBuilding, FaUsers, FaChartBar, FaUserShield, FaChartPie } from 'react-icons/fa';

const AdminHorizontalMenu = ({ type, full_stats }) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      padding={0}
      bgcolor="background.paper"
      boxShadow={1}
      borderRadius={0}
      overflow="auto" // Enable horizontal scrolling
      maxWidth="100%" // Ensure it fits within the screen width
      sx={{ whiteSpace: 'nowrap' }} // Prevent items from wrapping
    >
      {type === 'superadmin' && (
        <Tooltip title="Manage Organizations" arrow>
          <IconButton href="/superadmin/get-organizations" color="primary" sx={{ margin: 0.5 }}>
            <FaBuilding size={22} />
            <Typography variant="caption" sx={{ marginLeft: 0.3 }}>Organizations</Typography>
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title="View Users" arrow>
        <IconButton href={`/${type}/get-users`} color="primary" sx={{ margin: 0.5 }}>
          <FaUsers size={22} />
          <Typography variant="caption" sx={{ marginLeft: 0.3 }}>Users</Typography>
        </IconButton>
      </Tooltip>
      <Tooltip title="User Consumption Stats" arrow>
        <IconButton href={`/${type}/get-user-stats`} color="primary" sx={{ margin: 0.5 }}>
          <FaChartBar size={22} />
          <Typography variant="caption" sx={{ marginLeft: 0.3 }}>User Consumption</Typography>
        </IconButton>
      </Tooltip>
      <Tooltip title="Token Statistics" arrow>
        <IconButton href={`/${type}/get-token-stats`} color="primary" sx={{ margin: 0.5 }}>
          <FaChartPie size={22} />
          <Typography variant="caption" sx={{ marginLeft: 0.3 }}>Token Stats</Typography>
        </IconButton>
      </Tooltip>
      {type === 'superadmin' && (
        <Tooltip title="Tenant Subscribers Stats" arrow>
          <IconButton href="/superadmin/get-stats" color="primary" sx={{ margin: 0.5 }}>
            <FaUserShield size={22} />
            <Typography variant="caption" sx={{ marginLeft: 0.3 }}>Tenant Stats</Typography>
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
};

export default AdminHorizontalMenu;