import React, { useState } from 'react';
import { Button, Alert } from '@mui/material';
import { startRegistration } from '@simplewebauthn/browser';
import * as default_data from '../../config.js';
import { getToken } from "../../AuthService";
import { FaFingerprint } from 'react-icons/fa';
import * as log from 'loglevel';

export const WebAuthnTab = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [WebAuthnErrorMessage, setWebAuthnErrorMessage] = useState(undefined);
  const [WebAuthnSuccessMessage, setWebAuthnSuccessMessage] = useState(undefined);

  const registerDevice = async () => {
    setIsLoading(true);
    setWebAuthnErrorMessage('');
    setWebAuthnSuccessMessage('');
    const token = getToken();
    if (!token) {
      setWebAuthnErrorMessage('Authentication token missing. Please log in again.');
      setIsLoading(false);
      return;
    }

    try {
      const resp = await fetch(default_data.default_user_service_url + '/webauthn-register-options', {
        method: 'GET',
        headers: { 'x-access-tokens': token }
      });
      if (!resp.ok) throw new Error('Failed to get registration options');
      const responseData = await resp.json();
      
      // Extract the challenge
      const challenge = responseData.serverChallenge;
      delete responseData.serverChallenge;  // Remove the challenge from the options

      const attestationResp = await startRegistration(responseData);
      const verifyResp = await fetch(default_data.default_user_service_url + '/webauthn-register-verify', {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json', 
          'x-access-tokens': token,
          'X-WebAuthn-Challenge': challenge  // Include the challenge in the header
        },
        body: JSON.stringify(attestationResp),
      });
      if (!verifyResp.ok) {
        const err = await verifyResp.json();
        throw new Error(err.message || 'Registration failed');
      }
      setWebAuthnSuccessMessage('Device registered successfully!');
    } catch (err) {
      log.error(`/WebAuthnTab: ${err.message}`);
      setWebAuthnErrorMessage('Failed to register: please contact customer support.');
    } finally {
      setIsLoading(false);
    }
  };

  if(!default_data.default_show_webauthn ){
    return;
  }

  return (
    <>
      {WebAuthnErrorMessage && (
        <Alert severity="error" onClose={()=>{setWebAuthnErrorMessage(undefined)}} size={"small"}>
          {WebAuthnErrorMessage}
        </Alert>
      )}
      {WebAuthnSuccessMessage && (
        <Alert severity="success" onClose={()=>{setWebAuthnSuccessMessage(undefined)}} size={"small"}>
          {WebAuthnSuccessMessage}
        </Alert>
      )}
      <Button
        variant="contained"
        color="primary"
        onClick={registerDevice}
        disabled={isLoading}
        startIcon={<FaFingerprint />}
        fullWidth        
      >
        Register Yubikey / Device
      </Button>
    </>
  );
};