import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  forwardRef,
  useImperativeHandle
} from "react";
import { ProseMirror } from "@nytimes/react-prosemirror";
import { stripMarkdown } from '../Utils/text_functions.js'
import { EditorState, Plugin } from "prosemirror-state";
import { Schema, Slice, Fragment } from "prosemirror-model";
import { schema as basicSchema } from "prosemirror-schema-basic";
import {
  addListNodes,
  liftListItem,
  sinkListItem,
  splitListItem,
  wrapInList
} from "prosemirror-schema-list";
import { baseKeymap, toggleMark, setBlockType } from "prosemirror-commands";
import { keymap } from "prosemirror-keymap";
import { Decoration, DecorationSet } from "prosemirror-view";
import { Divider, IconButton, MenuItem, Select, Tooltip, useMediaQuery } from "@mui/material";
import { FiCheck, FiCopy } from "react-icons/fi";
import {
  FaBold,
  FaItalic,
  FaUnderline,
} from "react-icons/fa";
import { LuUndo2, LuRedo2 } from "react-icons/lu";
import CopyToClipboard from "react-copy-to-clipboard";
import FloatingButton from "./FloatingButton";
import * as log from 'loglevel';

import MarkdownIt from "markdown-it";
import {
  defaultMarkdownParser,
  defaultMarkdownSerializer,
  MarkdownParser,
  MarkdownSerializer
} from "prosemirror-markdown";
import { FaListOl, FaListUl } from "react-icons/fa6";
import "../App.css"

const underlineMark = {
  parseDOM: [
    { tag: "u" },
    {
      style: "text-decoration",
      getAttrs: (value) => value === "underline"
    }
  ],
  toDOM: () => ["u", 0]
};

const em = {
  parseDOM: [{ tag: 'em' }, { tag: 'i' }, { style: 'font-style=italic' }],
  toDOM: () => ['em', 0],
}

const strong = {
  parseDOM: [{ tag: "strong" }, { tag: "b" }],
  toDOM: () => ["strong", 0]
};

const codeMarkSpec = {
  parseDOM: [{ tag: "code" }],
  toDOM() {
    return ["code", 0];
  }
};

const proseSchema = new Schema({
  nodes: addListNodes(basicSchema.spec.nodes, "paragraph block*", "block"),
  marks: {
    ...basicSchema.spec.marks,
    em: em,
    strong: strong,
    underline: underlineMark,
    code: codeMarkSpec
  }
});

const md = new MarkdownIt();
md.disable([
  'fence',
  'link',
  'linkify',
  'strikethrough',
  'table',
  'blockquote',
  'image'
]);

md.inline.ruler.before("emphasis", "underline", (state, silent) => {
  const start = state.pos;
  const max = state.posMax;
  if (start + 1 >= max) return false;
  if (state.src.charCodeAt(start) !== 0x2b || state.src.charCodeAt(start + 1) !== 0x2b) return false;
  const match = state.src.indexOf("++", start + 2);
  if (match === -1 || match >= max) return false;

  const content = state.src.slice(start + 2, match);
  state.pos = match + 2;
  if (!silent) {
    const tokenOpen = state.push("underline_open", "u", 1);
    tokenOpen.markup = "++";

    const tokenText = state.push("text", "", 0);
    tokenText.content = content;

    const tokenClose = state.push("underline_close", "u", -1);
    tokenClose.markup = "++";
  }
  return true;
});

const tokenMap = {
  ...defaultMarkdownParser.tokenMap,
  text: { node: "text" },
  hardbreak: { node: "hard_break"},
  hr: { node: "horizontal_rule" },
  paragraph: { block: "paragraph" },
  heading: { block: "heading", getAttrs: (tok) => ({ level: +tok.tag.slice(1) }) },
  bullet_list: { block: "bullet_list" },
  ordered_list: { block: "ordered_list", getAttrs: (tok) => ({ order: +tok.attrGet("order") || 1 }) },
  list_item: { block: "list_item" },
  strong: { mark: "strong" },
  em: { mark: "em" },
  underline: { mark: "underline" },
};


const markdownParser = new MarkdownParser(proseSchema, md, tokenMap);

const customMarks = {
  ...defaultMarkdownSerializer.marks,
  underline: {
    open: "++",
    close: "++",
    mixable: true,
    expelEnclosingWhitespace: true
  }
};


const customMarkdownSerializer = new MarkdownSerializer(
  defaultMarkdownSerializer.nodes,
  customMarks
);

function placeholderPlugin(placeholderText = "Type here…") {
  return new Plugin({
    props: {
      decorations(state) {
        const { doc } = state;
        const isEmpty =
          doc.childCount === 1 &&
          doc.firstChild.isTextblock &&
          doc.firstChild.content.size === 0;
        if (!isEmpty) return null;

        const deco = Decoration.widget(1, () => {
          const span = document.createElement("span");
          span.style.color = "#aaa";
          span.textContent = placeholderText;
          return span;
        });
        return DecorationSet.create(doc, [deco]);
      }
    }
  });
}

function stripAllMarks(node, schema) {
  if (node.isText) {
    return schema.text(node.text, []);
  }
  if (node.isLeaf) {
    return node;
  }
  const strippedNode = [];
  node.forEach(child => {
    strippedNode.push(stripAllMarks(child, proseSchema));
  });
  return node.copy(Fragment.from(strippedNode));
}

const pasteAsMarkdownPlugin = new Plugin({
  props: {
    handlePaste(view, event) {
      event.preventDefault();
      const text = event.clipboardData?.getData("text/plain") || "";
      if (!text) return false;

      const doc = markdownParser.parse(text);
      const slice = new Slice(doc.content, 0, 0);
      view.dispatch(view.state.tr.replaceSelection(slice));
      return true;
    }
  }
});

function filterSelectionChangesPlugin() {
  return new Plugin({
    filterTransaction(transaction, state) {
      if (transaction.docChanged) return true;

      if (transaction.selectionSet) return true;

      return false;
    },
  });
}

const plugins = [
  keymap({
    "Mod-b": toggleMark(proseSchema.marks.strong),
    "Mod-i": toggleMark(proseSchema.marks.em),
    "Mod-u": toggleMark(proseSchema.marks.underline),
    "Enter": splitListItem(proseSchema.nodes.list_item),
    "Tab": sinkListItem(proseSchema.nodes.list_item),
    "Shift-Tab": liftListItem(proseSchema.nodes.list_item)
  }),
  keymap(baseKeymap),
  placeholderPlugin("Type something..."),
  pasteAsMarkdownPlugin,
  filterSelectionChangesPlugin()
];

const ProseRenderer = forwardRef(function ProseRenderer(
  {
    markdown,
    onMarkdownChange,
    history = [],
    currentHistoryIndex = -1,
    setHistory,
    setCurrentHistoryIndex
  },
  ref
) {
  const [highlightChanges, setHighlightChanges] = useState(false);
  const [fullMarkdownCopied, setFullMarkdownCopied] = useState(false);
  const [cleanMarkdownCopied, setCleanMarkdownCopied] = useState(false);
  const [mount, setMount] = useState(null);

  const debounceTimer = useRef(null);
  const lastMarkdownRef = useRef(markdown);

  const [editorState, setEditorState] = useState(() => {
    let doc;
    try {
      doc = markdownParser.parse(markdown ?? "");
    } catch (error) {
      log.error("Error parsing markdown:", error);
      doc = proseSchema.nodeFromJSON({ type: "doc", content: [] });
    }
    return EditorState.create({
      doc,
      schema: proseSchema,
      plugins
    });
  });

  useEffect(() => {
    if (markdown !== lastMarkdownRef.current) {
      let doc;
      try {
        doc = markdownParser.parse(markdown ?? "");
      } catch (error) {
        log.error("Error parsing markdown:", error);
        doc = proseSchema.nodeFromJSON({ type: "doc", content: [] });
      }
      const newState = EditorState.create({
        doc,
        schema: proseSchema,
        plugins
      });
      setEditorState(newState);
      lastMarkdownRef.current = markdown;
    }
  }, [markdown]);

  useImperativeHandle(ref, () => ({
    getSelectedText() {
      const { from, to } = editorState.selection;
      if (from === to) return "";
      return editorState.doc.textBetween(from, to, "\n", "\n");
    }
  }));

  const handleChange = useCallback(newState => {
    const mdText = customMarkdownSerializer.serialize(newState.doc);
    lastMarkdownRef.current = mdText;

    if (debounceTimer.current) clearTimeout(debounceTimer.current);
    debounceTimer.current = setTimeout(() => {
      onMarkdownChange(mdText);
      debounceTimer.current = null;

      setHistory((prev = []) => {
        const trimmed = [...prev.slice(0, currentHistoryIndex + 1), mdText];
        if (trimmed.length > 20) trimmed.shift();
        setCurrentHistoryIndex(trimmed.length - 1);
        return trimmed;
      });
    }, 500);
  }, [onMarkdownChange, currentHistoryIndex, setHistory, setCurrentHistoryIndex]);

  const dispatchTransaction = useCallback((transaction) => {
    const newState = editorState.apply(transaction);
    setEditorState(newState);
  
    if (transaction.docChanged) {
      handleChange(newState);
    }
  }, [editorState, handleChange]);

  const handleUndo = useCallback(() => {
    if (currentHistoryIndex > 0) {
      const idx = currentHistoryIndex - 1;
      setCurrentHistoryIndex(idx);
      onMarkdownChange(history[idx]);
    }
  }, [currentHistoryIndex, history, onMarkdownChange, setCurrentHistoryIndex]);

  const handleRedo = useCallback(() => {
    if (currentHistoryIndex < history.length - 1) {
      const idx = currentHistoryIndex + 1;
      setCurrentHistoryIndex(idx);
      onMarkdownChange(history[idx]);
    }
  }, [currentHistoryIndex, history, onMarkdownChange, setCurrentHistoryIndex]);

  const handleToggleMark = (markName) => {
    const markType = proseSchema.marks[markName];
    if (!markType) return;
    toggleMark(markType)(editorState, transaction => {
      const newState = editorState.apply(transaction);
      setEditorState(newState);
      handleChange(newState);
    });
  };

  const handleSetBlockType = (value) => {
    const { nodes } = proseSchema;
    if (value.startsWith("heading")) {
      const level = parseInt(value.slice("heading".length), 10) || 1;
      setBlockType(nodes.heading, { level })(editorState, transaction => {
        const newState = editorState.apply(transaction);
        setEditorState(newState);
        handleChange(newState);
      });
    } else {
      setBlockType(nodes.paragraph, {})(editorState, transaction => {
        const newState = editorState.apply(transaction);
        setEditorState(newState);
        handleChange(newState);
      });
    }
  };

  const getCleanMarkdown = useCallback(() => {
    try {
      let raw = lastMarkdownRef.current || "";
      let clean = stripMarkdown(raw);
      clean = clean.replace(/^#+\s+/gm, "");
      clean = clean.replace(/\\/g, "");
      return clean;
    } catch (error) {
      log.error("serialize error", error);
      return "";
    }
  }, []);

  function findListAncestor($pos, bulletListNode, orderedListNode) {
    for (let depth = $pos.depth; depth >= 0; depth--) {
      const node = $pos.node(depth);
      if (node.type === bulletListNode) {
        return bulletListNode;
      } else if (node.type === orderedListNode) {
        return orderedListNode;
      }
    }
    return null;
  }

  const toggleList = (listType, itemType) => {
    return (state, dispatch) => {
      const { bullet_list, ordered_list } = state.schema.nodes;
      const { $from, $to } = state.selection;
  
      const range = $from.blockRange($to);
      if (!range) return false;
  
      const currentListType = findListAncestor($from, bullet_list, ordered_list);
      const otherListType = (listType === bullet_list) ? ordered_list : bullet_list;
  
      if (currentListType === listType) {
        return liftListItem(itemType)(state, dispatch);
      }
  
      if (currentListType === otherListType) {
        if (liftListItem(itemType)(state, dispatch)) {
          return wrapInList(listType)(state, dispatch);
        }
        return false;
      }
  
      return wrapInList(listType)(state, dispatch);
    };
  }

  const handleToggleBulletList = () => {
    const { bullet_list, list_item } = proseSchema.nodes;
  
    const dispatch = (transaction) => {
      const newState = editorState.apply(transaction);
      setEditorState(newState);
      if (transaction.docChanged) {
        handleChange(newState);
      }
    };

    toggleList(bullet_list, list_item)(editorState, dispatch);

    document.querySelector(".ProseMirror")?.focus();
  };
  
  const handleToggleNumberedList = () => {
    const { ordered_list, list_item } = proseSchema.nodes;
  
    const dispatch = (transaction) => {
      const newState = editorState.apply(transaction);
      setEditorState(newState);
      if (transaction.docChanged) {
        handleChange(newState);
      }
    };

    toggleList(ordered_list, list_item)(editorState, dispatch);

    document.querySelector(".ProseMirror")?.focus();
  };

  const fullMarkdown = lastMarkdownRef.current || "";
  const canUndo = currentHistoryIndex > 0;
  const canRedo = currentHistoryIndex < history.length - 1;
  const isSmallScreen = useMediaQuery("(max-width:1350px)");

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%", padding: 8 }}>
      {/* Toolbar */}
      <div style={{ display: "flex", alignItems: "center", marginBottom: 8 }}>
        {/* Copy Full Markdown */}
        <CopyToClipboard text={fullMarkdown} onCopy={() => {
          setFullMarkdownCopied(true);
          setTimeout(() => setFullMarkdownCopied(false), 2000);
        }}>
          <Tooltip title="Copy Full Markdown" placement="right">
            <IconButton color="primary" size="large">
            {fullMarkdownCopied ? <FiCheck size={20} /> : <FiCopy size={20} />}
            </IconButton>
          </Tooltip>
        </CopyToClipboard>

        {/* Copy Clean Markdown */}
        <CopyToClipboard text={getCleanMarkdown()} onCopy={() => {
          setCleanMarkdownCopied(true);
          setTimeout(() => setCleanMarkdownCopied(false), 2000);
        }}>
          <Tooltip title="Copy Clean Markdown" placement="right">
            <IconButton color="primary" size="large">
            {cleanMarkdownCopied ? <FiCheck color="grey" size={20} /> : <FiCopy color="grey" size={20} />}
            </IconButton>
          </Tooltip>
        </CopyToClipboard>

        {/* Undo */}
        <Tooltip title="Undo" placement="right">
          <span>
            <IconButton
              color="secondary"
              size="large"
              onClick={handleUndo}
              disabled={!canUndo}
            >
              <LuUndo2 size={20} />
            </IconButton>
          </span>
        </Tooltip>

        {/* Redo */}
        <Tooltip title="Redo" placement="right">
          <span>
            <IconButton
              color="secondary"
              size="large"
              onClick={handleRedo}
              disabled={!canRedo}
            >
              <LuRedo2 size={20} />
            </IconButton>
          </span>
        </Tooltip>

        <Divider orientation="vertical" sx={{ mx: 1, height: "60%"}} />

        {/* Bold */}
        <Tooltip title="Bold" placement="bottom">
          <IconButton onMouseDown={(event) => {
              event.preventDefault();
              handleToggleMark("strong");
            }}>
            <FaBold size={18} />
          </IconButton>
        </Tooltip>

        {/* Italic */}
        <Tooltip title="Italic" placement="bottom">
          <IconButton onMouseDown={(event) => {
              event.preventDefault();
              handleToggleMark("em");
            }}>
            <FaItalic size={18} />
          </IconButton>
        </Tooltip>

        {/* Underline */}
        <Tooltip title="Underline" placement="bottom">
          <IconButton onMouseDown={(event) => {
              event.preventDefault();
              handleToggleMark("underline");
            }}>
            <FaUnderline size={18} />
          </IconButton>
        </Tooltip>

        {!isSmallScreen && (
          <>
            <Divider orientation="vertical" sx={{ mx: 1, height: "60%"}} />
            {/* Bulleted List */}
            <Tooltip title="Bulleted List" placement="bottom">
              <IconButton onClick={handleToggleBulletList}>
                <FaListUl size={18} />
              </IconButton>
            </Tooltip>
            {/* Ordered List */}
            <Tooltip title="Ordered List" placement="bottom">
              <IconButton onClick={handleToggleNumberedList}>
                <FaListOl size={18} />
              </IconButton>
            </Tooltip>
          </>
        )
      }

        <Divider orientation="vertical" sx={{ mx: 1, height: "60%"}} />

        {/* Headings Dropdown */}
        <Select
          variant="standard"
          onChange={(e) => handleSetBlockType(e.target.value)}
          defaultValue="body"
          style={{ marginLeft: 8, width: '100px', height: "24px" }}
        >
          <MenuItem value="body">Body</MenuItem>
          <MenuItem value="heading1">Heading 1</MenuItem>
          <MenuItem value="heading2">Heading 2</MenuItem>
          <MenuItem value="heading3">Heading 3</MenuItem>
          <MenuItem value="heading4">Heading 4</MenuItem>
          <MenuItem value="heading5">Heading 5</MenuItem>
          <MenuItem value="heading6">Heading 6</MenuItem>
        </Select>
      </div>

      {/* ProseMirror Editor */}
      <div className="prose-mirror">
        <ProseMirror
          mount={mount}
          state={editorState}
          dispatchTransaction={dispatchTransaction}
        >
          <div ref={setMount} />
        </ProseMirror>
      </div>
    </div>
  );
})

export default ProseRenderer;
