import React, { useState } from 'react';
import { FiTrash2 } from 'react-icons/fi';
import * as log from 'loglevel';
import { unEscapeHTML } from '../Utils/text_functions';
import { IconButton, Stack, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { MdCopyAll, MdDone, MdVisibility, MdVisibilityOff } from 'react-icons/md';

export const ApiKeysVignette = ({ info, deleteClick }) => {
  const [showKey, setShowKey] = useState(false);
  const [copied, setCopied] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const toggleKeyVisibility = () => {
    setShowKey(!showKey);
  };

  const maskedKey = (key) => {
    return key.replace(/./g, '*');
  };

  const copyAPIKey = () => {
    navigator.clipboard.writeText(info.key)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      })
      .catch(error => {
        log.error('Failed to copy API key: ', error);
      });
  };

  return (
    <Stack 
      key={info.id} 
      tag={info.id} 
      direction={isMobile ? 'column' : 'row'} 
      sx={{
        paddingX: 2, 
        paddingY: 1, 
        mb: 1, 
        borderRadius: 2, 
        border: theme.palette.card.border,
        alignItems: 'center',
        justifyContent: 'space-between'
      }}
    >
      <Stack tag={info.id} direction={'column'} width={isMobile ? '100%' : "calc(100% - 120px)"}>
        <Tooltip title={unEscapeHTML(info.name)} placement="top-start" enterDelay={1000}>
          <Typography 
            sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap'
            }}
          >
            {unEscapeHTML(info.name)}
          </Typography>
        </Tooltip>
        <Tooltip title={showKey ? unEscapeHTML(info.key) : maskedKey(info.key)} placement="bottom-start" enterDelay={1000}>
          <Typography 
            sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              fontSize: '0.875rem',
              color: 'text.secondary'
            }}
          >
            {showKey ? unEscapeHTML(info.key) : maskedKey(info.key)}
          </Typography>
        </Tooltip>
      </Stack>
      <Stack tag={info.id} direction={'row'} spacing={1}>
        <Tooltip title={showKey ? 'Hide API key' : 'Show API key'} enterDelay={1000}>
          <IconButton onClick={toggleKeyVisibility} size="small" aria-label='Show API key'>
            {showKey ? <MdVisibilityOff /> : <MdVisibility />}
          </IconButton>
        </Tooltip>
        <Tooltip title="Copy API key" enterDelay={1000}>
          <IconButton onClick={copyAPIKey} size="small" disabled={copied} aria-label='Copy API key'>
            {copied ? <MdDone /> : <MdCopyAll />}
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete API key" enterDelay={1000}>
          <IconButton onClick={()=>{deleteClick(info.id)}} size="small" aria-label='Delete API key'>
            <FiTrash2 />
          </IconButton>
        </Tooltip>
      </Stack>          
    </Stack>
  );
};