import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableVirtuoso } from "react-virtuoso";
import { useTheme } from "@mui/material";

export function ReactVirtualizedTable({ data }) {
  const header = data[0] || [];
  const rows = data.slice(1) || [];
  const MAX_TABLE_HEIGHT = 600;
  const tableHeight = 42 * (rows?.length || 0) + 46;
  const [selectedColumns, setSelectedColumns] = React.useState(new Set());
  const [selectedData, setSelectedData] = React.useState({});

  // Default column width if there are many columns.
  const DEFAULT_COLUMN_WIDTH = 150;
  const [columnWidths, setColumnWidths] = React.useState([]);

  const theme = useTheme();

  // Ref for the table container to measure its width and control scroll.
  const containerRef = React.useRef(null);
  // Ref to store the scroll position when resizing starts.
  const initialScrollLeftRef = React.useRef(0);

  // Initialize column widths based on container width and column count.
  React.useEffect(() => {
    if (header && header.length && containerRef.current.clientWidth) {
      const containerWidth = containerRef.current.clientWidth;
      const calcWidth = containerWidth / header.length;
      const startingWidth = Math.max(DEFAULT_COLUMN_WIDTH, calcWidth);
      setColumnWidths(new Array(header.length).fill(startingWidth));
    }
  }, [header]);

  // Preserve scroll position after column width changes.
  React.useEffect(() => {
    if (containerRef.current) {
      requestAnimationFrame(() => {
        if (initialScrollLeftRef.current !== undefined && containerRef.current.scrollLeft !== undefined) {
          containerRef.current.scrollLeft = initialScrollLeftRef.current;
        }
      });
    }
  }, [columnWidths]);

  // Refs and handlers for drag-to-resize.
  const resizingColRef = React.useRef(null);
  const startXRef = React.useRef(0);
  const startWidthRef = React.useRef(0);

  const handleMouseDown = (event, colIndex) => {
    event.preventDefault();
    if (
      colIndex !== undefined && 
      startWidthRef.current !== undefined && 
      startXRef.current !== undefined && 
      event.clientX !== undefined &&
      columnWidths[colIndex] !== undefined
    ) {
      resizingColRef.current = colIndex;
      startXRef.current = event.clientX;
      startWidthRef.current = columnWidths[colIndex] || DEFAULT_COLUMN_WIDTH;
      if (containerRef.current && containerRef.current.scrollLeft) {
        initialScrollLeftRef.current = containerRef.current.scrollLeft;
      }
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    }
  };

  const handleMouseMove = (event) => {
    if (resizingColRef.current === null) return;
    if (
      startXRef.current === undefined ||
      startWidthRef.current === undefined ||
      event.clientX === undefined
    ){
      return;
    }
    const delta = event.clientX - startXRef.current;
    const newWidth = Math.max(startWidthRef.current + delta, 50); // Minimum width = 50px
    setColumnWidths((prevWidths) => {
      const newWidths = [...prevWidths];
      newWidths[resizingColRef.current] = newWidth;
      return newWidths;
    });
  };

  const handleMouseUp = () => {
    resizingColRef.current = null;
    window.removeEventListener("mousemove", handleMouseMove);
    window.removeEventListener("mouseup", handleMouseUp);
  };

  // Optional column-click selection logic.
  const handleColumnClick = React.useCallback(
    (colIndex) => {
      setSelectedColumns((prev) => {
        const newSelected = new Set(prev);
        newSelected.has(colIndex)
          ? newSelected.delete(colIndex)
          : newSelected.add(colIndex);
        return newSelected;
      });

      setSelectedData((prev) => {
        const newData = { ...prev };
        if (newData[colIndex]) {
          delete newData[colIndex];
        } else {
          newData[colIndex] = rows.map((row) => row[colIndex]);
        }
        return newData;
      });
    },
    [rows]
  );

  // Render the header row with an enlarged grab zone entirely within the cell.
  const fixedHeaderContent = React.useCallback(
    () => (
      <TableRow>
        {header.map((cell, colIndex) => (
          <TableCell
            key={colIndex}
            variant="head"
            align="center"
            // onClick={() => handleColumnClick(colIndex)}
            sx={{
              backgroundColor: selectedColumns.has(colIndex)
                ? "#90caf9"
                : "transparent",
              color: selectedColumns.has(colIndex)
                ? "black"
                : theme?.palette?.text?.primary || "inherit",
              fontWeight: "bold",
              borderTop: "1px solid #CCC",
              borderBottom: "1px solid #CCC",
              borderLeft: colIndex === 0 ? "1px solid #CCC" : "none",
              padding: "10px",
              pr: "30px", // extra right padding for the grab zone
              whiteSpace: "normal", // allow text wrapping
              overflow: "hidden",
              textOverflow: "ellipsis",
              wordWrap: "break-word",
              position: "relative", // required for absolute positioning of the handle
            }}
          >
            {colIndex !== 0 && (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0, // now fully inside the cell
                  width: "8px", // enlarged grab zone width
                  height: "100%",
                  cursor: "col-resize",
                  userSelect: "none",
                  borderLeft: "1px solid #CCC",
                }}
                onMouseDown={(e) => handleMouseDown(e, colIndex - 1)}
              />
            )}
            {cell}
            <div
              style={{
                position: "absolute",
                top: 0,
                right: 0, // now fully inside the cell
                width: "8px", // enlarged grab zone width
                height: "100%",
                cursor: "col-resize",
                userSelect: "none",
                borderRight: "1px solid #CCC",
              }}
              onMouseDown={(e) => handleMouseDown(e, colIndex)}
            />
          </TableCell>
        ))}
      </TableRow>
    ),
    [header, selectedColumns, theme, handleColumnClick, columnWidths]
  );

  // Memoized row component.
  const Row = React.memo(({ row, rowIndex }) => (
    <>
      {row.map((cell, cellIndex) => (
        <TableCell
          key={cellIndex}
          sx={{
            padding: "10px",
            backgroundColor: selectedColumns.has(cellIndex)
              ? "#90caf9"
              : "transparent",
            color: selectedColumns.has(cellIndex)
              ? "black"
              : theme?.palette?.text?.primary || "inherit",
            border: "1px solid #CCC",
            whiteSpace: "normal", // allow wrapping
            overflow: "hidden",
            textOverflow: "ellipsis",
            wordWrap: "break-word",
          }}
        >
          {cell}
        </TableCell>
      ))}
    </>
  ));

  const rowContent = React.useCallback(
    (index, row) => <Row row={row} rowIndex={index} />,
    [selectedColumns, theme]
  );

  // Custom table applying dynamic column widths via a colgroup.
  const CustomTable = React.forwardRef((props, ref) => (
    <Table
      {...props}
      ref={ref}
      sx={{
        borderCollapse: "separate",
        borderSpacing: 0,
        tableLayout: "fixed",
        width:
          columnWidths.length > 0
            ? `${columnWidths.reduce((sum, w) => sum + w, 0)}px`
            : "100%",
        boxSizing: "border-box",
      }}
    >
      <colgroup>
        {header.map((_, colIndex) => (
          <col
            key={colIndex}
            style={{
              width: columnWidths[colIndex]
                ? `${columnWidths[colIndex]}px`
                : "auto",
            }}
          />
        ))}
      </colgroup>
      {props.children}
    </Table>
  ));

  // Components passed to TableVirtuoso.
  const components = {
    Scroller: React.forwardRef((props, ref) => (
      <TableContainer
        component={Paper}
        {...props}
        ref={(node) => {
          if (node) {
            containerRef.current = node;
          }
          if (ref) {
            if (typeof ref === "function") {
              ref(node);
            } else {
              ref.current = node;
            }
          }
        }}
        sx={{ overflowX: "auto" }}
      />
    )),
    Table: CustomTable,
    TableHead: React.forwardRef((props, ref) => (
      <TableHead {...props} ref={ref} />
    )),
    TableRow: React.forwardRef((props, ref) => (
      <TableRow
        {...props}
        ref={ref}
        sx={{
          backgroundColor:
            props["data-index"] % 2 === 0
              ? theme?.palette?.action?.hover || "inherit"
              : "inherit",
        }}
      />
    )),
    TableBody: React.forwardRef((props, ref) => (
      <TableBody {...props} ref={ref} />
    )),
  };

  return (
    <TableVirtuoso
      data={rows}
      components={components}
      fixedHeaderContent={fixedHeaderContent}
      itemContent={rowContent}
      // Increase the bottom viewport by 120 pixels (~2–3 rows)
      increaseViewportBy={{ top: 0, bottom: 120 }}
      overscan={120}
      style={{
        height: tableHeight < MAX_TABLE_HEIGHT ? tableHeight : MAX_TABLE_HEIGHT,
        maxHeight: MAX_TABLE_HEIGHT,
        overflowX: "auto",
      }}
    />
  );
}