import { 
  Typography, 
  Paper, 
  Button, 
  Box, 
  Card, 
  CardContent, 
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Chip,
  Grid,
  useTheme
} from '@mui/material';
import { 
  MdExpandMore, 
  MdCheck, 
  MdApi, 
  MdSearch, 
  MdCode, 
  MdStorage, 
  MdUpload, 
  MdMic, 
  MdWeb, 
  MdOutlineTerminal, 
  MdSettings
} from "react-icons/md";
import * as default_data from '../../../config';
import { plans } from '../../../Data/plans';

export const PaidFeatures = ({paid, cur_user}) => {
  const theme = useTheme();
  
  // Model features data for better organization
  const modelFeatures = [
    { name: "GPT-3.5-16K", description: "40 pages context window!" },
    { name: "GPT-4-32K", description: "80 pages context window!" },
    { name: "GPT-4-Vision", description: "300 pages context window with image recognition!" },
    { name: "GPT-4o", description: "300 pages context window!" },
    { name: "GPT-4o-mini", description: "300 pages context window!" },
    { name: "GPT-o1", description: "300 pages context window!" },
    { name: "GPT-o1-mini", description: "300 pages context window!" },
    { name: "GPT-o3-mini", description: "450 pages context window!" },
    { name: "GPT-4.5-preview", description: "300 pages context window!" },
    { name: "DALL-E (v2)", description: "generate images." },
    { name: "DALL-E (v3)", description: "generate images." },
    { name: "TTS/TTS-HD", description: "text to speech." },
    { name: "Whisper", description: "speech to text." },
    { name: "Claude 3.5 (200K)", description: "500 pages context window! (no CUI)" },
    { name: "Claude 3.7 (200K)", description: "500 pages context window! (no CUI)" },
    { name: "AWS Gov Bedrock Claude 3.5 Sonnet", description: "AWS Gov Bedrock Claude 3.5 Sonnet model" },
    { name: "Google Bison", description: "(Google Bard's model)" },
    { name: "Google Gemini Pro", description: "" },
    { name: "Google Gemini 2.0 Flash", description: "" },
    { name: "Mistral Large", description: "" }
  ];
  
  // Customization options
  const customizationOptions = [
    "Add & Fine-tune your own LLMs",
    "Create custom plugins",
    "Integrate with your data lake/API/data warehouse."
  ];
  
  return (
    <Paper sx={{ padding: 3, mt: 2, borderRadius: 1 }}>
      <Typography variant="h5" component="h3" className="modal_header" sx={{ mb: 3, textAlign: 'center', fontWeight: 'medium' }}>
        Ask Sage Plans
      </Typography>
      
      {/* Desktop view */}
      <Box display={{ xs: 'none', md: 'block' }}>
        <TableContainer component={Paper} sx={{ mb: 4 }}>
          <Table 
            sx={{ 
              minWidth: 650,
              backgroundColor: theme.palette.table?.body?.backgroundColor || 'background.paper',
            }}
          >
            <TableHead sx={{ backgroundColor: theme.palette.table?.header?.backgroundColor || 'background.default' }}>
              <TableRow>
                <TableCell sx={{ paddingY: 1 }}>Plan cost</TableCell>
                <TableCell sx={{ paddingY: 1 }}>Ask Sage Inference Tokens</TableCell>
                <TableCell sx={{ paddingY: 1 }}>Ask Sage Training Tokens</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {plans.map((plan, index) => (
                <TableRow key={index}>
                  <TableCell dangerouslySetInnerHTML={{ __html: plan.cost }}></TableCell>
                  <TableCell>
                    {plan.inferenceTokens}
                    {plan.interfaceDetails && <Typography variant="body2" color="text.secondary" sx={{ mt: 0.5 }}>{plan.interfaceDetails}</Typography>}
                  </TableCell>
                  <TableCell>
                    {plan.trainingTokens}
                    {plan.trainingDetails && <Typography variant="body2" color="text.secondary" sx={{ mt: 0.5 }}>{plan.trainingDetails}</Typography>}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      
      {/* Mobile view */}
      <Box display={{ xs: 'block', md: 'none' }}>
        {plans.map((plan, index) => (
          <Card key={index} sx={{ mb: 2, borderRadius: 1, boxShadow: 1 }}>
            <CardHeader
              sx={{ bgcolor: 'background.default', py: 1.5 }}
              title={
                <div style={{fontSize: '12pt'}} dangerouslySetInnerHTML={{ __html: plan.cost }} />
              }
            />
            <CardContent>
              <Typography variant="subtitle1" gutterBottom><strong>Ask Sage Inference Tokens:</strong></Typography>
              <Typography variant="body1">{plan.inferenceTokens}</Typography>
              {plan.interfaceDetails && <Typography variant="body2" color="text.secondary" sx={{ mt: 0.5, mb: 1.5 }}>{plan.interfaceDetails}</Typography>}
              
              <Typography variant="subtitle1" gutterBottom><strong>Ask Sage Training Tokens:</strong></Typography>
              <Typography variant="body1">{plan.trainingTokens}</Typography>
              {plan.trainingDetails && <Typography variant="body2" color="text.secondary" sx={{ mt: 0.5 }}>{plan.trainingDetails}</Typography>}
            </CardContent>
          </Card>
        ))}
      </Box>
      
      <Box sx={{ textAlign: "center", mt: 2, mb: 4 }}>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
          *Enterprise purchases: you can buy Ask Sage tokens in bulk and assign them to your users (with 200K tokens minimum per user) via our Administration User Interface, without paying a fee per user. Starts at $90/mo for 2M Ask Sage tokens per month! Reach out to us for more information.
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Ask Sage can be deployed on your Cloud enclaves and/or on-premise environments.
        </Typography>
      </Box>
      
      {(paid != true && default_data.default_customer_tenant !== true) || (cur_user && cur_user.plan_type && cur_user.plan_type === 'trial') ? (
        <Box sx={{ textAlign: 'center', my: 3 }}>
          <Button 
            variant='contained' 
            href="/payment" 
            size="large"
            sx={{ px: 4, py: 1 }}
          >
            Subscribe now for more features & tokens!
          </Button>
        </Box>
      ) : null}
      
      <Divider sx={{ my: 4 }} />
      
      <Typography variant="h5" component="h3" className="modal_header" sx={{ mb: 3, textAlign: 'center', fontWeight: 'medium' }}>
        Exclusive Paid Features
      </Typography>
      
      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} md={6}>
          <Card sx={{ height: '100%', borderRadius: 1, boxShadow: 1 }}>
            <CardHeader 
              title="API & Search Features" 
              sx={{ bgcolor: 'background.default', py: 1.5 }}
            />
            <CardContent>
              <List>
                <ListItem>
                  <ListItemIcon><MdApi size={24} color={theme.palette.primary.main} /></ListItemIcon>
                  <ListItemText 
                    primary="Ask Sage API access" 
                    secondary="Integrate Ask Sage into your applications"
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon><MdSearch size={24} color={theme.palette.primary.main} /></ListItemIcon>
                  <ListItemText 
                    primary="Live+ Feature" 
                    secondary="Leverages Bing to search real-time results and loads the first 2 results content for more accurate answers! Warning, this will use more tokens."
                  />
                </ListItem>
              </List>
            </CardContent>
          </Card>
        </Grid>
        
        <Grid item xs={12} md={6}>
          <Card sx={{ height: '100%', borderRadius: 1, boxShadow: 1 }}>
            <CardHeader 
              title="Advanced Tools" 
              sx={{ bgcolor: 'background.default', py: 1.5 }}
            />
            <CardContent>
              <List>
                <ListItem>
                  <ListItemIcon><MdCode size={24} color={theme.palette.primary.main} /></ListItemIcon>
                  <ListItemText 
                    primary="Exclusive Plugins and Agents" 
                    secondary="Over 15 plugins such as RFP answering, Git repository analysis and auditor, Summarization, Ingestion etc."
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon><MdStorage size={24} color={theme.palette.primary.main} /></ListItemIcon>
                  <ListItemText 
                    primary="Data Connectors" 
                    secondary="Postgres, Elastic connectors, and more"
                  />
                </ListItem>
              </List>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      
      <Typography variant="h6" sx={{ mb: 2, mt: 4, fontWeight: 'medium' }}>
        More Models
      </Typography>
      
      <Grid container spacing={1} sx={{ mb: 4 }}>
        {modelFeatures.map((model, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card sx={{ mb: 1, borderRadius: 1, boxShadow: 0, border: '1px solid', borderColor: 'divider' }}>
              <CardContent sx={{ py: 1.5, px: 2, '&:last-child': { pb: 1.5 } }}>
                <Typography variant="subtitle2" fontWeight="bold" component="div">
                  {model.name}
                </Typography>
                {model.description && (
                  <Typography variant="body2" color="text.secondary">
                    {model.description}
                  </Typography>
                )}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      
      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} md={6}>
          <Card sx={{ height: '100%', borderRadius: 1, boxShadow: 1 }}>
            <CardHeader 
              title="File & Data Processing" 
              sx={{ bgcolor: 'background.default', py: 1.5 }}
            />
            <CardContent>
              <List>
                <ListItem>
                  <ListItemIcon><MdUpload size={24} color={theme.palette.primary.main} /></ListItemIcon>
                  <ListItemText 
                    primary="File ingestor (50MB)" 
                    secondary="Supports zip, pdf, xlsx, pptx, docx, ppt, csv, cc, sql, cs, hh, c, php, js, py, html, xml, msg, odt, epub, eml, rtf, txt, doc, json, md, jpeg, jpg, png, tsv, yaml, yml, java, rb, sh, bat, ps1"
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon><MdMic size={24} color={theme.palette.primary.main} /></ListItemIcon>
                  <ListItemText 
                    primary="Speech to text ingestor (Whisper) (500MB max)" 
                    secondary="Supports mp3, mp4, mpeg, mpga, m4a, wav, webm"
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon><MdWeb size={24} color={theme.palette.primary.main} /></ListItemIcon>
                  <ListItemText primary="Web ingestor" />
                </ListItem>
              </List>
            </CardContent>
          </Card>
        </Grid>
        
        <Grid item xs={12} md={6}>
          <Card sx={{ height: '100%', borderRadius: 1, boxShadow: 1 }}>
            <CardHeader 
              title="Development & Customization" 
              sx={{ bgcolor: 'background.default', py: 1.5 }}
            />
            <CardContent>
              <List>
                <ListItem>
                  <ListItemIcon><MdOutlineTerminal size={24} color={theme.palette.primary.main} /></ListItemIcon>
                  <ListItemText primary="Python code sandbox" />
                </ListItem>
                <ListItem>
                  <ListItemIcon><MdSettings size={24} color={theme.palette.primary.main} /></ListItemIcon>
                  <ListItemText 
                    primary="Custom introduction prompt" 
                    secondary="Allows to have a prompt context per user so Ask Sage tracks who you are and your context"
                  />
                </ListItem>
              </List>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      
      <Card sx={{ mb: 3, mt: 4, borderRadius: 1, boxShadow: 1, bgcolor: 'background.default' }}>
        <CardHeader 
          title="Customizations available for additional cost" 
          sx={{ py: 1.5 }}
        />
        <CardContent>
          <List>
            {customizationOptions.map((option, index) => (
              <ListItem key={index}>
                <ListItemIcon><MdCheck size={24} color={theme.palette.success.main} /></ListItemIcon>
                <ListItemText primary={option} />
              </ListItem>
            ))}
          </List>
        </CardContent>
      </Card>
    </Paper>
  );
};