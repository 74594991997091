import { Box, Dialog, DialogActions, DialogTitle, IconButton, Stack, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material"
import { IoIosCloseCircleOutline } from "react-icons/io"
import { ScrollableDialogContent } from "../ScrollableDialogContent";

export const CustomDialog = ({
  open,
  onClose,
  maxWidth = 'xl',
  fullWidth = false,
  title,
  subtitle = undefined,
  allowEscape,
  actions,
  children,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Dialog
      open={open}
      onClose={allowEscape ? onClose : null} 
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      aria-label={`${title} popup`}
      fullScreen={fullScreen}
      sx={{
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        '& .MuiDialog-paper': {
          backgroundColor: theme.palette.dialog.backgroundColor,
          backgroundImage: 'none',
          border: fullScreen ? 'none' : theme.palette.dialog.border,
          borderRadius: fullScreen ? 0 : theme.palette.dialog.borderRadius,
        },
      }}
    >
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          {subtitle ? (
            <Stack direction="column" spacing={0}>
              <Typography fontWeight={700} fontSize={'24px'} lineHeight={1}>{title}</Typography>
              <Typography fontSize={'14px'} color={theme.palette.text.secondary}>{subtitle}</Typography>
            </Stack>
          ) : (
            <Typography fontWeight={700} fontSize={'24px'}>{title}</Typography>
          )}
          
          <Tooltip title={`Close ${title} popup`} enterDelay={1000} placement="top">
            <IconButton onClick={onClose} aria-label={`close ${title} popup`}>
              <IoIosCloseCircleOutline />
            </IconButton>
          </Tooltip>
        </Box>
      </DialogTitle>
      <ScrollableDialogContent modalIsOpen={open}>
        {children}
      </ScrollableDialogContent>
      {actions && (
        <DialogActions>
          {actions}
        </DialogActions>
      )}
    </Dialog>
  )

}