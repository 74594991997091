import React from 'react';
import { Card, Grid, Stack, Typography, useTheme } from '@mui/material';
import { FiInfo } from 'react-icons/fi';
import { IoWarning } from 'react-icons/io5';
import { MdError } from 'react-icons/md';

export const TokenCard = ({
  tokensUsed,
  tokensTotal,
  title,
  loading,
  error,
  errorMaxTokens,
}) => {
  const theme = useTheme();
  const percentUsed = tokensTotal > 0 ? (tokensUsed / tokensTotal) * 100 : 0;
  const tokensRemaining = tokensTotal > 0 ? tokensTotal - tokensUsed : 0;

  const getAlertColor = () => {
    if (tokensTotal === 0) {
      return theme.palette.error.main;
    } else if (percentUsed < 75) {
      return theme.palette.primary.main;
    } else if (percentUsed < 90) {
      return theme.palette.warning.main;
    } else {
      return theme.palette.error.main;
    }
  };

  const getAlertIcon = () => {
    if (tokensTotal === 0) {
      return <MdError size={24} color={theme.palette.error.main} />;
    } else if (percentUsed < 75) {
      return <FiInfo size={24} color={theme.palette.primary.main} />;
    } else if (percentUsed < 90) {
      return <IoWarning size={24} color={theme.palette.warning.main} />;
    } else {
      return <MdError size={24} color={theme.palette.error.main} />;
    }
  };

  if (loading) {
    return (
      <Card sx={{ border: `2px solid ${theme.palette.text.disabled}`, borderRadius: 2, padding: 2 }}>
        <Typography variant="body1" textAlign="center">
          Loading...
        </Typography>
      </Card>
    );
  }

  if (error || errorMaxTokens || tokensTotal === 0) {
    return (
      <Card sx={{ border: `2px solid ${theme.palette.error.main}`, borderRadius: 2, padding: 2 }}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <MdError size={24} color={theme.palette.error.main} />
          <Typography variant="body1" color={theme.palette.error.main}>
            {error?.message || errorMaxTokens?.message || 'No tokens available or an error occurred. Please try again.'}
          </Typography>
        </Stack>
      </Card>
    );
  }

  return (
    <Card sx={{ border: `2px solid ${getAlertColor()}`, borderRadius: 2 }}>
      <Grid container spacing={1} px={2} py={1}>
        <Grid item xs={1.5}>
          {getAlertIcon()}
        </Grid>
        <Grid item xs={8.5}>
          <Typography variant={'h6'} fontSize={16}>
            {title}
          </Typography>
          <Grid container columnSpacing={1}>
            <Grid item xs={6}>
              <Typography variant={'body1'} textAlign={'end'} fontSize={16}>
                {tokensUsed.toLocaleString()}
              </Typography>
            </Grid>
            <Grid item xs={6} alignContent={'center'}>
              <Typography variant={'body1'} fontSize={14} color={theme.palette.text.secondary}>
                used
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant={'body1'} textAlign={'end'} fontSize={14}>
                {tokensRemaining.toLocaleString()}
              </Typography>
            </Grid>
            <Grid item xs={6} alignContent={'center'}>
              <Typography variant={'body1'} fontSize={14} color={theme.palette.text.secondary}>
                remaining
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant={'body1'} textAlign={'end'} fontSize={14}>
                {tokensTotal.toLocaleString()}
              </Typography>
            </Grid>
            <Grid item xs={6} alignContent={'center'}>
              <Typography variant={'body1'} fontSize={14} color={theme.palette.text.secondary}>
                total
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={2} width={'100%'}>
          <Typography variant={'body1'} fontSize={20} textAlign={'end'} color={getAlertColor()}>
            {tokensTotal > 0 ? percentUsed.toFixed(0) : 'N/A'}%
          </Typography>
          <Typography variant={'body1'} textAlign={'end'}>
            {tokensTotal > 0 ? 'used' : 'No tokens available'}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
};
