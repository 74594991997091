import React, { useEffect, useRef, useState } from 'react';
import { isBotLoggedIn, getBotSettingItem } from '../../AuthService'
import * as default_data from '../../config.js'
import AudioRecorder from '../AudioRecorder';
import {
  Box,
  Button,
  Card,
  Chip,
  Input,
  Stack,
  useTheme,
  TextField,
  IconButton,
  Typography,
  useMediaQuery,
  Menu,
  MenuItem,
  Popover,
  Tooltip,
} from '@mui/material'
import { FiDatabase,FiFile,FiFolder, FiSettings,  } from "react-icons/fi";
import { FiFileText, FiPaperclip } from "react-icons/fi"
import { useDropzone } from 'react-dropzone';
import { validateFile } from '../../Utils/file_functions.js';
import { ChatInputButton } from './ChatInputButton';
import PromptList from '../../Prompts/PromptList'
import PluginList from '../../Plugins/PluginList'
import { IoChatbubblesSharp, IoClose, IoSend } from 'react-icons/io5';
import { ModelsDialog } from '../../Popups/ModelsDialog.js';
import { ChatInputDrawer } from './ChatInputDrawer';
import { models } from '../../Data/models.js';
import { IoMdPerson } from 'react-icons/io';
import { commands } from '../../Data/commands.js';
import { LiveSearchToggle } from '../Components/LiveSearchToggle.js';

export const ChatInputNew = ({
  getTokenizer,
  handleSubmit,
  prevCommand,
  setFile,
  setCurrentPersona,
  currentMode,
  current_dataset,
  datasets,
  handleDataset,
  showSimpleIngest,
  showCopyData,
  handleLive,
  live,
  paid,
  showPersonaList,
  current_persona,
  personas,
  showAddDataset,
  handlePersona,
  handleSelect,
  temperature,
  currentModel,
  handleModel,
  cur_user,
  handleFileChange,
  handleAudio,
  setChatLogAndSaveWithScroll,
  secrets,
  tokens_count,
  getTokenWindow,
  file,
  isCUICapable,
  chatInputPass,
  setChatInputPass,
  setChatInputMode,
  handlePluginClick,
  mode='chat',
  hideModelSelect = false,
  hidePromptSelect = false, 
  hidePluginSelect = false
}) => {
  const theme = useTheme();
  const [modelSelectOpen, setModelSelectOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [chatInput, setChatInput] = useState("");
  const pluginListRef = useRef(null);
  const [triggerPromptList, setTriggerPromptList] = useState(0);
  const [PromptListMode, setPromptListMode] = useState("normal");
  const [PromptListValue, setPromptListValue] = useState(null);
  const [triggerPluginList, setTriggerPluginList] = useState(0);
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('mobile'));
  const [datasetLabel, setDatasetLabel] = useState('None');
  const [slashMenu, setSlashMenu] = useState(false);
  const [filteredCommands, setFilteredCommands] = useState([]);
  
  const ref = useRef(null);

  useEffect(() => {
    // check the chat input to see if it starts with a slash
    if(chatInput === undefined || chatInput === null) {
      return;
    }
    if (chatInput.startsWith('/')) {
      setSlashMenu(true);
      setFilteredCommands(commands.filter((command) => command.command.startsWith(chatInput)));
    } else {
      setSlashMenu(false);
    }
  }, [chatInput]);



  useEffect(() => {
    if (current_dataset && current_dataset.length > 0) {
      const noneDataset = current_dataset.find((dataset) => dataset.value === 'none');
      const allDataset = current_dataset.find((dataset) => dataset.value === 'all');
      if (noneDataset) {
        setDatasetLabel('None');
      } else if (allDataset) {
        setDatasetLabel('All');
      } else {
        if (current_dataset.length === 1) {
          setDatasetLabel(`${current_dataset[0].label}`);
        } else {
          setDatasetLabel(`${current_dataset.length} datasets`);
        }
      }
    }
  }, [current_dataset]);



  useEffect(() => {
    const timeoutId = setTimeout(() => {
      getTokenizer(chatInput)
    }, 2000);
    return () => clearTimeout(timeoutId);
  }, [chatInput]);

  useEffect(() => {
    if (chatInputPass !== undefined) {
      setChatInput(chatInputPass)
      setChatInputPass(undefined)
    }
  }, [chatInputPass, setChatInputPass])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      if (!validateFile(acceptedFiles[0])) {
        alert('Invalid file type. Please upload a valid file type.');
        return;
      }
      setFile(acceptedFiles[0]);
    },
  });

  function handlePluginClickExec(plugin_name, plugin_values, prompt) {
    return handlePluginClick(plugin_name, plugin_values, prompt, setChatInput)
  }

  function handlePromptClick(prompt, persona=1) {
    setChatInput(prompt)
    setCurrentPersona(persona);
  }

  async function keyHandler(e) {
    if (e.which === 13 && e.ctrlKey) {
      handleSubmit(e, chatInput, setChatInput);
    } else if (e.which === 38 && e.ctrlKey) {
      const chatInputValue = chatInput?.trim() || undefined;
      const newPrevCommand = prevCommand.filter((item, index, self) => {  
        return index === self.indexOf(item);
      });
      if (chatInputValue === '' || chatInputValue === undefined || !newPrevCommand.includes(chatInput)) {
        setChatInput(newPrevCommand[newPrevCommand.length - 1]);
      } else {
        const index = newPrevCommand.indexOf(chatInput);
        if (index > 0) {
          setChatInput(newPrevCommand[index - 1]);
        }
      }
    } else if (e.which === 40 && e.ctrlKey) {
      const chatInputValue = chatInput?.trim() || undefined;
      const newPrevCommand = prevCommand.filter((item, index, self) => {  
        return index === self.indexOf(item);
      });
      if (chatInputValue === '' || chatInputValue === undefined || !newPrevCommand.includes(chatInput)) {
        setChatInput(newPrevCommand[0]);
      } else {
        const index = newPrevCommand.indexOf(chatInput);
        if (index < newPrevCommand.length - 1) {
          setChatInput(newPrevCommand[index + 1]);
        }
      }
    }
  }

  const file_style = {
    fileInput: {
      display: 'none', 
    },
    fileLabel: {
      background: 'url(file.png) no-repeat'
    }
  };

  const getPersonaActive = () => {
    const foundPersona = personas.find((persona) => persona.id == current_persona)?.name
    if (foundPersona) {
      return foundPersona
    } else {
      return 'Persona'
    }
  }


  function showPromptList(type='normal', field_name) {
    setTriggerPromptList(1);
    setPromptListMode(type);
    setPromptListValue(field_name);
  }
  
  // Handle paste event to attach file
  useEffect(() => {
    const handlePaste = (e) => {
      // If there is no file in the clipboard then the pasting of text content is handled by the browser
      if( !e.clipboardData || !e.clipboardData.items ) {
        return;
      }
      // Get the files and text data from the clipboard
      const items = e.clipboardData.items;
      const textData = e.clipboardData.getData('text/plain');
      for (let i = 0; i < items.length; i++) {
        // If the item is a file
        if (items[i].kind === 'file') {
          try {
            const file = items[i].getAsFile();
            // If the file is an image and there is text data then do not upload the image this means it was copied 
            // complex text like a csv or text from OneNote and the text should be posted not the image
            if (file.name === 'image.png' && textData && textData.length > 0) {
              return;
            }
            if (validateFile(file)) {
              setFile(file);
            } else {
              alert('Invalid file type. Please upload a valid file type.');
            }
            e.preventDefault();
          } catch (error) {
            console.error(error);
          }
        }
      }
    };
  
    document.addEventListener('paste', handlePaste);
    return () => {
      document.removeEventListener('paste', handlePaste);
    };
  }, []);

  return (
    <>
      <Box sx={{ position: 'relative', marginRight:1}}>
        {/* Buttons Container */}
        <Stack 
          direction="row" 
          spacing={1} 
          sx={{
            position: 'absolute', 
            top: isMobile ? -38 : -44, 
            left: 0, 
            right: 0,
            zIndex: 2, 
            marginX: 2,
            borderRadius: '0 0 8px 8px',
            justifyContent: isMobile ? 'space-between' : 'center',
            maxHeight: isMobile ? 32 : 52,
          }}
        >
          {isMobile && (
            <Stack direction="row" spacing={1} style={{ alignItems: 'center' }}>
              <Box 
                sx={{
                  backgroundColor: theme.palette.chatBackground,
                  borderRadius: 2,
                  border: '1px solid white',
                }}
              >
                {default_data.default_voicerecorder_enabled === true && paid == true && (
                  <AudioRecorder handleAudio={handleAudio} chatInput={chatInput} setChatInput={setChatInput} isMobile />
                )}
              </Box>
              <Box 
                sx={{
                  backgroundColor: theme.palette.chatBackground,
                  borderRadius: 2,
                  border: '1px solid white',
                }}
              >
                {(!isBotLoggedIn() || getBotSettingItem('hide_fileupload') === false) && (
                  <span className="file-paperclip">
                    <Input style={file_style.fileInput} id="file" name="file" type="file" onChange={handleFileChange} />
                    <label 
                      className="chat-log-file-btn" 
                      htmlFor="file" 
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <FiPaperclip alt="Attach File" color={theme.palette.text.primary} size={18} />
                    </label>
                  </span>
                )}
              </Box>
            </Stack>
          )}
          <Stack direction="row" spacing={1} justifyContent={isMobile ? 'end' : 'center'} width={'100%'}>
            {!hideModelSelect && <ChatInputButton
              onClick={() => setModelSelectOpen(true)}
              startIcon={<IoChatbubblesSharp size={18} />}
            >
              { mode === 'chat' ? 
              <Typography sx={{ overflow: 'hidden', maxWidth: '200px', textOverflow: 'none', whiteSpace: 'nowrap', fontSize: '14px'}}>
                {models.find((model) => model.key === currentModel)?.value}
              </Typography> : null }
            </ChatInputButton>}
            {currentMode === 'advanced' && <ChatInputButton 
              onClick={() => showPersonaList()}
              startIcon={<IoMdPerson size={18} />}
            >
              {mode === 'chat' ? 
              <Typography sx={{ overflow: 'hidden', maxWidth: '200px', textOverflow: 'none', whiteSpace: 'nowrap', fontSize: '14px'}}>
                {getPersonaActive()}
              </Typography>
              : null }
            </ChatInputButton>}
            {!hidePromptSelect && <ChatInputButton
              onClick={() => showPromptList('normal')}
              startIcon={<FiFileText size={18} />}
            >
              {mode === 'chat' ? 'Prompts' : null}
            </ChatInputButton>}
            {!hidePluginSelect && <ChatInputButton
              onClick={() => setTriggerPluginList(1)}
              startIcon={<FiDatabase size={18} />}
            >
              {mode === 'chat' ? 'Plugins' : null}
            </ChatInputButton>}
            <ChatInputButton
              onClick={() => {setDrawerOpen(true)}}
              startIcon={<FiSettings size={18} />}
            >
              {mode === 'chat' ? 'Data' : null}
            </ChatInputButton>
          </Stack>
        </Stack>
        <Box sx={{boxShadow: `0 -30px 10px ${theme.palette.chatBackground}`}}>
          <Card 
            variant='outlined'
            sx={{
              backgroundColor: theme.palette.chatInput.backgroundColor,
              marginX: 2,
              overflow: 'unset',
              paddingX: 1,
              paddingY: 0.5,
              backgroundImage: 'none',
              borderRadius: 3,
              border: '1px solid white',
            }}
          >
            <Stack direction="row" spacing={0} alignItems={'end'} {...getRootProps()} onClick={()=>{}}>
              {!isMobile && (
                <Stack direction="row" spacing={0} style={{ marginBottom: '4px' }}>
                  {default_data.default_voicerecorder_enabled === true && paid == true && (
                    <AudioRecorder handleAudio={handleAudio} chatInput={chatInput} setChatInput={setChatInput}  />
                  )}
                  {(!isBotLoggedIn() || getBotSettingItem('hide_fileupload') === false) && (
                    <Tooltip title="You can also drag and drop files" placement="top" enterDelay={1000}>
                      <span className="file-paperclip">
                        <Input style={file_style.fileInput} id="file" name="file" type="file" onChange={handleFileChange} />
                        <label 
                          className="chat-log-file-btn" 
                          htmlFor="file" 
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <FiPaperclip alt="Attach File" color={theme.palette.text.primary} size={20} />
                        </label>
                      </span>
                    </Tooltip>
                  )}
                  { default_data.default_block_live === false && (
                    <LiveSearchToggle liveSearch={live} setLiveSearch={handleLive} paid={paid} />
                  )}
                </Stack>
              )}
              <input {...getInputProps()} onClick={()=>{}} onChange={()=>{}} />
              <Box display={isDragActive ? 'block' : 'none'} border={'grey dashed 2px'} color={'grey'} padding={1} borderRadius={2}>
                  <div dangerouslySetInnerHTML={{ __html: default_data.default_file_allowed_txt}}></div>
              </Box>
              <Popover
                id="slash-menu"
                anchorEl={ref.current}
                open={slashMenu && filteredCommands.length > 0}
                size="small"
                onClose={()=>{
                  setSlashMenu(false);
                }}
                disableAutoFocus={true}
                disableEnforceFocus={true}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                sx={{
                  '& .MuiPopover-paper': {
                    // set the background color to the theme's chat background color with a opacity of 0.7
                    backgroundColor: theme.palette.chatBackground,
                    color: theme.palette.text.primary,
                    border: '1px solid white',
                    maxHeight: '200px',
                  },
                }}
              >
                {filteredCommands
                  .map((command, index) => {
                  return (
                    <MenuItem 
                      key={index} 
                      onClick={() => {
                        setChatInput(command.command);
                        setSlashMenu(false);
                      }}
                      sx={{
                        paddingX: 1,
                      }}
                    >
                      <Stack direction="column" spacing={0}>
                        <Typography variant="body2" sx={{ color: theme.palette.text.primary }}>
                          {command.command}
                        </Typography>
                        {command.description && (
                          <Typography variant="caption" sx={{ color: theme.palette.text.secondary }}>
                            {command.description}
                          </Typography>
                        )}
                      </Stack>
                    </MenuItem>
                  )
                })}
              </Popover>
              <TextField
                value={chatInput}
                fullWidth
                ref={ref}
                sx={{
                  display: isDragActive ? 'none' : 'block',
                  width: '100%',
                  backgroundColor: theme.palette.chatInput.backgroundColor,
                  resize: 'vertical',
                  color: theme.palette.text.primary,
                  '& .MuiInputBase-root': {
                    color: theme.palette.text.primary,
                    padding: isMobile ? 0.5 : 1,
                    border: 'none',
                    justifyContent: 'center',
                  },
                }}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                }}
                onKeyDown={(e) => keyHandler(e)}
                onChange={(e)=> setChatInput(e.target.value)}
                placeholder="Ask Sage Anything..."
                minRows={1}
                maxRows={8}
                multiline
              />
              <IconButton
                onClick={(e) => handleSubmit(e, chatInput, setChatInput)}
                size={isMobile ? 'small' : 'medium'}
                aria-label='send'
              >
                <IoSend />
              </IconButton>
            </Stack>
          </Card>
        </Box>
        <Stack direction="row" spacing={0} style={{ marginBottom: '4px', width: '100%' }} justifyContent={'space-between'} paddingX={2}>
          <Stack direction="row" spacing={0} alignItems={'center'} marginTop={'4px'}>
            <FiFile size={16} style={{marginRight: 8}}/>
            <span style={{ color: theme.palette.text.primary, fontSize: '0.8rem', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100%'}}>{file ? file.name : "No File Attached"}</span>
            {file && (
              <IconButton onClick={() => setFile(null)} size='small'>
                <IoClose />
              </IconButton>
            )}
            {datasetLabel && !isMobile && (
              <Chip 
                sx={{
                  marginLeft: 1,
                  overflow: 'hidden', 
                  maxWidth: '180px',
                  paddingLeft: '5px',
                  paddingRight: '5px', 
                  textOverflow: 'none',
                  whiteSpace: 'nowrap',
                }} 
                label={datasetLabel} 
                color="primary" 
                size="small" 
                icon={<FiFolder/>} 
                onClick={()=>setDrawerOpen(true)}
              />
            )}
          </Stack>
          <Stack direction="row" spacing={0} alignItems={'center'}>
            <Box className="file-info" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              {live !== 0 && !isMobile && (
                <Chip sx={{marginLeft: 1}} label={live === 1 ? 'Live' : 'Live+'} color="success" size="small" onClick={()=>setDrawerOpen(true)} />
              )}
              {!default_data.default_hide_cac && (
                isCUICapable() ?
                  <Chip label={isMobile ? 'CUI' : 'CUI compliant'} color="success" size="small" />
                :
                  <Chip label={isMobile ? 'Not CUI' : 'Not CUI compliant'} color="error" size="small" />
              )}
              <Typography variant="body2" sx={{ color: tokens_count > getTokenWindow() ? 'error.main' : 'inherit' }}>
                {tokens_count.toLocaleString()}{!isMobile && ` / ${getTokenWindow().toLocaleString()}`} tokens
              </Typography>
            </Box>
          </Stack>
        </Stack>
      </Box>
      {triggerPluginList === 1 && <PluginList showAddDataset={showAddDataset} ref={pluginListRef} showPromptList={showPromptList} handleBotMessage={setChatLogAndSaveWithScroll} secrets={secrets} datasets={datasets} setTriggerPluginList={setTriggerPluginList} triggerPluginList={triggerPluginList} handlePluginClick={handlePluginClickExec} />}
      {triggerPromptList === 1 && <PromptList setTriggerPromptList={setTriggerPromptList} triggerPromptList={triggerPromptList} personas={personas} handlePromptClick={handlePromptClick} />}
      {modelSelectOpen && (
        <ModelsDialog
          open={modelSelectOpen}
          onClose={() => setModelSelectOpen(false)}
          handleModel={handleModel}
          paid={paid}
          currentUser={cur_user}
        />
      )}
      <ChatInputDrawer
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        selectedDatasets={current_dataset}
        datasets={datasets}
        onChange={handleDataset}
        temperature={temperature}
        handleSeletTemperature={handleSelect}
        live={live}
        handleLive={handleLive}
        paid={paid}
        showSimpleIngest={showSimpleIngest}
        showCopyData={showCopyData}
        currentMode={currentMode}
      />
    </>
  )  
}