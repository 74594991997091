import React, { useEffect, useRef, useState } from 'react';
import { Tooltip, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Stack, useTheme } from '@mui/material';
import { IoChatbubbles, IoChatbubblesOutline, IoPerson, IoSettingsSharp } from 'react-icons/io5';
import { FaCode, FaPencilAlt } from 'react-icons/fa';
import { FiBookOpen, FiLogOut, FiUser, FiMessageSquare, FiCodesandbox, FiTag } from 'react-icons/fi';
import { MdCompare, MdHelp } from 'react-icons/md';
import * as default_data from '../config.js'
import { getBotSettingItem, getUser, getWithExpiry, isBotLoggedIn, logout, setWithExpiry } from '../AuthService';
import { WhatsNewDialog } from '../Popups/WhatsNew/WhatsNewDialog.js';
import { SettingsDrawer } from './SettingsDrawer/SettingsDrawer.js';
import APIKeysList from '../APIKeys/APIKeysList.js';

export const Navbar = () => {
  const [paid, setPaid] = useState(false);
  const [cur_user, setUser] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [settingDrawerOpen, setSettingDrawerOpen] = useState(false);
  const [showAPIKeysList, setShowAPIKeysList] = useState(0);
  const [settingsTabValue, setSettingsTabValue] = useState("customization");

  const theme = useTheme();

  const iconSize = '22px';
  const menuIconSize = '14px';
  const windowLocation = window.location.pathname;
  const isChatPageActive = windowLocation === '/dashboard' || windowLocation === '/' ? true : false;
  const isWorkbookPageActive = windowLocation === '/workbook' ? true : false;
  const isLeaderboardPageActive = windowLocation === '/leaderboard' ? true : false;
  const isModelComparisonPageActive = windowLocation === '/model-comparison' ? true : false;
  const isCodeCanvasActive = windowLocation === '/codecanvas' ? true : false;
  const isProseCanvasActive = windowLocation === '/prosecanvas' ? true : false;

  const listItemIconStyle = { minWidth: '30px !important' }
  const listItemStyle = { '&:hover': { color:theme.palette.text.primary, backgroundImage: 'rgba(0,0,0,0.3)'} }
  const activeListItemStyle = { backgroundColor: `${theme.palette.primary.main} !important`, padding: '4px'}

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const buttonRef = useRef(null);

  useEffect(() => {
    var user = getUser()
    setUser(user)
    setPaid(user.paid)

    if(getWithExpiry('helpMenuDismissed') === null) {
      setIsOpen(true)
    }
    if(isBotLoggedIn()) {
      if(getBotSettingItem('disable_whatsnew') === true) {         
        setIsOpen(false)
      }
    }

  }, []);

  function closeModal() {
    // stops overwriting the local storage if the user manually opens the help menu
    if(getWithExpiry('helpMenuDismissed') === null) {
      const tenDays = 10 * 24 * 60 * 60 * 1000;
      setWithExpiry('helpMenuDismissed', true, tenDays);
    }
    setIsOpen(false);
  }

  return (
    <Stack
      direction="column"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        color: 'white',
        height: '100%',
        width: '48px',
        paddingY: '16px',
        paddingX: '8px',
        borderRight: '1px solid #505050',
      }}
    >
      <Stack direction="column" spacing={2} alignItems="center">
        <img src="/logo.png" alt="Ask Sage" className="logo" width={'40px'} />
        <Tooltip title="Chat" placement='right'>
          <IconButton 
            component={'a'} 
            href={'/'} 
            disabled={isChatPageActive} 
            sx={isChatPageActive ? activeListItemStyle : {}}
          >
            {isChatPageActive ? <IoChatbubblesOutline size={iconSize} color={'black'} /> : <IoChatbubblesOutline size={iconSize}/>}
          </IconButton>
        </Tooltip>
        { paid &&
        <Tooltip title="Workbook" placement='right'>
          <IconButton 
            component={'a'} 
            href={'/workbook'} 
            disabled={isWorkbookPageActive} 
            sx={isWorkbookPageActive ? activeListItemStyle : {}}
          >
            {isWorkbookPageActive ? <FiBookOpen size={iconSize} color={'black'} /> : <FiBookOpen size={iconSize}/>}
          </IconButton>
        </Tooltip>
        }
        {/* <IconButton component={'a'} href={'/leaderboard'} disable={isLeaderboardPageActive} >
          {isLeaderboardPageActive ? <MdLeaderboard size={iconSize} fill='white' /> : <MdOutlineLeaderboard size={iconSize}/>}
        </IconButton> */}
        { paid &&
        <Tooltip title="Code Canvas" placement='right'>
          <IconButton component={'a'} href={'/codecanvas'} disabled={isCodeCanvasActive}
            sx={isCodeCanvasActive ? activeListItemStyle : {}}
            >
            {isCodeCanvasActive ? <FaCode size={iconSize} fill={theme.palette.text.primary}/> : <FaCode size={iconSize}/>}
          </IconButton>
        </Tooltip>
        }
        { paid &&
        <Tooltip title="Prose Canvas" placement='right'>
          <IconButton component={'a'} href={'/prosecanvas'} disabled={isProseCanvasActive}
            sx={isProseCanvasActive ? activeListItemStyle : {}}
            >
            {isProseCanvasActive ? <FaPencilAlt size={iconSize} fill={theme.palette.text.primary}/> : <FaPencilAlt size={iconSize}/>}
          </IconButton>
        </Tooltip>
        }        
        <Tooltip title="Model Compare" placement='right'>
          <IconButton 
            component={'a'} 
            href={'/model-comparison'} 
            disabled={isModelComparisonPageActive} 
            sx={isModelComparisonPageActive ? activeListItemStyle : {}}
          >
            {isModelComparisonPageActive ? <MdCompare size={iconSize} fill={'black'}/> : <MdCompare size={iconSize}/>}
          </IconButton>
        </Tooltip>
        { paid &&
        <Tooltip title="In a Box" placement='right'>
          <IconButton 
            component={'a'}
            href={`https://${default_data.default_box_prefix_url}.${default_data.default_tenantDomain}`}
            target="_blank"
          >
            <FiCodesandbox size={iconSize}/>
          </IconButton>
        </Tooltip>}
      </Stack>
      <Stack direction="column" spacing={1} alignItems="center">
      <Tooltip title="Help">
        <IconButton onClick={()=>setIsOpen(true)} >
          <MdHelp size={iconSize} />
        </IconButton>
      </Tooltip>

      <Tooltip title="Account" placement='right'>
        <IconButton
          ref={buttonRef}
          onClick={handleClick}
        >
          <IoPerson size={iconSize} />
        </IconButton>
      </Tooltip>
        <Tooltip title="Settings" placement='right'>
          <IconButton
            onClick={()=>{
              setSettingDrawerOpen(true)
              setSettingsTabValue('customization')
            }} 
          >
            <IoSettingsSharp size={iconSize} />
          </IconButton>
        </Tooltip>
        {open && (
          <Menu
            id={'account-menu'}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
          >
            <MenuList dense>
              <MenuItem
                onClick={()=>{
                  setAnchorEl(null)
                  setSettingDrawerOpen(true)
                  setSettingsTabValue('account')
                }} 
                sx={listItemStyle}
              >
                <ListItemIcon sx={listItemIconStyle}>
                  <FiUser size={menuIconSize}/>
                </ListItemIcon>
                <ListItemText primary="Account" />
              </MenuItem>  
                <MenuItem
                  onClick={()=>{
                    setAnchorEl(null)
                    setSettingDrawerOpen(true)
                    setSettingsTabValue('tokens')
                  }} 
                  sx={listItemStyle}
                >
                  <ListItemIcon sx={listItemIconStyle}>
                    <FiTag size={menuIconSize}/>
                  </ListItemIcon>
                  <ListItemText primary="Subscription" />
                </MenuItem>
              { default_data.default_block_discord === true ? '' : 
                <MenuItem 
                  component={'a'}
                  href="https://discord.gg/upxuAdNvjY"
                  target="_blank"
                  rel="noopener"
                  sx={listItemStyle}
                >
                  <ListItemIcon sx={listItemIconStyle}>
                    <FiMessageSquare size={menuIconSize}/>
                  </ListItemIcon>
                  <ListItemText primary="Discord" />
                </MenuItem>
              }
              <MenuItem onClick={logout} sx={listItemStyle}>
                <ListItemIcon sx={listItemIconStyle}>
                  <FiLogOut size={menuIconSize}/>
                </ListItemIcon>
                <ListItemText primary="Logout" />
              </MenuItem>
              {/* <button variant="light" onClick={call_logout}>Logout <FiLogOut /></button> */}
            </MenuList>
          </Menu>
        )}
      </Stack>
      {/* Dialogs and Drawers */}
      { modalIsOpen && (
        <WhatsNewDialog cur_user={cur_user} modalIsOpen={modalIsOpen} closeModal={closeModal} paid={paid}/>
      )}
      {settingDrawerOpen && (
        <SettingsDrawer
          settingsTabValue={settingsTabValue}
          onClose={() => {
            setSettingDrawerOpen(false)
          }}
          open={settingDrawerOpen}
          setShowAPIKeysList={setShowAPIKeysList}
        />
      )}
      {showAPIKeysList === 1 && (
        <APIKeysList setTriggerAPIKeysList={setShowAPIKeysList} triggerAPIKeysList={showAPIKeysList} />
      )}
    </Stack>
  );
};
