import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  Switch,
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ThemeProvider,
  Paper,
  CircularProgress,
  Chip,
  Divider,
  Link,
  Backdrop
} from '@mui/material';
import * as default_data from '../config.js';
import { getUser, getToken, setWithExpiry } from '../AuthService.js';
import HeaderLogged from '../Chat/HeaderLogged';
import { lightTheme } from '../themes';

const PaymentForm = () => {
  const [plan_type, setPlanType] = useState(null);
  const [isYearly, setIsYearly] = useState(false);
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [max_tokens, setMaxTokens] = useState('UNKNOWN');
  const [max_train_tokens, setMaxTrainTokens] = useState('UNKNOWN');
  const [current_monthly_tokens, setMonthlyTokens] = useState('UNKNOWN');
  const [current_train_monthly_tokens, setTrainMonthlyTokens] = useState('UNKNOWN');
  const [isLoading, setIsLoading] = useState(false);

  const plans = [
    { plan_name: "200", tokens: 200000, monthlyPrice: 15, yearlyPrice: 15 * 12 },
    { plan_name: "500", tokens: 500000, monthlyPrice: 30, yearlyPrice: 30 * 11 },
    { plan_name: "1000", tokens: 1000000, monthlyPrice: 50, yearlyPrice: 50 * 11 },
    { plan_name: "2000", tokens: 2000000, monthlyPrice: 90, yearlyPrice: 90 * 11, best: true },
  ];

  const handleToggle = () => {
    setIsYearly(!isYearly);
  };

  const handlePlanSelect = (plan) => {
    var planType = isYearly ? 'yearly' : 'monthly';
    planType = (plan.tokens / 1000) + '_' + planType;

    const token = getToken();
    if (!user) {
      window.location = '/login';
      return;
    }

    setIsLoading(true);
    const domain = window.location.hostname;

    fetch(default_data.default_user_service_url_no_cac + '/checkout', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-tokens': `${token}`
      },
      body: JSON.stringify({
        domain: domain,
        planType: planType,
      })
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('An error occurred while sending chats.');
    })
    .then(data => {
      setIsLoading(false);  
      if(data.status != 200) {
        console.log(data);
      }
      else {          
        if(data.url != null && data.url != '') {
          window.location = data.url;
          return;
        }            
        else {
          window.location = '/update?action=success_update_checkout';
          return;
        }
      }
    })
    .catch(error => {
      setIsLoading(false);  
    });    
  };

  useEffect(() => {
    updateInfo();
    var token = getToken();

    fetch(default_data.default_chat_service_url + '/count-monthly-tokens', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-tokens': `${token}`
      },        
      body: JSON.stringify({})
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('An error occurred while sending chats.');
    })
    .then(data => {
      if(data.status != 200) {
        console.log(data.response);
      }
      else {          
        if(data.response == null || data.response == '') {
          setMonthlyTokens(0);
        }
        else {
          setMonthlyTokens(data.response);
        }
      }
    })
    .catch(error => {
      console.error(error);
    });    

    fetch(default_data.default_chat_service_url + '/count-monthly-teach-tokens', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-tokens': `${token}`
      },        
      body: JSON.stringify({})
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('An error occurred while sending chats.');
    })
    .then(data => {
      if(data.status != 200) {
        console.log(data.response);
      }
      else {          
        if(data.response == null || data.response == '') {
          setTrainMonthlyTokens(0);
        }
        else {
          setTrainMonthlyTokens(data.response);
        }
      }
    })
    .catch(error => {
      console.error(error);
    });      
  }, []);

  const handleEnterpriseClick = () => {
    window.location.href = 'mailto:sales@asksage.ai?subject=Ask Sage Enterprise purchase';
  };

  const getCurrentPlan = () => {
    if (!plan_type) return null;
    if (!plan_type.includes('_')) return null;

    const [tokens, period] = plan_type.split('_');
    return { plan_name: tokens, tokens: parseInt(tokens) * 1000, period };
  };

  const currentPlan = getCurrentPlan();

  const handleCancelClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function updateInfo() {
    var token = getToken();

    fetch(default_data.default_user_service_url_no_cac + '/validate_token_with_full_user', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-tokens': `${token}`
      },        
      body: JSON.stringify({})
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('An error occurred while sending chats.');
    })
    .then(data => {
      if(data.status != 200) {
        console.log(data.response);
      }
      else {    
        setMaxTokens(data.response.max_tokens);
        setMaxTrainTokens(data.response.max_train_tokens);

        var user = data.response;
        setWithExpiry('user', JSON.stringify(user));
        setUser(user);
        
        if (user.plan_type !== 'free' && user.plan_type !== 'trial') {
          setPlanType(user.plan_type);
          setIsYearly(user.plan_type.includes('yearly'));
        }              
      }
    })
    .catch(error => {
      console.error(error);
    });  
  }

  return (
    <ThemeProvider theme={lightTheme}>
      <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      { 
        <div className='header-message'>
          { default_data.default_banner_override != null && default_data.default_banner_override != '' ?
              default_data.default_banner_override : 'UNCLASSIFIED' 
          }
        </div>            
      }

        <HeaderLogged />
        
        <Box className="reg-page-mode" sx={{ flexGrow: 1, py: 4 }}>
          <Container maxWidth="lg">
            <Paper 
              elevation={3} 
              sx={{ 
                borderRadius: 2, 
                overflow: 'hidden',
                mb: 4
              }}
            >
              <Box 
                sx={{ 
                  bgcolor: 'primary.main', 
                  color: 'primary.contrastText', 
                  p: 2, 
                  textAlign: 'center' 
                }}
              >
                <Typography variant="h4" component="h1" sx={{ fontSize: '2rem', fontWeight: 'bold' }}>
                  Subscription
                </Typography>
              </Box>
              
              <Box sx={{ p: 3, bgcolor: 'background.paper' }}>
                <Paper elevation={1} sx={{ p: 3, mb: 4 }}>
                  <Typography variant="h5" gutterBottom align="center" sx={{ mb: 3, fontSize: '1.5rem', fontWeight: 'bold' }}>
                    Tokens Information
                  </Typography>
                  
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <Box sx={{ mb: 2 }}>
                        <Typography variant="body1" sx={{ mb: 1, fontSize: '1.1rem', fontWeight: 'medium' }}>
                          Inference Tokens:
                        </Typography>
                        <Chip 
                          label={`${max_tokens.toLocaleString()} available / ${current_monthly_tokens.toLocaleString()} used`} 
                          color="primary" 
                          variant="outlined"
                          sx={{ fontSize: '1rem', height: 'auto', py: 1 }}
                        />
                      </Box>
                    </Grid>
                    
                    <Grid item xs={12} md={6}>
                      <Box sx={{ mb: 2 }}>
                        <Typography variant="body1" sx={{ mb: 1, fontSize: '1.1rem', fontWeight: 'medium' }}>
                          Training Tokens:
                        </Typography>
                        <Chip 
                          label={`${max_train_tokens.toLocaleString()} available / ${current_train_monthly_tokens.toLocaleString()} used`} 
                          color="secondary" 
                          variant="outlined"
                          sx={{ fontSize: '1rem', height: 'auto', py: 1 }}
                        />
                      </Box>
                    </Grid>
                    
                    {user && user.plan_type !== 'unknown' && (
                      <Grid item xs={12}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Typography variant="body1" sx={{ mr: 1, fontSize: '1.1rem', fontWeight: 'medium' }}>
                            Current Subscription:
                          </Typography>
                          <Chip 
                            label={currentPlan ? currentPlan.plan_name: user.plan_type} 
                            color="success" 
                            variant="outlined"
                            sx={{ fontSize: '1rem', height: 'auto', py: 1 }}
                          />
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                </Paper>

                {user !== null && user.plan_type === 'enterprise' ? (
                  <Paper elevation={1} sx={{ p: 3, mb: 3 }}>
                    <Typography variant="body1" sx={{ fontWeight: 'medium', fontSize: '1.1rem' }}>
                      You are on an enterprise plan. If you need more tokens, please reach out to your administrator. 
                      If you have any other questions, please reach out to us at <Link href="mailto:sales@asksage.ai">sales@asksage.ai</Link>
                    </Typography>
                  </Paper>
                ) : default_data.default_customer_tenant === true || user === null || user.plan_type === 'unknown' ? (
                  <Paper elevation={1} sx={{ p: 3, mb: 3 }}>
                    <Typography variant="body1" sx={{ fontWeight: 'medium', fontSize: '1.1rem' }}>
                      Need more tokens? We have $15 (200K tokens), $30 (500K tokens), $50 (1M tokens) and $90 (2M tokens) monthly plans.
                      Reach out to us at <Link href="mailto:sales@asksage.ai">sales@asksage.ai</Link>
                    </Typography>
                  </Paper>
                ) : (
                  <Box sx={{ mt: 4 }}>
                    <Typography variant="h5" align="center" gutterBottom sx={{ mb: 3, fontSize: '1.5rem', fontWeight: 'bold' }}>
                      Subscription Plans
                    </Typography>
                    
                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
                      <FormControlLabel
                        control={<Switch checked={isYearly} onChange={handleToggle} color="primary" />}
                        label={
                          <Typography variant="body1" sx={{ fontSize: '1.1rem' }}>
                            {isYearly ? 'Yearly Billing (Save up to 15%)' : 'Monthly Billing'}
                          </Typography>
                        }
                      />
                    </Box>
                    
                    <Grid container spacing={4}>
                      <Grid item xs={12} md={8}>
                        <Paper elevation={3} sx={{ overflow: 'hidden' }}>
                          <Box sx={{ bgcolor: 'primary.main', color: 'white', p: 2 }}>
                            <Typography variant="h5" align="center" sx={{ fontSize: '1.4rem', fontWeight: 'bold' }}>
                              Single Users
                            </Typography>
                          </Box>
                          
                          <Box sx={{ p: 3 }}>
                            <Typography variant="body1" gutterBottom sx={{ fontSize: '1.1rem' }}>
                              Exclusive access to Ask Sage's latest features, including:
                            </Typography>
                            <Box component="ul" sx={{ pl: 4, mb: 3 }}>
                              <Typography component="li" sx={{ fontSize: '1.05rem', mb: 0.5 }}>Multi modal (text, audio, image)</Typography>
                              <Typography component="li" sx={{ fontSize: '1.05rem', mb: 0.5 }}>Access to 20+ LLMs, incl. GPT 4o, o1, Claude 3.7, LLAMA3.3</Typography>
                              <Typography component="li" sx={{ fontSize: '1.05rem', mb: 0.5 }}>Dozens of file ingestors and connectors</Typography>
                              <Typography component="li" sx={{ fontSize: '1.05rem', mb: 0.5 }}>Access to plugins/agents, custom personas and prompt template library</Typography>
                              <Typography component="li" sx={{ fontSize: '1.05rem', mb: 0.5 }}>Live+ feature, and so much more!</Typography>
                            </Box>
                            
                            <Grid container spacing={3}>
                              {plans.map((plan, index) => {
                                const isCurrentPlan = currentPlan && 
                                  currentPlan.plan_name === plan.plan_name && 
                                  currentPlan.period === (isYearly ? 'yearly' : 'monthly');
                                
                                return (
                                  <Grid item key={index} xs={12} sm={6} md={3}>
                                    <Card 
                                      elevation={plan.best ? 3 : 1} 
                                      sx={{ 
                                        height: '100%',
                                        position: 'relative',
                                        borderRadius: 2,
                                        border: plan.best ? '2px solid gold' : 'none',
                                        bgcolor: isCurrentPlan ? 'info.lighter' : 'background.paper',
                                        transition: 'transform 0.2s',
                                        '&:hover': {
                                          transform: 'translateY(-5px)',
                                          boxShadow: 6
                                        },
                                        pt: 3 // Add padding at the top for the badge
                                      }}
                                    >
                                      {plan.best && (
                                        <Box 
                                          sx={{ 
                                            position: 'absolute', 
                                            top: 10, 
                                            right: 'calc(50% - 50px)',
                                            zIndex: 1
                                          }}
                                        >
                                          <Chip 
                                            label="Best Value" 
                                            color="warning" 
                                            size="medium"
                                            sx={{ 
                                              fontSize: '0.9rem', 
                                              fontWeight: 'bold',
                                              px: 1
                                            }}
                                          />
                                        </Box>
                                      )}
                                      
                                      {isCurrentPlan && (
                                        <Box 
                                          sx={{ 
                                            position: 'absolute', 
                                            top: 10, 
                                            left: 10,
                                            zIndex: 1
                                          }}
                                        >
                                          <Chip 
                                            label="Current Plan" 
                                            color="primary" 
                                            size="medium"
                                            sx={{ fontSize: '0.9rem' }}
                                          />
                                        </Box>
                                      )}
                                      
                                      <CardContent sx={{ p: 3, height: '100%', display: 'flex', flexDirection: 'column' }}>
                                        <Typography variant="h5" component="div" gutterBottom align="center" sx={{ fontSize: '1.3rem', fontWeight: 'bold' }}>
                                          {plan.tokens.toLocaleString()} Tokens
                                        </Typography>
                                        
                                        <Typography variant="h4" color="primary" align="center" sx={{ my: 2, fontSize: '1.8rem', fontWeight: 'bold' }}>
                                          ${isYearly ? plan.yearlyPrice : plan.monthlyPrice}
                                          <Typography variant="body2" component="span" color="text.secondary" sx={{ fontSize: '1rem', ml: 0.5 }}>
                                            /{isYearly ? 'year' : 'month'}
                                          </Typography>
                                        </Typography>
                                        
                                        {isYearly && (
                                          <Typography variant="body2" color="success.main" align="center" sx={{ mb: 2, fontSize: '1rem' }}>
                                            Save ${((plan.monthlyPrice * 12) - plan.yearlyPrice)} per year
                                          </Typography>
                                        )}
                                        
                                        <Box sx={{ flexGrow: 1 }} />
                                        
                                        {!isCurrentPlan && (
                                          <Button
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={() => handlePlanSelect(plan)}
                                            sx={{ mt: 2, fontSize: '1rem', py: 1 }}
                                          >
                                            Buy now
                                          </Button>
                                        )}
                                      </CardContent>
                                    </Card>
                                  </Grid>
                                );
                              })}
                            </Grid>
                          </Box>
                        </Paper>
                      </Grid>
                      
                      <Grid item xs={12} md={4}>
                        <Paper 
                          elevation={3} 
                          sx={{ 
                            height: '100%',
                            overflow: 'hidden',
                            borderRadius: 2
                          }}
                        >
                          <Box sx={{ bgcolor: 'error.main', color: 'white', p: 2 }}>
                            <Typography variant="h5" align="center" sx={{ fontSize: '1.4rem', fontWeight: 'bold' }}>
                              Enterprise
                            </Typography>
                          </Box>
                          
                          <Box sx={{ p: 3 }}>
                            <Typography variant="h6" component="div" gutterBottom sx={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
                              2,000,000 Ask Sage Tokens Bundles
                            </Typography>
                            
                            <Box component="ul" sx={{ pl: 4, mb: 3 }}>
                              <Typography component="li" sx={{ fontSize: '1.05rem', mb: 0.5 }}>
                                Bundles of 2,000,000 Ask Sage tokens for $90 per month to spread across users (minimum of 200,000 tokens per user)
                              </Typography>
                              <Typography component="li" sx={{ fontSize: '1.05rem', mb: 0.5 }}>
                                Enterprise Dashboard with user & tokens management, tracking
                              </Typography>
                            </Box>
                            
                            <Divider sx={{ my: 3 }} />
                            
                            <Typography variant="body2" align="center" sx={{ mb: 3, fontSize: '1.05rem' }}>
                              <strong>OPTIONAL:</strong> Dedicated on-premise deployment (dedicated Ask Sage tenant): $120,000/year, can deployed anywhere, including on-premise, Clouds, air-gapped, high side, etc.
                            </Typography>
                            
                            <Button
                              variant="contained"
                              color="error"
                              fullWidth
                              onClick={handleEnterpriseClick}
                              sx={{ mt: 2, fontSize: '1rem', py: 1 }}
                            >
                              Contact Sales
                            </Button>
                          </Box>
                        </Paper>
                      </Grid>
                    </Grid>
                    
                    {plan_type && plan_type !== 'free' && plan_type !== 'trial' && (
                      <Box sx={{ mt: 4, textAlign: 'center' }}>
                        <Divider sx={{ mb: 3 }} />
                        <Typography variant="h6" gutterBottom sx={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
                          Manage Your Subscription
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, flexWrap: 'wrap' }}>
                          <Button 
                            variant="outlined"
                            onClick={() => window.open('https://billing.stripe.com/p/login/14k00685C9vMayI5kk', '_blank')}
                            sx={{ fontSize: '1rem', py: 1 }}
                          >
                            Manage Invoices & Payment Methods
                          </Button>
                          <Button 
                            variant="outlined" 
                            color="error"
                            onClick={handleCancelClick}
                            sx={{ fontSize: '1rem', py: 1 }}
                          >
                            Cancel Subscription
                          </Button>
                        </Box>
                      </Box>
                    )}
                    
                    <Dialog 
                      open={open} 
                      onClose={handleClose}
                      maxWidth="sm"
                      fullWidth
                    >
                      <DialogTitle sx={{ fontSize: '1.3rem', fontWeight: 'bold' }}>
                        Are you sure you want to cancel? Don't give up now!
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText paragraph sx={{ fontSize: '1.1rem' }}>
                          <strong>Option 1:</strong> Reach out to our team for help to see how we can help achieve your outcomes.
                        </DialogContentText>
                        <DialogContentText sx={{ fontSize: '1.1rem' }}>
                          <strong>Option 2:</strong> Yes, I'm sure, cancel.
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions sx={{ p: 3 }}>
                        <Button 
                          variant="contained"
                          onClick={() => window.location.href = 'mailto:' + default_data.default_support_email + '?subject=Ask Sage Help Required'} 
                          color="primary"
                          sx={{ fontSize: '1rem', py: 1 }}
                        >
                          I need help!
                        </Button>
                        <Button 
                          variant="outlined"
                          onClick={() => window.open('https://billing.stripe.com/p/login/14k00685C9vMayI5kk', '_blank')} 
                          color="error"
                          sx={{ fontSize: '1rem', py: 1 }}
                        >
                          Yes, I'm sure, cancel
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </Box>
                )}
              </Box>
            </Paper>
            
            <Box 
              component="footer" 
              sx={{ 
                textAlign: 'center', 
                py: 2,
                color: 'text.secondary',
                fontSize: '1rem'
              }}
            >
              Copyright &copy; {default_data.default_copywrite_year} Ask Sage, Inc.
            </Box>
          </Container>
        </Box>
      </Box>
      
      <Backdrop
        sx={{ 
          color: '#fff', 
          zIndex: (theme) => theme.zIndex.drawer + 1,
          flexDirection: 'column',
          gap: 2
        }}
        open={isLoading}
      >
        <CircularProgress color="inherit" size={60} />
        <Typography variant="h6" sx={{ fontSize: '1.3rem' }}>
          Connecting, please wait...
        </Typography>
      </Backdrop>
    </ThemeProvider>
  );
};

export default PaymentForm;