import React, { createContext, useContext, useReducer, useEffect } from 'react';

// Define the initial state
const initialState = {
  theme: 'dark',
  useModernChat: true,
  useAdvanceFields: true,
  showAllRecommendations: true,
  defaultDatasets: [{"value":"none","label":"None"}],
  defaultModel: 'auto',
  defaultPersonality: 1,
  autoNameChats: true
};

// Create a context
const UserSettingsContext = createContext();

// Define action types
const actionTypes = {
  SET_THEME: 'SET_THEME',
  SET_USE_MODERN_CHAT: 'SET_USE_MODERN_CHAT',
  SET_USE_ADVANCE_FIELDS: 'SET_USE_ADVANCE_FIELDS',
  SET_SHOW_ALL_RECOMMENDATIONS: 'SET_SHOW_ALL_RECOMMENDATIONS',
  SET_DEFAULT_DATASETS: 'SET_DEFAULT_DATASETS',
  SET_DEFAULT_MODEL: 'SET_DEFAULT_MODEL',
  SET_DEFAULT_PERSONALITY: 'SET_DEFAULT_PERSONALITY',
  SET_AUTO_NAME_CHATS: 'SET_AUTO_NAME_CHATS'
};

// Create a reducer function
const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.SET_THEME:
      return { ...state, theme: action.payload };
    case actionTypes.SET_USE_MODERN_CHAT:
      return { ...state, useModernChat: action.payload };
    case actionTypes.SET_USE_ADVANCE_FIELDS:
      return { ...state, useAdvanceFields: action.payload };
    case actionTypes.SET_SHOW_ALL_RECOMMENDATIONS:
      return { ...state, showAllRecommendations: action.payload };
    case actionTypes.SET_DEFAULT_DATASETS:
      return { ...state, defaultDatasets: action.payload };
    case actionTypes.SET_DEFAULT_MODEL:
      return { ...state, defaultModel: action.payload };
    case actionTypes.SET_DEFAULT_PERSONALITY:
      return { ...state, defaultPersonality: action.payload };
    case actionTypes.SET_AUTO_NAME_CHATS:
      return { ...state, autoNameChats: action.payload}
    default:
      return state;
  }
};

// Create a provider component
export const UserSettingsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState, (initial) => {
    const oldSettings = localStorage.getItem('userSettings');
    //Update the setting name
    if (oldSettings) {
      const updatedSettings = JSON.parse(oldSettings);
      localStorage.setItem('userPreferences', JSON.stringify({...updatedSettings, useModernChat: true, useAdvanceFields: true}));
      localStorage.removeItem('userSettings');
    }

    const savedSettings = localStorage.getItem('userPreferences');

    // Check for old settings
    const existingTheme = localStorage.getItem('currentColor');
    const currentDatasetMode = localStorage.getItem('currentDatasetMode');
    const currentMode = localStorage.getItem('currentMode');
    const chatInput = localStorage.getItem('chatInput');
    // Migrate old settings
    if (existingTheme) {
      const parsedTheme = JSON.parse(existingTheme);
      initial.theme = parsedTheme.value === 'black' ? 'dark' : 'light';
      localStorage.removeItem('currentColor');
    }
    if (currentDatasetMode) {
      const parsedDatasetMode = JSON.parse(currentDatasetMode);
      initial.defaultDatasets = parsedDatasetMode.value === 'all' ? [{"value":"all","label":"All"}] : [{"value":"none","label":"None"}];
      localStorage.removeItem('currentDatasetMode');
    }
    if (currentMode) {
      const parsedMode = JSON.parse(currentMode);
      initial.useAdvanceFields = parsedMode.value === 'advanced' ? true : false;
      localStorage.removeItem('currentMode');
    }
    if (chatInput) {
      initial.useModernChat = chatInput === 'simple' ? true : false;
      localStorage.removeItem('chatInput');
    }

    if (savedSettings === undefined || savedSettings === null) {
      return initial;
    } 

    try {
      let parsedSettings = JSON.parse(savedSettings);
      if (parsedSettings.autoNameChats === undefined) {
        parsedSettings = {...parsedSettings, autoNameChats: true};
      }
      return parsedSettings;
    } catch (e) {
      console.error('Error parsing user preferences', e);
      return initial;
    }
  });

  useEffect(() => {
    localStorage.setItem('userPreferences', JSON.stringify(state));
  }, [state]);

  return (
    <UserSettingsContext.Provider value={{ state, dispatch }}>
      {children}
    </UserSettingsContext.Provider>
  );
};

// Custom hook to use the UserSettingsContext
export const useUserSettings = () => {
  return useContext(UserSettingsContext);
};

// Action creators
export const setTheme = (theme) => ({ type: actionTypes.SET_THEME, payload: theme });
export const setUseModernChat = (useModernChat) => ({ type: actionTypes.SET_USE_MODERN_CHAT, payload: useModernChat });
export const setUseAdvanceFields = (useAdvanceFields) => ({ type: actionTypes.SET_USE_ADVANCE_FIELDS, payload: useAdvanceFields });
export const setShowAllRecommendations = (showAllRecommendations) => ({ type: actionTypes.SET_SHOW_ALL_RECOMMENDATIONS, payload: showAllRecommendations });
export const setDefaultDatasets = (defaultDatasets) => ({ type: actionTypes.SET_DEFAULT_DATASETS, payload: defaultDatasets });
export const setDefaultModel = (defaultModel) => ({ type: actionTypes.SET_DEFAULT_MODEL, payload: defaultModel });
export const setDefaultPersonality = (defaultPersonality) => ({ type: actionTypes.SET_DEFAULT_PERSONALITY, payload: defaultPersonality });
export const setAutoNameChats = (autoNameChats) => ({ type: actionTypes.SET_AUTO_NAME_CHATS, payload: autoNameChats });