import { createTheme } from '@mui/material';

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor) => augmentColor({ color: { main: mainColor } });

// Base theme with common properties (ensure 'mode' is not set here)
const baseTheme = {
  typography: {
    fontFamily: 'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
  },
    components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            width: "10px",
            padding: 0,
            margin: 0,
          },
          "&::-webkit-scrollbar-track, & *::-webkit-scrollbar-track": {
            background: 'lightgray',
            borderRadius: '20px',
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            background: '#888',
            borderRadius: '20px',
          },
          "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
            background: '#555',
          },
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      mobile: 750,
      md: 900,
      tablet: 1050,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    tooltip: {
      backgroundColor: 'white',
      color: 'black',
      iconColor: '#aaaaaa',
      borderColor: '#D5BB10',
    },
    appBackground: '#121212',
    chatBackground: '#212121',
    chatInputBackground: '#282c34',
    textColorSecondary: '#e9ecef',
    buttonColor: createColor('#ffffff'),
    chatInput: {
      backgroundColor: '#404954',
    },
    dropdowns: {
      backgroundColor: '#404954',
      color: '#e9ecef',
      border: '1px solid #929396',
    },
    card: {
      backgroundColor: '#121212',
      backgroundColorLight: '#444444',
      border: '1px solid #bbbbbb',
      hover: {
        boxShadow: '0 0 0 3px #1976d2',
        backgroundColor: 'black',
        border: '1px solid #121212',
      },
    },
    table: {
      groupBackground: '#f8f8f8',
      header: {
        backgroundColor: '#000000',
        color: '#e9ecef',
      },
      body: {
        backgroundColor: '#222222',
        backgroundColorLight: '#444444',
        color: '#3d3e40',
      },
    },
    sidebar: {
      item: {
        backgroundColor: '#212121',
        hover: {
          backgroundColor: '#282c34',
        },
        selected: {
          backgroundColor: '#e0e0e0',
          color: '#333333',
        },
      },
    },
    button: {
      contained: {
        backgroundColor: 'white',
        color: 'black',
        fontWeight: 'bold',
        padding: '4px 16px',
        '&:hover': {
          backgroundColor: '#cccccc',
        },
      },
      outlined: {
        backgroundColor: 'transparent',
        color: 'white',
        fontWeight: 'bold',
        padding: '4px 16px',
        border: '1px solid white',
        '&:hover': {
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
        },
      },
    },
    dialog: {
      backgroundColor: '#212121',
      border: '2px solid #BBBBBB',
      borderRadius: '12px',
      boxShadow: '0 0 6px 0 #90CAF9',
    },
  },
};

// Now, create the themes by merging properties
export const darkTheme = createTheme({
  ...baseTheme,
  palette: {
    ...baseTheme.palette,
    mode: 'dark',
    appBackground: '#121212',
    chatBackground: '#212121',
    chatInputBackground: '#282c34',
    textColorSecondary: '#e9ecef',
    buttonColor: createColor('#ffffff'),
    chatInput: {
      backgroundColor: '#404954',
    },
    dropdowns: {
      backgroundColor: '#404954',
      color: '#e9ecef',
      border: '1px solid #929396',
    },
    card: {
      backgroundColor: '#121212',
      backgroundColorLight: '#444444',
      border: '1px solid #bbbbbb',
      hover: {
        boxShadow: '0 0 0 3px #1976d2',
        backgroundColor: 'black',
        border: '1px solid #121212',
      },
    },
    table: {
      groupBackground: '#3a3a3a',  // Slightly lighter than before
      header: {
        backgroundColor: '#1a1a1a', // Dark but not black for better visual hierarchy
        color: '#ffffff',           // Pure white for better readability in headers
      },
      body: {
        backgroundColor: '#2d2d2d',  // Dark background but not too dark
        backgroundColorLight: '#1e1e1e', // For alternating rows, subtle difference
        color: '#e0e0e0',            // Light gray text that's easy to read on dark backgrounds
      },
    },
    sidebar: {
      item: {
        backgroundColor: '#212121',
        hover: {
          backgroundColor: '#282c34',
        },
        selected: {
          backgroundColor: '#e0e0e0',
          color: '#333333',
        },
      },
    },
    button: {
      contained: {
        backgroundColor: 'white',
        color: 'black',
        fontWeight: 'bold',
        padding: '4px 16px',
        '&:hover': {
          backgroundColor: '#cccccc',
        },
      },
      outlined: {
        backgroundColor: 'transparent',
        color: 'white',
        fontWeight: 'bold',
        padding: '4px 16px',
        border: '1px solid white',
        '&:hover': {
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
        },
      },
    },
    dialog: {
      backgroundColor: '#212121',
      border: '2px solid #BBBBBB',
      borderRadius: '12px',
      boxShadow: '0 0 6px 0 #90CAF9',
    },
  },
});

export const lightTheme = createTheme({
  ...baseTheme,
  palette: {
    ...baseTheme.palette,
    mode: 'light',
    appBackground: '#e9ecef',
    chatBackground: '#ffffff',
    chatInputBackground: '#e9ecef',
    textColorSecondary: '#3d3e40',
    buttonColor: createColor('#000000'),
    chatInput: {
      backgroundColor: '#f0f0f0',
    },
    dropdowns: {
      backgroundColor: '#f0f0f0',
      color: '#3d3e40',
      border: '1px solid #3d3e40',
    },
    card: {
      backgroundColor: '#f8f8f8',
      backgroundColorLight: '#e9ecef',
      border: '1px solid #999999',
      hover: {
        boxShadow: '0 0 0 3px #1976d2',
        backgroundColor: '#fafafa',
        border: '1px solid #f8f8f8',
      },
    },
    table: {
      groupBackground: '#f1f1f1',      
      header: {
        backgroundColor: '#c7c7c7',
        color: '#3d3e40',
      },
      body: {
        backgroundColor: '#ffffff',
        backgroundColorLight: '#f0f0f0',
        color: '#3d3e40',
      },
    },
    sidebar: {
      item: {
        backgroundColor: '#ffffff',
        hover: {
          backgroundColor: '#bebfd4',
        },
        selected: {
          backgroundColor: '#bfbfbf',
          color: '#333333',
        },
      },
    },
    button: {
      contained: {
        backgroundColor: '#1976d2',
        color: 'white',
        fontWeight: 'bold',
        padding: '4px 16px',
        '&:hover': {
          backgroundColor: '#0056b3',
        },
      },
      outlined: {
        backgroundColor: 'transparent',
        color: '#1976d2',
        fontWeight: 'bold',
        padding: '4px 16px',
        border: '1px solid #1976d2',
        '&:hover': {
          backgroundColor: 'rgba(25, 118, 210, 0.1)',
        },
      },
    },
    dialog: {
      backgroundColor: '#e9ecef',
      border: '2px solid #3d3e40',
      borderRadius: '12px',
      boxShadow: '0 0 6px 0 #90CAF9',
    },
  },
});

export const realDarkTheme = createTheme({
  ...baseTheme,
  components: {
    ...baseTheme.components,
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            width: "10px",
          },
          "&::-webkit-scrollbar-track, & *::-webkit-scrollbar-track": {
            background: "transparent",
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            borderRadius: 8,
            backgroundColor: "#6b6b6b",
          },
        },
      },
    },
  },
  palette: {
    ...baseTheme.palette,
    mode: 'dark',
    appBackground: '#121212',
    chatBackground: '#282828',
    chatInputBackground: '#121212',
    textColorSecondary: '#e9ecef',
    buttonColor: createColor('#ffffff'),
    chatInput: {
      backgroundColor: '#121212',
    },
    dropdowns: {
      backgroundColor: '#404954',
      color: '#e9ecef',
      border: '1px solid #929396',
    },
    card: {
      backgroundColor: '#121212',
      backgroundColorLight: '#444444',
      border: '1px solid #bbbbbb',
      hover: {
        boxShadow: '0 0 0 3px #1976d2',
        backgroundColor: 'black',
        border: '1px solid #121212',
      },
    },
    table: {
      groupBackground: '#525252',
      header: {
        backgroundColor: '#000000',
        color: '#e9ecef',
      },
      body: {
        backgroundColor: '#222222',
        backgroundColorLight: '#444444',
        color: '#3d3e40',
      },
    },
    sidebar: {
      item: {
        backgroundColor: '#212121',
        hover: {
          backgroundColor: '#282c34',
        },
        selected: {
          backgroundColor: '#e0e0e0',
          color: '#333333',
        },
      },
    },
    button: {
      contained: {
        backgroundColor: 'white',
        color: 'black',
        fontWeight: 'bold',
        padding: '4px 16px',
        '&:hover': {
          backgroundColor: '#cccccc',
        },
      },
      outlined: {
        backgroundColor: 'transparent',
        color: 'white',
        fontWeight: 'bold',
        padding: '4px 16px',
        border: '1px solid white',
        '&:hover': {
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
        },
      },
    },
    dialog: {
      backgroundColor: '#212121',
      border: '2px solid #BBBBBB',
      borderRadius: '12px',
      boxShadow: '0 0 6px 0 #90CAF9',
    },
  },
});
