import * as default_data from '../config.js'
import { getToken } from '../AuthService.js';
import { getAllowedModels } from '../Utils/model_helper.js'
import { unEscapeHTML } from '../Utils/text_functions.js';
import * as log from 'loglevel'

export var canvas_allowed_models = ['gpt-4o', 'gpt-4o-mini', 'gpt-o3-mini', 'gpt-4o-gov', 'gpt-4o-mini-gov']

async function proposeModelCount(token_count, file=null, force_mini=false) {
  var model = 'gpt';

  var models_available = ['gpt-4o', 'gpt35-16k', 'gpt-4o-mini', 'gpt', 'gpt4', 'gpt4-32k', 'gpt4-vision', 'gpt-4o-gov', 'gpt-4o-mini-gov', 'gpt-gov', 'gpt4-gov']
  if(force_mini === true) {
    models_available = ['gpt-4o-mini', 'gpt-4o', 'gpt35-16k', 'gpt', 'gpt4', 'gpt4-32k', 'gpt4-vision', 'gpt-4o-mini-gov', 'gpt-4o-gov', 'gpt-gov', 'gpt4-gov']
  }
  models_available = models_available.filter((option) => getAllowedModels().includes(option));

  if(token_count === null) {
    return models_available[0];
  }

  if(file !== null && file !== '') {
    if(models_available.includes('gpt-4o')) {
      model = 'gpt-4o';
      return model;
    }
    else if(models_available.includes('gpt-4o-mini')) {
      model = 'gpt-4o-mini';
      return model;
    }
    else if(models_available.includes('gpt-4o-gov')) {
      model = 'gpt-4o-gov';
      return model;
    }
    else if(models_available.includes('gpt-4o-mini-gov')) {
      model = 'gpt-4o-mini-gov';
      return model;
    }
  }

  model = null;
  // loop through available models
  for(var i = 0; i < models_available.length; i++) {
    var token_window = getTokenWindowR(models_available[i]);
    if(token_count <= (token_window * 3 / 5)) {
      model = models_available[i];
      break;
    }
  }

  if(model === null) {
    model = models_available[0];
  }

  return model;
}

function getTokenWindowR(currentModel) {
  if(currentModel.toLowerCase() === 'gpt' || currentModel.toLowerCase() === 'openai_gpt') {
    return 4096;
  }
  else if(currentModel.toLowerCase() === 'gpt-gov') {
    return 16384;
  }
  else if(currentModel.toLowerCase() === 'cohere') {
    return 4096;
  }
  else if(currentModel.toLowerCase() === 'groq-70b') {
    return 8192;
  }
  else if(currentModel.toLowerCase() === 'groq-llama33') {
    return 128000;
  }
  else if(currentModel.toLowerCase() === 'groq-deepseek') {
    return 128000;
  }
  else if(currentModel.toLowerCase() === 'xai-grok') {
    return 131072;
  }  
  else if(currentModel.toLowerCase() === 'mpt-7b-chat') {
    return 4096;
  }
  else if(currentModel.toLowerCase() === 'dolly') {
    return 4096;
  }
  else if(currentModel.toLowerCase() === 'flan-t5') {
    return 4096;
  }
  else if(currentModel.toLowerCase() === 'claude2') {
    return 100000;
  }
  else if(currentModel.toLowerCase() === 'claude-3-opus') {
    return 200000;
  }
  else if(currentModel.toLowerCase() === 'claude-3-sonnet') {
    return 200000;
  }
  else if(currentModel.toLowerCase() === 'claude-35-sonnet') {
    return 200000;
  }
  else if(currentModel.toLowerCase() === 'claude-37-sonnet') {
    return 200000;
  }
  else if(currentModel.toLowerCase() === 'llma2') {
    return 8000;
  }
  else if(currentModel.toLowerCase() === 'llma3') {
    return 8000;
  }
  else if(currentModel.toLowerCase() === 'falcon') {
    return 4096;
  }
  else if(currentModel.toLowerCase() === 'google-bison') {
    return 8192;
  }
  else if(currentModel.toLowerCase() === 'aws-bedrock-titan') {
    return 8192;
  }
  else if(currentModel.toLowerCase() === 'aws-bedrock-claude-35-sonnet-gov') {
    return 200000;
  }
  else if(currentModel.toLowerCase() === 'google-gemini-pro') {
    return 32000;
  }
  else if(currentModel.toLowerCase() === 'google-gemini-20-flash') {
    return 1000000;
  }
  else if(currentModel.toLowerCase() === 'mistral-large') {
    return 32000;
  }
  else if(currentModel.toLowerCase() === 'gpt4') {
    return 8192;
  }
  else if(currentModel.toLowerCase() === 'gpt4-gov') {
    return 128000;
  }
  else if(currentModel === 'gpt4-32k') {
    return 32768;
  }
  else if(currentModel === 'gpt4-vision') {
    return 128000;
  }
  else if(currentModel === 'auto') {
    return 128000;
  }
  else if(currentModel === 'gpt-4o') {
    return 128000;
  }
  else if(currentModel === 'gpt-4o-mini') {
    return 128000;
  }
  else if(currentModel === 'gpt-o1-mini') {
    return 128000;
  }
  else if(currentModel === 'gpt-o3-mini') {
    return 200000;
  }
  else if(currentModel === 'gpt-o1') {
    return 128000;
  }
  else if(currentModel === 'gpt-4.5-preview') {
    return 128000;
  }
  else if(currentModel === 'gpt-4o-gov') {
    return 128000;
  }
  else if(currentModel === 'gpt-4o-mini-gov') {
    return 128000;
  }
  else if(currentModel === 'dall-e-2') {
    return 1024;
  }
  else if(currentModel === 'dall-e-3') {
    return 1024;
  }
  else if(currentModel === 'google-imagen-3') {
    return 1024;
  }
  else if(currentModel === 'gpt35-16k') {
    return 16000;
  }
  return 4096;
}

function isCUICapableR(currentModel, live=0) {
  if(live === 1 || live === 2) {
    return false;
  }

  if(currentModel.toLowerCase() === 'gpt' || currentModel.toLowerCase() === 'openai_gpt') {
    return true;
  }
  else if(currentModel.toLowerCase() === 'gpt-gov') {
    return true;
  }
  else if(currentModel.toLowerCase() === 'cohere') {
    return false;
  }
  else if(currentModel.toLowerCase() === 'groq-70b') {
    return false;
  }
  else if(currentModel.toLowerCase() === 'groq-llama33') {
    return false;
  }
  else if(currentModel.toLowerCase() === 'groq-deepseek') {
    return false;
  }
  else if(currentModel.toLowerCase() === 'xai-grok') {
    return false;
  }  
  else if(currentModel.toLowerCase() === 'mpt-7b-chat') {
    return true;
  }
  else if(currentModel.toLowerCase() === 'dolly') {
    return true;
  }
  else if(currentModel.toLowerCase() === 'flan-t5') {
    return true;
  }
  else if(currentModel.toLowerCase() === 'claude2') {
    return false;
  }
  else if(currentModel.toLowerCase() === 'claude-3-opus') {
    return false;
  }
  else if(currentModel.toLowerCase() === 'claude-3-sonnet') {
    return false;
  }
  else if(currentModel.toLowerCase() === 'claude-35-sonnet') {
    return false;
  }
  else if(currentModel.toLowerCase() === 'claude-37-sonnet') {
    return false;
  }
  else if(currentModel.toLowerCase() === 'llma2') {
    return false;
  }
  else if(currentModel.toLowerCase() === 'llma3') {
    return false;
  }
  else if(currentModel.toLowerCase() === 'falcon') {
    return true;
  }
  else if(currentModel.toLowerCase() === 'google-bison') {
    return false;
  }
  else if(currentModel.toLowerCase() === 'aws-bedrock-titan') {
    return true;
  }
  else if(currentModel.toLowerCase() === 'aws-bedrock-claude-35-sonnet-gov') {
    return true;
  }
  else if(currentModel.toLowerCase() === 'google-gemini-pro') {
    return false;
  }
  else if(currentModel.toLowerCase() === 'google-gemini-20-flash') {
    return true;
  }
  else if(currentModel.toLowerCase() === 'mistral-large') {
    return false;
  }
  else if(currentModel.toLowerCase() === 'gpt4') {
    return true;
  }
  else if(currentModel.toLowerCase() === 'gpt4-gov') {
    return true;
  }
  else if(currentModel === 'gpt4-32k') {
    return true;
  }
  else if(currentModel === 'auto') {
    return true;
  }
  else if(currentModel === 'gpt4-vision') {
    return true;
  }
  else if(currentModel === 'gpt-4o') {
    return true;
  }
  else if(currentModel === 'gpt-4o-mini') {
    return true;
  }
  else if(currentModel === 'gpt-o1') {
    return true;
  }
  else if(currentModel === 'gpt-o1-mini') {
    return true;
  }
  else if(currentModel === 'gpt-o3-mini') {
    return true;
  }
  else if(currentModel === 'gpt-4.5-preview') {
    return false;
  }
  else if(currentModel === 'gpt-4o-gov') {
    return true;
  }
  else if(currentModel === 'gpt-4o-mini-gov') {
    return true;
  }
  else if(currentModel === 'dall-e-2') {
    return false;
  }
  else if(currentModel === 'dall-e-3') {
    return true;
  }
  else if(currentModel === 'google-imagen-3') {
    return false;
  }
  else if(currentModel === 'gpt35-16k') {
    return true;
  }
  return false;
}

async function getModelInfoR(currentModel, tokens_count, file) {
  var new_model = currentModel

  if(new_model.toLowerCase() === 'auto') {
    new_model = await proposeModelCount(tokens_count, file)
  }

  var model = 'openai_gpt';
  var history_count = 10;
  if(new_model.toLowerCase() == 'gpt' || new_model.toLowerCase() == 'openai_gpt') {
    model = 'openai_gpt'
  }
  else if(new_model.toLowerCase() == 'gpt-gov') {
    model = 'gpt-gov'
  }
  else if(new_model.toLowerCase() == 'cohere') {
    model = 'cohere'
  }
  else if(new_model.toLowerCase() == 'groq-70b') {
    model = 'groq-70b'
  }
  else if(new_model.toLowerCase() == 'groq-llama33') {
    model = 'groq-llama33'
  }
  else if(new_model.toLowerCase() == 'groq-deepseek') {
    model = 'groq-deepseek'
  }
  else if(new_model.toLowerCase() == 'xai-grok') {
    model = 'xai-grok'
  }
  else if(new_model.toLowerCase() == 'mpt-7b-chat') {
    model = 'mpt-7b-chat'
  }
  else if(new_model.toLowerCase() == 'dolly') {
    model = 'dolly'
  }
  else if(new_model.toLowerCase() == 'flan-t5') {
    model = 'flan-t5'
  }
  else if(new_model.toLowerCase() == 'claude2') {
    model = 'claude2'
    history_count = 30;
  }
  else if(new_model.toLowerCase() == 'claude-3-opus') {
    model = 'claude-3-opus'
    history_count = 30;
  }
  else if(new_model.toLowerCase() == 'claude-3-sonnet') {
    model = 'claude-3-sonnet'
    history_count = 30;
  }
  else if(new_model.toLowerCase() == 'claude-35-sonnet') {
    model = 'claude-35-sonnet'
    history_count = 30;
  }
  else if(new_model.toLowerCase() == 'claude-37-sonnet') {
    model = 'claude-37-sonnet'
    history_count = 30;
  }
  else if(new_model.toLowerCase() == 'llma2') {
    model = 'llma2'
  }
  else if(new_model.toLowerCase() == 'llma3') {
    model = 'llma3'
  }
  else if(new_model.toLowerCase() == 'falcon') {
    model = 'falcon'
  }
  else if(new_model.toLowerCase() == 'google-bison') {
    model = 'google-bison'
  }
  else if(new_model.toLowerCase() == 'aws-bedrock-titan') {
    model = 'aws-bedrock-titan'
  }
  else if(new_model.toLowerCase() == 'aws-bedrock-claude-35-sonnet-gov') {
    model = 'aws-bedrock-claude-35-sonnet-gov'
  }
  else if(new_model.toLowerCase() == 'google-gemini-pro') {
    model = 'google-gemini-pro'
  }
  else if(new_model.toLowerCase() == 'google-gemini-20-flash') {
    model = 'google-gemini-20-flash'
  }
  else if(new_model.toLowerCase() == 'mistral-large') {
    model = 'mistral-large'
  }
  else if(new_model.toLowerCase() == 'gpt4') {
    model = 'gpt4'
    history_count = 16;
  }
  else if(new_model.toLowerCase() == 'gpt4-gov') {
    model = 'gpt4-gov'
    history_count = 16;
  }
  else if(new_model == 'gpt4-32k') {
    model = 'gpt4-32k'
    history_count = 22;
  }
  else if(new_model == 'gpt4-vision') {
    model = 'gpt4-vision'
    history_count = 50;
  }
  else if(new_model == 'gpt-4o') {
    model = 'gpt-4o'
    history_count = 50;
  }
  else if(new_model == 'gpt-4o-mini') {
    model = 'gpt-4o-mini'
    history_count = 50;
  }
  else if(new_model == 'gpt-o1-mini') {
    model = 'gpt-o1-mini'
    history_count = 50;
  }
  else if(new_model == 'gpt-o3-mini') {
    model = 'gpt-o3-mini'
    history_count = 50;
  }
  else if(new_model == 'gpt-4.5-preview') {
    model = 'gpt-4.5-preview'
    history_count = 50;
  }
  else if(new_model == 'gpt-o1') {
    model = 'gpt-o1'
    history_count = 50;
  }
  else if(new_model == 'gpt-4o-gov') {
    model = 'gpt-4o-gov'
    history_count = 50;
  }
  else if(new_model == 'gpt-4o-mini-gov') {
    model = 'gpt-4o-mini-gov'
    history_count = 50;
  }
  else if(new_model == 'dall-e-2') {
    model = 'dall-e-2'
    history_count = 1;
  }
  else if(new_model == 'dall-e-3') {
    model = 'dall-e-3'
    history_count = 1;
  }
  else if(new_model == 'google-imagen-3') {
    model = 'google-imagen-3'
    history_count = 1;
  }
  else if(new_model == 'gpt35-16k') {
    model = 'gpt35-16k'
    history_count = 18;
  }

  return {model: model, history_count: history_count}
}

async function getTokenizerR(string) {

  var tokens_count = 0;
  var token = getToken()
  if(token === false) {
    return 0;
  }

  if (string == null || string == '') {
    return 0;
  }

  var model = 'openai_gpt';
  try {
    const response = await fetch(default_data.default_chat_service_url + '/tokenizer', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-tokens': `${token}`
      },        
      body: JSON.stringify({
        content: string,
        model: model
      })
    })
    const data = await response.json();
    if(data != null && data.status != 200) {            
      console.log(data)
    }
    else {
      var cur_tokens = parseInt(data.response);
      tokens_count = cur_tokens
    }
  }
  catch {
    tokens_count = 0;
  }
  return tokens_count
} 

function parseToolCalls(data, update_value=null) {
  try {
    if(data.tool_calls !== null) {
      // check if tool_calls is an array and if so, take item 0
      if(Array.isArray(data.tool_calls)) {
        var cur_tool_calls = data.tool_calls[0]
        // check if cur_tool_calls has 'function' item
        if(cur_tool_calls.hasOwnProperty('function')) {
          // check if cur_tool_calls['function'] has 'name' item
          if(cur_tool_calls['function'].hasOwnProperty('name')) {
            // check if cur_tool_calls['function']['name'] is 'respond_with_code_and_answer'
            if(cur_tool_calls['function']['name'] === 'respond_with_code_and_answer') {
              // check if cur_tool_calls has 'parameters' item
              if(cur_tool_calls['function'].hasOwnProperty('arguments')) {
                // json decode cur_tool_calls['function']['arguments']
                try {
                  var parameters = JSON.parse(cur_tool_calls['function']['arguments'])
                  if(parameters.hasOwnProperty('code') && parameters.hasOwnProperty('answer')) {
                    if(update_value !== null) {
                      parameters['code'] = update_value
                      var parameters_json = JSON.stringify(parameters)
                      data.tool_calls[0]['function']['arguments'] = parameters_json
                      return data
                    }
                    if(parameters['code'] === null || parameters['code'] === undefined) {                      
                      parameters['code'] = ''
                    }
                    return {'message': parameters['answer'], 'code': parameters['code']}
                  }
                }
                catch(err) {
                  log.error(`/parseToolCalls: ${err.message}`)
                }
              }
            }
            if(cur_tool_calls['function']['name'] === 'respond_with_content_and_explanation') {
              // check if cur_tool_calls has 'parameters' item
              if(cur_tool_calls['function'].hasOwnProperty('arguments')) {
                // json decode cur_tool_calls['function']['arguments']
                try {
                  var parameters = JSON.parse(cur_tool_calls['function']['arguments'])                    
                  if(parameters.hasOwnProperty('content') && parameters.hasOwnProperty('explanation')) {
                    if(update_value !== null) {
                      parameters['content'] = update_value
                      var parameters_json = JSON.stringify(parameters)
                      data.tool_calls[0]['function']['arguments'] = parameters_json
                      return data
                    }
                    return {'message': parameters['explanation'], 'content': parameters['content']}
                  }
                }
                catch(err) {
                  log.error(`parseToolCalls: 2: ${err.message}`)
                }
              }
            }
          }
        }
      }
    }
    return null;
  }
  catch(err) {
    log.error(`/parseToolCalls: 3: ${err.message}`)
    return null;
  }
}

function isValidImageJSON(content) {
  try {
    if(content.indexOf('{"images":') !== -1 && (content.indexOf('"model": "dall-e-2"}}') !== -1 || content.indexOf('"model": "dall-e-3"}}') !== -1 || content.indexOf('"model": "google-imagen-3"}}') !== -1)) {
      return true;
    }
  }
  catch {
  }
  return false;
}

function preserveAllowedTags(input, allowedTags) {
  allowedTags.forEach(tag => {
    // Replace <tag> with [[tag]] and </tag> with [[/tag]]
    input = input.replace(new RegExp(`<${tag}>`, 'g'), `[[${tag}]]`);
    input = input.replace(new RegExp(`</${tag}>`, 'g'), `[[/${tag}]]`);
  });
  return input;
}

function restoreAllowedTags(input, allowedTags) {
  allowedTags.forEach(tag => {
    // Replace [[tag]] back to <tag> and [[/tag]] back to </tag>
    input = input.replace(new RegExp(`\\[\\[${tag}\\]\\]`, 'g'), `<${tag}>`);
    input = input.replace(new RegExp(`\\[\\[/${tag}\\]\\]`, 'g'), `</${tag}>`);
  });
  return input;
}

async function parseMessage(random_element, message) {
  try {
    var data = {message: message};
    var images = null;

    const allowedTags = ["action", "comment", "file-content", "gen-images", "plotly-json"];

    // Step 1: Replace allowed tags with placeholders
    let modifiedMessage = preserveAllowedTags(data.message, allowedTags);
    
    // Step 2: Use your existing escape method
    random_element.textContent = modifiedMessage;
    data.message = random_element.innerHTML;
    random_element.innerHTML = '';
    
    // Step 3: Restore allowed tags
    data.message = restoreAllowedTags(data.message, allowedTags);
    
    // replace all occcurences of @|@@sep@@|@ by @|@sep@|@
    if (data.message.indexOf('@|@@sep@@|@') !== -1) {
      data.message = data.message.split('@|@@sep@@|@').join('@|@@sep@@|@');
    }
    
    data.message = data.message.replace(/\^\^\^([\s\S]*?)\^\^\^/g, '$1')

    /* keep old parser for legacy messages */
    data.message = data.message.replace(/@@@ACTION@@@([\s\S]*?)@@@ACTION@@@/g, '<action>\n$1\n</action>\n')
    data.message = data.message.replace(/@@@COMMENT@@@([\s\S]*?)@@@COMMENT@@@/g, '<comment>\n$1\n</comment>\n')
    // finding file extracts, format: FILE CONTENT:\n' + format(file) + '\nEND OF FILE CONTENT.
    data.message = data.message.replace(/FILE CONTENT:\n([\s\S]*?)\nEND OF FILE CONTENT./g, '\n<file-content>\n$1\n</file-content>\n')

    if(data.message.indexOf('<gen-images>') === -1) {   
      if(isValidImageJSON(data.message)) {
        try {
          // Handle message by finding the first {"images": in the message} and do a substring
          images = data.message.substring(data.message.indexOf('{"images":'));
          data.message = '';
        }
        catch(e) {
          log.error(`/parseMessage: ${e.message}`);
          log.error(`/parseMessage: ${e.stack}`);
        }
      }
    }
  }
  catch {
  }
  return {'message': data.message, 'images': images};
}

function cleanMessage(new_item) {
  if (new_item.message_orig && new_item.message_orig !== null && new_item.message_orig !== '') {
    new_item.message = new_item.message_orig;
  }
  // Unescape HTML in the message
  new_item.message = unEscapeHTML(new_item.message);

  if(new_item.message.indexOf('{"images":') !== -1 && (new_item.message.indexOf('"model": "google-imagen-3"}}') !== -1)) {
    new_item.message = 'Image generated by Google Imagen-3';
    new_item.images = null;
  }

  // Set message_orig to null
  new_item.message_orig = null;
  return new_item;
} 

export { proposeModelCount, getTokenWindowR, isCUICapableR, getModelInfoR, getTokenizerR, parseMessage, cleanMessage, parseToolCalls, isValidImageJSON }