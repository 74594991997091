import { Alert, useTheme, Link } from '@mui/material';
import AskSagePlaylist from '../../AskSagePlaylist';
import * as default_data from '../../../config.js'
import { Button } from '@mui/material';

export const Training = () => {
  const theme = useTheme();

  return (
    <div style={{clear: 'both'}}>
      <h3 className="modal_header">Documentation</h3>
      {default_data.default_has_warning_use_mil === true && (
        <Alert severity="warning" sx={{ marginBottom: 3 }}>
            WARNING: DoD government users are required to use the .MIL Ask Sage tenants (if unsure, use https://chat.asksage.mil) for all CUI data.
        </Alert>
      )}
      <p><Button variant="contained" href="https://docs.asksage.ai" rel="noreferrer" target="_blank">NEW: Full documentation portal (product documentation, APIs, Plugins, etc.)</Button></p>
      <h3 className="modal_header">Training &amp; Use Case Videos (Watch before Getting Started!)</h3>
      { default_data.default_disable_youtube === true ? 
      'YouTube is disabled on your network, check out our videos at https://www.youtube.com/playlist?list=PLr6nbjG8-gxSBegcnxDGXHE2Zk1bFPid4 on a different device.' :
      
      <>
      <AskSagePlaylist /><br />
      If the videos are not working on your device, visit <Link sx={{textTransform: 'none', color: theme.palette.textColorSecondary}} href="https://www.youtube.com/playlist?list=PLr6nbjG8-gxSBegcnxDGXHE2Zk1bFPid4" rel="noreferrer" target="_blank">YouTube Ask Sage playlist</Link>.
      </>
      }
    </div>
  )
}