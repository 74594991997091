import React from 'react';
import { Select, MenuItem } from '@mui/material';

const SelectOrganization = ({ organizations, selectedOrganization, setSelectedOrganization, handleFilterChange, emailFilter, small=false }) => {
  return (
    <Select
    {...(small ? {style: {marginRight: '10px', height: '30px', padding: 0}} : {marginRight: '10px'}) }
    size='small'
      value={selectedOrganization}
      onChange={(e) => {
        setSelectedOrganization(e.target.value);
        handleFilterChange(emailFilter, e.target.value); // Call the filter change handler
      }}
      displayEmpty
      inputProps={{ 'aria-label': 'Without label' }}
    >
      <MenuItem value="">
        <em>All Organizations</em>
      </MenuItem>
      {organizations !== null && organizations.map(org => (
        <MenuItem key={org.id} value={org.id}>{org.name}</MenuItem>
      ))}
    </Select>
  );
};

export default SelectOrganization;