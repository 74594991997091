import { Button as MUIButton, useTheme } from '@mui/material';

export const Button = ({
  onClick,
  size='medium',
  disabled=false,
  children,
  sx={},
  variant='contained',
  ...props
}) => {
  const theme = useTheme();
  const buttonTheme = variant === 'contained' ? theme.palette.button.contained : theme.palette.button.outlined;
  return (
    <MUIButton
      onClick={onClick}
      disabled={disabled}
      size={size}
      variant={variant}
      sx={{
        ...buttonTheme,
        ...sx
      }}
      {...props}
    >
      {children}
    </MUIButton>
  );
}