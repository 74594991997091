import React, { useState } from "react";
import { Box, MenuItem, Select, Stack, Switch, Typography, useTheme } from "@mui/material";
import { SelectDataset } from "../ChatBox";
import { setTheme, useUserSettings } from "../../Contexts/UserPreferenceContext";
import { SelectModel } from "../Components/SelectModel.js";
import { SettingsCard } from "./SettingsCard.js";

export const CustomizationTab = ({
  personas, 
  datasets,
  user,
}) => {
  const { state, dispatch } = useUserSettings(); // Access the dispatch function
  const theme = useTheme();
  
  const onChange = (e) => {
    dispatch({ type: 'SET_DEFAULT_DATASETS', payload: e }); // Dispatch the action to update the default datasets
  };

  return (
    <Stack direction={'column'} spacing={2} padding={0}>
      <SettingsCard title={'General'} cardColor={theme.palette.card.backgroundColorLight} style={{fontSize: '12px'}}>
        <Stack direction={'column'} px={0} spacing={1}>
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} pl={1} spacing={0}>
            <Typography variant='body1' className="settings-drawer-text">
              Theme
            </Typography>
            <Select
              value={state.theme}
              onChange={(e) => {
                dispatch(setTheme(e.target.value)); // Dispatch the action to update the theme
              }}
              size="small"
              sx={{ 
                width: 150,
                backgroundColor: theme.palette.dropdowns.backgroundColor,
                '&.MuiInputBase-root': {
                  fontSize: '0.875rem',
                },
              }}
            >
              <MenuItem key={'dark'} value={'dark'} sx={{fontSize: 13}}>Dark</MenuItem>
              <MenuItem key={'light'} value={'light'} sx={{fontSize: 13}}>Light</MenuItem>
              <MenuItem key={'modernDark'} value={'realDark'} sx={{fontSize: 13}}>Modern Dark</MenuItem>
            </Select>
          </Stack>
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} pl={1}>
            <Typography variant='body1' className="settings-drawer-text">
              Show All Prompt Follow-up Recommendations 
            </Typography>
            <Switch
              checked={state.showAllRecommendations}
              onChange={
                () => {
                  dispatch({ type: 'SET_SHOW_ALL_RECOMMENDATIONS', payload: !state.showAllRecommendations }); // Dispatch the action to update the show all recommendations
                }
              }
              size="small"
            />
          </Stack>
        </Stack>
      </SettingsCard>
      <SettingsCard title={'Chat Mode'} cardColor={theme.palette.card.backgroundColorLight}>
        <Stack direction={'column'} px={0} spacing={1}>
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} pl={1}>
            <Typography variant='body1' className="settings-drawer-text">
              Modern Chat
            </Typography>
            <Switch
              checked={state.useModernChat}
              onChange={() => {
                dispatch({ type: 'SET_USE_MODERN_CHAT', payload: !state.useModernChat }); // Dispatch the action to update the modern chat input
              }}
              size="small"
            />
          </Stack>
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} pl={1}>
            <Typography variant='body1' className="settings-drawer-text">
              Advanced Chat Fields
            </Typography>
            <Switch
              checked={state.useAdvanceFields}
              onChange={() => {
                dispatch({ type: 'SET_USE_ADVANCE_FIELDS', payload: !state.useAdvanceFields }); // Dispatch the action to update the advanced user fields
              }}
              size="small"
            />
          </Stack>
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} pl={1}>
            <Typography variant='body1' className="settings-drawer-text">
              Auto Name Chats
            </Typography>
            <Switch
              checked={state.autoNameChats}
              onChange={() => {
                dispatch({ type: 'SET_AUTO_NAME_CHATS', payload: !state.autoNameChats }); // Dispatch the action to update the auto name chat field
              }}
              size="small"
            />
          </Stack>
        </Stack>
      </SettingsCard>
      <SettingsCard title={'Prompt Settings'} cardColor={theme.palette.card.backgroundColorLight} loading={!user} loadingCardHeight={200}>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} pl={1} pb={1}>
          <Typography variant='body1' className="settings-drawer-text">
            Dataset
          </Typography>
          <Box sx={{ width: 200 }}>
            <SelectDataset 
              value={state.defaultDatasets}
              datasets={datasets}
              onChange={onChange}
              maxMenuHeight={100}
            />
          </Box>
        </Stack>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} pl={1} pb={1}>
          <Typography variant='body1' className="settings-drawer-text">
            Model
          </Typography>
          <SelectModel
            value={state.defaultModel}
            cur_user={user}
            onChange={(e) => {
              dispatch({ type: 'SET_DEFAULT_MODEL', payload: e.target.value }); // Dispatch the action to update the default model
            }}
            paid={user ? user.paid : false}
            sx={{ 
              width: 200,
              height: '40px !important',
              '&.MuiInputBase-root': {
                fontSize: '0.875rem',
              },
            }}
          />
        </Stack>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} pl={1}>
          <Typography variant='body1' className="settings-drawer-text">
            Personality
          </Typography>
          <Select
            value={state.defaultPersonality}
            onChange={(e) => {
              dispatch({ type: 'SET_DEFAULT_PERSONALITY', payload: e.target.value }); // Dispatch the action to update the default personality
            }}
            size="small"
            sx={{ 
              width: 200,
              backgroundColor: theme.palette.dropdowns.backgroundColor,
              '&.MuiInputBase-root': {
                fontSize: '0.875rem',
              },
            }}
            MenuProps={{
              style: {
                maxHeight: 500,          
              }
            }}
          >
            {personas.map((p, index) => (
              <MenuItem key={index} value={p.id} sx={{fontSize: 13}}>{p.name}</MenuItem>
            ))}
          </Select>
        </Stack>
      </SettingsCard>
  </Stack>
  );
};