import React, { useState, useEffect } from 'react';
import { List, ListItem, ListItemText, ListItemSecondaryAction, Typography, Drawer, IconButton } from '@mui/material';
import * as default_data from '../../config.js';
import { getToken } from '../../AuthService';

export const LoginCard = () => {
  const [logs, setLogs] = useState([]);
  const [loaded, setLoaded] = useState(false);

  function loadLogs() {
    var token = getToken()
    if(token === false) {
      return;
    }    
  
    fetch(default_data.default_user_service_url_no_cac + '/get-user-logins', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-tokens': `${token}`
      },        
      body: JSON.stringify({
      })
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('An error occurred while getting data.');
      })
      .then(data => {
        if(data.status != 200) {
            return;
        }
        data = data.response;
        setLogs(data);
      })
      .catch(error => {
        console.log(error);
      });
  }

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
      loadLogs();
    }
  }, [loaded]);

  return (
    <div>
      {logs.length > 0 ? (
        <List disablePadding dense>
          {logs.map((log) => (
            <ListItem key={log.id} divider>
              <ListItemText
                primary={log.date_time}
                primaryTypographyProps={{ variant: 'body2' }}
                secondary={
                  <>
                    <>
                      {`IP: ${log.ip} - Status: ${log.status}`}
                    </>
                    { log.comment && (
                      <>
                        {`Comment: ${log.comment}`}
                      </>
                    )}
                  </>
                }
              />
            </ListItem>
          ))}
        </List>
      ) : (
        <Typography variant="body2" color="textSecondary">
          No login records found.
        </Typography>
      )}
    </div>
  );
}
